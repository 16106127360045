import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import Modal from 'react-bootstrap/Modal'
import info from '../../Assets/Images/Product/info-circle.svg'
import patch from '../../Assets/Images/Product/patch-check.svg'
import MiniLoading from '../MiniLoading'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import Table from 'react-bootstrap/Table'
import 'react-tabs/style/react-tabs.css'
import No from '../../Assets/Images/Login/no.svg'
import { Clipboard, ShareFill } from 'react-bootstrap-icons'
import ProductsCarrousel from '../ProductsCarrousel'
import './productDetail.css'
import cmfLogo from '../../Assets/Images/Product/logo-cmf.svg'
import calculator from '../../Assets/Images/Product/calculator.svg'
import calculatorBlue from '../../Assets/Images/Home/calculadora.svg'
import pdfIcon from '../../Assets/Images/Product/pdf.svg'
import _ from 'lodash'
import { Col, Row } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { priceListAddToCart } from '../../redux/actions/cartActions.js'
import FilteringTable from '../FilteringTable'
import { FormatMoney, toTitleCase } from '../../Utils/functions'
import LightTooltip from '../LightTooltip'
import GenericModal from 'Components/GenericModal'
import { t } from 'i18next'
import { getPriceInventoryGeneral } from 'redux/actions/priceProductsAction'
import {
  getCmfPriceAction,
  getInventoryAction,
  getProductDetaillAction,
  getRefererCreateAction,
  getSpecificCampaignAction,
  getWarrantyAction,
  getcmfCampaignsAction,
} from 'redux/actions/productActions'
import { useDebounce } from 'Hooks/useDebounce'
import { VAPermissions } from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

function ProductDetail({
  id,
  price,
  openWithInventario,
  handleClose,
  producto_final: productoFinal,
  productsRef,
  cost,
  setShowMaterialsCalculatorPDP,
  setProductDetail,
}) {
  const debounce = useDebounce()
  const [product, setProduct] = useState(null)
  const [similarProducts, setSimilarProducts] = useState([])
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [inventory, setInventory] = useState(openWithInventario)
  // const [availability, setAvailability] = useState([]);
  const [warrantySelect, setWarrantySelect] = useState('no-warranty')
  const [warrantyOptions, setWarrantyOptions] = useState([])
  const [company] = useState(window.localStorage.getItem('company'))
  const [counter, setCounter] = useState(1)
  const [openCMF, setOpenCMF] = useState(false)
  const [data, setData] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [cmfCode, setCmfCode] = useState(false)
  const [precioCmf, setPrecioCmf] = useState({})
  const dispatch = useDispatch()

  const [, setStateDetalle] = useState(false)

  const [exCodes, setExCodes] = useState([])

  const [showAlertDontAddProduct, setShowAlertDontAddProduct] = useState(false)

  const [caracteristicasUnidadMedida, setCaracteristicasUnidadMedida] =
    useState([])
  const [caracteristicasGeneral, setCaracteristicasGeneral] = useState([])

  const [cmfCopiado, setCmfCopiado] = useState(false)

  const [meters, setMeters] = useState('')
  const [boxes, setBoxes] = useState('')

  const [user] = useState(useSelector(state => state.login.user))
  const companyId = useSelector(state => state.company.CompanyId)
  const companyCode = useSelector(state => state.company.CompanyCode)
  const warehouse = useSelector(state => state.company.WarehouseId)

  const ShortDescriptionRef = useRef(null)

  const [hiddenColumns, setHiddenColumns] = useState([])

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const seeCostProd = useValidatePermission(VAPermissions.seeCostProd)

  const setInventoryData = data => {
    const inventory = data.map(office => {
      return {
        Tienda: toTitleCase(office.WarehouseName),
        Código: office.WarehouseCode,
        Disponible: office?.AvailableQty || 0,
        'En Existencia': office?.InStockQty || 0,
        Comprometido: office?.ReservedQty || 0,
        'En Compras': office?.OnOrderQty || 0,
        Requisición: office?.RequisitionQty || 0,
        Traslado: office?.TransferQty || 0,
        Total: office?.TotalQty || 0,
      }
    })
    return inventory
  }

  const inventoryData = useMemo(() => setInventoryData(data), [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tienda',
        accessor: 'Tienda',
        hideEnabled: true,
        Footer: 'Total general',
      },
      {
        Header: 'Código',
        hideEnabled: true,
        accessor: 'Código',
      },
      {
        Header: 'Disponible',
        hideEnabled: true,
        accessor: 'Disponible',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values.Disponible.toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'En Existencia',
        hideEnabled: true,
        accessor: 'En Existencia',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values['En Existencia'].toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'Comprometido',
        hideEnabled: true,
        accessor: 'Comprometido',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values.Comprometido.toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'En Compras',
        hideEnabled: true,
        accessor: 'En Compras',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values['En Compras'].toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'Requisición',
        hideEnabled: true,
        accessor: 'Requisición',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values['Requisición'].toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'Traslado',
        hideEnabled: true,
        accessor: 'Traslado',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(row.values.Traslado.toString().replaceAll(',', '')) +
                  sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
      {
        Header: 'Total',
        hideEnabled: true,
        accessor: 'Total',
        Footer: info => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  parseInt(
                    row.values.Disponible.toString().replaceAll(',', '')
                  ) + sum,
                0
              ),
            [info.rows]
          )
          return <>{total}</>
        },
      },
    ],
    []
  )

  const fetchWarehousesAvailability = async () => {
    const payload = {
      ProductId: id,
      CustomerId: user.CustomerInfo.CustomerId,
      GlobalExecution: true,
      PageNumber: 1,
      PageSize: 200,
      CompanyId: companyId,
      Latitude: 0,
      Longitude: 0,
    }
    const response = await dispatch(getInventoryAction(payload))
    if (response && response.data) {
      const warehouses = []
      response.data.Data.map(warehouse => {
        return warehouses.push(warehouse)
      })
      const sortedWarehouses = warehouses.sort((a, b) => {
        return a.WarehouseCode.localeCompare(b.WarehouseCode)
      })
      const Novey = sortedWarehouses.filter(warehouse =>
        warehouse.WarehouseName.includes('NOVEY')
      )
      const Cochez = sortedWarehouses.filter(warehouse =>
        warehouse.WarehouseName.includes('COCHEZ')
      )
      if (window.localStorage.getItem('company') === 'Cochez') {
        setData([...Cochez, ...Novey])
      } else {
        setData([...Novey, ...Cochez])
      }
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const buildCmfLink = async referral => {
    const payload = {
      CampaignReferralId: referral.CampaignReferralId,
      ReferrerName: user.CustomerInfo.FullName,
      IdentityNumber: user.CustomerInfo.IDNumber,
      ExternalCode: user.CustomerInfo.CustomerId,
    }
    const response = await dispatch(getRefererCreateAction(payload))
    if (response && response.data) {
      setCmfCode(referral.ReferralLink + response.data.response)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const getSpecificCampaign = async referral => {
    const response = await dispatch(getSpecificCampaignAction(referral))
    if (response && response.data) {
      buildCmfLink(response.data?.response[0])
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const fetchCampaigns = async () => {
    const response = await dispatch(
      getcmfCampaignsAction(user.CustomerInfo.CustomerId)
    )
    if (response && response.data) {
      const campaignCmf = response.data.response.filter(
        campaign => campaign.CampaignName === 'CMF'
      )

      if (campaignCmf.length > 0) {
        getSpecificCampaign(campaignCmf[0].CampaignReferralId)
      }
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const fetchWarranty = async () => {
    const response = await dispatch(getWarrantyAction())
    if (response && response.data?.Data) {
      setWarrantyOptions(response.data.Data)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const fetchDataCMF = async () => {
    const payload = {
      loanTerm: 24,
      initDate: '2022-05-04T21:52:53.030Z',
      disbursementDate: '2022-05-04T21:52:53.030Z',
      cashValueProduct: productoFinal?.FinalPrice,
      rateDownPayment: 33,
      valueDownPayment: 100,
    }
    const response = await dispatch(getCmfPriceAction(payload))
    if (response && response.data?.data) {
      setPrecioCmf(response.data?.data)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  const getPrices = async PriceInventoryPayload => {
    try {
      const response = await dispatch(
        getPriceInventoryGeneral(PriceInventoryPayload)
      )
      return response.data?.Data
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPriceInventory = async (hits, CustomerId) => {
    const PriceInventoryData = {
      CompanyId: companyId,
      CompanyCode: companyCode,
      WarehouseId: warehouse,
      ProductIds: [],
      CustomerId: CustomerId || null,
      UoMId: null,
    }
    if (hits && hits.length > 0) {
      hits.map(item => PriceInventoryData.ProductIds.push(item.productId))
    }
    const priceData = await getPrices(PriceInventoryData)
    let productArrayWithPrice = []
    if (hits && hits.length > 0) {
      productArrayWithPrice = [...hits]
    }

    try {
      // eslint-disable-next-line array-callback-return
      productArrayWithPrice.map(item => {
        item.BasePrice = priceData?.find(
          product => product.ProductId === item.productId
        )?.BasePrice
        item.TaxCategoryValue = priceData?.find(
          product => product.ProductId === item.productId
        )?.TaxCategoryValue
        item.Cost = priceData?.find(
          product => product.ProductId === item.productId
        )?.Cost
        item.ProductId = priceData?.find(
          product => product.ProductId === item.productId
        )?.ProductId
        item.FinalPrice = priceData?.find(
          product => product.ProductId === item.productId
        )?.FinalPrice
        item.TotalQty = priceData?.find(
          product => product.ProductId === item.productId
        )?.TotalQty
        item.WarehouseQty = priceData?.find(
          product => product.ProductId === item.productId
        )?.WarehouseQty
        item.PriceListId = priceData?.find(
          product => product.ProductId === item.productId
        )?.PriceListId
      })
      return productArrayWithPrice
    } catch (error) {
      return console.error('Error al filtrar precios', error)
    }
  }

  const fetchProduct = async () => {
    const payload = {
      CompanyId: companyId,
      WarehouseId: warehouse,
      ProductId: id,
      GlobalExecution: true,
      PriceListId: null,
    }
    const response = await dispatch(getProductDetaillAction(payload))
    if (response && response.data) {
      let imagesFilter
      if (response?.data[0]?.Images !== null) {
        imagesFilter = response.data[0]?.Images.filter(
          item =>
            item.MediaResourceName === 'Product Image' ||
            item.MediaResourceName === 'Web'
        )
      }
      setProduct({ ...response.data[0], Images: imagesFilter })
      setProductDetail({ ...response.data[0], Images: imagesFilter })
      if (
        response?.data[0]?.PIMAttributes?.similarProducts &&
        response?.data[0]?.PIMAttributes?.similarProducts?.length > 0
      ) {
        fetchPriceInventory(response?.data[0]?.PIMAttributes?.similarProducts)
          .then(response => setSimilarProducts(response))
          .catch(err => {
            throw err
          })
      }
      if (
        response?.data[0]?.PIMAttributes?.relatedProducts &&
        response?.data[0]?.PIMAttributes?.relatedProducts?.length > 0
      ) {
        fetchPriceInventory(response?.data[0]?.PIMAttributes?.relatedProducts)
          .then(response => setRecommendedProducts(response))
          .catch(err => {
            throw err
          })
      }
    } else {
      console.error('Error CMF', response)
      throw new Error(response.toString())
    }
  }

  const getSecureFirstItemOfArray = async arrayData => {
    try {
      return arrayData[0] || {}
    } catch (error) {
      return {}
    }
  }

  const getBarCode = barcodes => {
    let dataReturn = null
    if (barcodes && barcodes.length) {
      let barcodeObject = barcodes.find(obj => obj.DefaultBarcode)
      if (!_.isEmpty(barcodeObject) && !barcodeObject.Barcode)
        barcodeObject = barcodes[0]
      dataReturn = !_.isEmpty(barcodeObject) ? barcodeObject.Barcode : null
    }
    return dataReturn
  }

  const loopOverData = (keys, attributes) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const array = [].constructor
        const object = {}.constructor
        const boolean = true.constructor
        let data = []
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const value = attributes[key]
          if (value) {
            if (value.constructor === object) {
              const objectData = await loopOverData(
                Object.keys(value),
                value
              ).catch(() => [{}])
              data = [...data, ...objectData]
            } else if (value.constructor === array) {
              const names = value.join(', ')
              data = [
                ...data,
                {
                  title: key.charAt(0).toUpperCase() + key.slice(1),
                  value: names,
                },
              ]
            } else if (value.constructor === boolean) {
              data = [
                ...data,
                {
                  title: key.charAt(0).toUpperCase() + key.slice(1),
                  value: value ? 'Yes' : 'No',
                },
              ]
            } else {
              data = [
                ...data,
                {
                  title: key.charAt(0).toUpperCase() + key.slice(1),
                  value,
                },
              ]
            }
          }
        }
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }

  const getPInfoData = async productDetails => {
    const measurements = await getSecureFirstItemOfArray(
      productDetails.Measurements
    )
    const attributes = productDetails.PIMAttributes
      ? productDetails.PIMAttributes.attributes
      : null

    const measurementsKeys = Object.keys(measurements).filter(
      key =>
        key.endsWith('UomName') ||
        key.endsWith('Length') ||
        key.endsWith('Height') ||
        key.endsWith('Width') ||
        key.endsWith('Weight')
    )
    const measurmentsAttributes = []
    measurementsKeys.forEach(foundKey => {
      if (foundKey === 'UomName') {
        measurmentsAttributes.push({
          title: `${foundKey}`,
          value: `${measurements[foundKey]}`,
        })
      } else {
        measurmentsAttributes.push({
          title: `${foundKey}`,
          value: `${measurements[foundKey]} ${measurements[`${foundKey}Abbr`]}`,
        })
      }
    })
    let _attributes = [
      {
        title: 'Brand',
        value: productDetails.BrandName,
      },
      {
        title: 'PartNumber',
        value: productDetails.ManufacturerPartNumber,
      },
      {
        title: 'Barcode',
        value: getBarCode(productDetails.BarCodes),
      },
    ]
    if (attributes) {
      const firstLevel = Object.keys(attributes)
      const filtered = firstLevel?.filter(
        key => !key.includes('cc') && !key.includes('core')
      )
      const otherPimAttributes = await loopOverData(filtered, attributes).catch(
        () => []
      )
      _attributes = [..._attributes, ...otherPimAttributes]
    }
    setCaracteristicasGeneral(_attributes)
    setCaracteristicasUnidadMedida(measurmentsAttributes)
    setStateDetalle(true)
  }

  const removeProduct = () => {
    if (counter > 1) {
      setCounter(counter - 1)
    }
  }

  const addProduct = () => {
    setCounter(counter + 1)
  }

  useEffect(() => {
    fetchProduct()
    fetchWarranty()
    fetchWarehousesAvailability()
    fetchCampaigns()
    if (productoFinal?.BasePrice >= 25) {
      fetchDataCMF()
    }
  }, [])

  useEffect(() => {
    if (ShortDescriptionRef.current && product?.ShortDescription) {
      ShortDescriptionRef.current.innerHTML = product?.ShortDescription
    }
  }, [ShortDescriptionRef.current, product])

  useEffect(() => {
    if (product) {
      getPInfoData(product)
    }
  }, [product])

  const handleInventory = () => {
    setInventory(!inventory)
  }

  const handleCmf = () => {
    setOpenCMF(true)
  }

  const handleCalc = e => {
    e.preventDefault()
    if (meters.length >= 1) {
      setBoxes(true)
    }
  }

  const handleMeters = e => {
    setMeters(e.target.value)
  }

  const handleAdd = async () => {
    const payload = {
      product: {
        ...productoFinal,
        hasDiscount: productoFinal.BasePrice > productoFinal.FinalPrice,
      },
      qty: counter,
    }
    const added = await dispatch(priceListAddToCart([payload.product]))
    if (!added) {
      setShowAlertDontAddProduct(true)
      setTimeout(() => {
        setShowAlertDontAddProduct(false)
        handleClose()
      }, 2000)
    } else {
      handleClose()
    }
  }

  useEffect(() => {
    if (product?.ExternalCodes?.length > 0) {
      const codes = product.ExternalCodes.map(externalCode => {
        if (externalCode.ExternalSystem === 'SCC') {
          externalCode.ExternalSystem = 'SALESFORCE'
        }
        return externalCode
      })

      const filterExternalCodes = codes.filter(
        externalCode =>
          externalCode.ExternalSystem === 'NAF_BASE' ||
          externalCode.ExternalSystem === 'NAF_NOVEY' ||
          externalCode.ExternalSystem === 'IVEND' ||
          externalCode.ExternalSystem === 'SALESFORCE' ||
          externalCode.ExternalSystem === 'PROVIVIENDA'
      )

      setExCodes(filterExternalCodes)
    }
  }, [product])

  const getArrayHiddenColumns = hiddenColumns => {
    const hiddenColumnsCopy = hiddenColumns.filter(column =>
      column.Status === false ? column.ColumnName : false
    )
    return hiddenColumnsCopy.map(column => column.ColumnName)
  }

  const costProduct = productoFinal.Cost
  const avgCost = productoFinal.AvgCost

  return (
    <>
      {product ? (
        <div className="container-card-product-detail">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="close-button product-detail-container-padding">
                <img
                  src={No}
                  alt="close"
                  onClick={() => {
                    handleClose()
                    setShowMaterialsCalculatorPDP(false)
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={5} className="text-center">
              <Carousel
                showStatus={false}
                showThumbs={false}
                showArrows={false}>
                {product.Images &&
                  product.Images.map(image => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          marginBottom: '20px',
                        }}
                        key={image?.ImageId}>
                        <img
                          src={image.URL}
                          alt={image.ImageId}
                          style={{
                            width: '100%',
                            height: 'auto',
                          }}
                          className="img-fluid"
                        />
                      </div>
                    )
                  })}
              </Carousel>
              {!product.Images && <img src={NoImage} />}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={7}>
              <div className="general-product-data product-detail-container-padding">
                <h2 className="product-title">{product.ProductName}</h2>
                <h2 className="product-brand">
                  {product.BrandName || 'GÉNERICO'}
                </h2>
                <p className="product-sku">{product.Sku}</p>
                <ul className="product-tags">
                  {product.Tags &&
                    product.Tags.split(',').map((tag, i) => {
                      return (
                        <li key={i} className="product-tag">
                          {tag}.
                        </li>
                      )
                    })}
                </ul>
                {product?.ShortDescription && (
                  <div
                    ref={ShortDescriptionRef}
                    className="product-short-description"
                  />
                )}
                {product?.PIMAttributes?.attributes?.corePurchase
                  ?.productFactor > 1 ? (
                  <>
                    <p className="product-price">
                      {price === undefined
                        ? 'Sin precio'
                        : '$' +
                          FormatMoney(
                            price /
                              product?.PIMAttributes?.attributes?.corePurchase
                                ?.productFactor,
                            false,
                            2,
                            true
                          )}
                      /m<sup>2</sup>
                    </p>
                    <p className="especial-price-caja">
                      {price === undefined
                        ? 'Sin precio'
                        : '$' + price?.toFixed(2)}
                      /caja
                    </p>
                  </>
                ) : (
                  <p className="product-price">
                    {price === undefined
                      ? 'Sin precio'
                      : '$' + FormatMoney(price, false, 2, true)}
                  </p>
                )}
                <p className="product-card-discount">
                  {productoFinal?.BasePrice === undefined && price === undefined
                    ? ''
                    : productoFinal?.BasePrice === price
                    ? ''
                    : 'Precio regular: $' +
                      productoFinal?.BasePrice?.toFixed(2)}
                </p>
                <br />
                {productoFinal?.BasePrice >= 25 && (
                  <div className="cmf-content">
                    <img src={cmfLogo} alt="logo_cmf" />
                    <p>
                      24 cuotas de ${precioCmf?.monthlyQuota} c/u.{' '}
                      <span>
                        <LightTooltip
                          title="*Valor cuota referencial calculada con 33% de interés anual."
                          placement="left">
                          <img src={info} alt="info-circle" />
                        </LightTooltip>{' '}
                      </span>
                      {cmfCode && (
                        <p>
                          Si el cliente aún no tiene la tarjeta{' '}
                          <button onClick={handleCmf}>ingresa aquí</button>
                        </p>
                      )}
                    </p>
                  </div>
                )}
                {product.HasCalculator && (
                  <div className="container-option-materials-calculator">
                    <img src={calculatorBlue} alt="calculadora" />
                    <p>
                      ¿No estás seguro de cuánto material necesitas para tu
                      proyecto? Nuestra calculadora te lo dirá ingresando{' '}
                      <span onClick={() => setShowMaterialsCalculatorPDP(true)}>
                        aquí
                      </span>
                    </p>
                  </div>
                )}
                <div className="product-counter">
                  <button
                    className="product-btn-counter minus"
                    onClick={removeProduct}>
                    -
                  </button>
                  <p className="product-btn-counter">{counter}</p>
                  <button
                    className="product-btn-counter plus"
                    onClick={addProduct}>
                    +
                  </button>
                </div>
                {seeMargenProd || seeCostProd ? (
                  <CostMarginVisibility
                    seeMargenProd={seeMargenProd}
                    seeCostProd={seeCostProd}
                    cost={costProduct}
                    avgCost={avgCost}
                    price={price}
                    isMargin
                    isCost
                    secondaryColor
                  />
                ) : null}

                <p className="product-unidades">
                  {product.TotalQty} Unidades disponibles en {company}
                </p>
                {product?.PIMAttributes?.attributes?.coreDetail
                  ?.hasWarranty && (
                  <div className="product-warranty">
                    <div className="product-warranty-header">
                      <img src={patch} alt="patch" />
                      <h2>Garantía extendida</h2>
                    </div>
                    <div className="product-warranty-text">
                      <p>
                        Protege este artículo con una garantía adicional, elige
                        una opción para aplicar a tu compra
                      </p>
                    </div>
                    <ul className="product-warranty-options">
                      {warrantyOptions.map((option, index) => {
                        return (
                          <li
                            className={
                              warrantySelect === option.Name
                                ? 'product-warranty-option selected'
                                : 'product-warranty-option'
                            }
                            key={index}>
                            <label htmlFor={option.ExtendedWarrantyId}>
                              {index + 1} año $
                              {price >= 50
                                ? (
                                    (price * option.WarrantyPercent) /
                                    100
                                  ).toFixed(2)
                                : ((50 * option.WarrantyPercent) / 100).toFixed(
                                    2
                                  )}
                            </label>
                            <input
                              type="radio"
                              className="warranty-option"
                              id={option.ExtendedWarrantyId}
                              name="warranty"
                              onClick={() => setWarrantySelect(option.Name)}
                            />
                            <FontAwesomeIcon icon={faCheck} />
                          </li>
                        )
                      })}
                      <li
                        className={
                          warrantySelect === 'no-warranty'
                            ? 'product-warranty-option selected'
                            : 'product-warranty-option'
                        }>
                        <label htmlFor="no-warranty">
                          Sin garantía extendida
                        </label>
                        <input
                          type="radio"
                          className="no-warranty-option"
                          name="warranty"
                          onClick={() => setWarrantySelect('no-warranty')}
                        />
                        <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                  </div>
                )}
                {product?.PIMAttributes?.attributes?.corePurchase
                  ?.productFactor > 1 &&
                  !product.HasCalculator && (
                    <div className="calculadora-de-piso">
                      <div className="calculadora-de-piso-header">
                        <img src={calculator} alt="calculator-logo" />
                        <h2>
                          Calcula cuántas cajas necesitas para tu proyecto
                        </h2>
                      </div>
                      <div className="calculadora-de-piso-body">
                        <p className="instrucciones-calc">
                          Ingresa los m<sup>2</sup> que necesitas cubrir. Para
                          1m
                          <sup>2</sup> necesitas 1 caja
                        </p>
                        <form onSubmit={handleCalc}>
                          <input
                            type="text"
                            placeholder="¿Cuántos m² necesitas?"
                            onChange={handleMeters}
                          />
                          <button type="submit">Calcular</button>
                        </form>
                        {boxes && (
                          <div className="calculadora-de-piso-resultado">
                            <p>
                              Necesitas{' '}
                              {Math.ceil(
                                parseFloat(meters) /
                                  product?.PIMAttributes?.attributes
                                    ?.corePurchase?.productFactor
                              )}{' '}
                              cajas para cubrir {meters} m<sup>2</sup>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                <div className="product-detail-buttons">
                  <button
                    className="product-detail-button-agregar"
                    onClick={
                      price
                        ? () => {
                            handleAdd()
                          }
                        : () => {
                            setShowMessage(true)
                            setTimeout(() => {
                              setShowMessage(false)
                            }, 3000)
                          }
                    }>
                    Agregar
                  </button>
                  <button
                    className="product-detail-button-inventario"
                    onClick={() => {
                      handleInventory()
                      fetchWarehousesAvailability()
                    }}>
                    Inventario
                  </button>
                </div>
                <div>
                  {showMessage ? (
                    <p
                      style={{
                        color: 'red',
                        backgroundColor: '#F8F8F8',
                        padding: '10px',
                        marginTop: '10px',
                      }}>
                      No se puede agregar, no cuenta con precio
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {recommendedProducts?.length > 0 && (
            <ProductsCarrousel
              productsRef={productsRef}
              id={id}
              data={recommendedProducts}
              title={'Productos recomendados'}
            />
          )}
          <div className="product-detail-accordion product-detail-container-padding">
            <div className="detalles-accordion-container">
              <div
                // eslint-disable-next-line react/no-unknown-property
                sx={{
                  boxShadow: 'none',
                  border: '1px solid #DFDFDF',
                  padding: '10px 23px',
                }}>
                <div>
                  <h2
                    className="product-detail-title"
                    style={{ color: '#003082', marginBottom: '30px' }}>
                    Información del producto
                  </h2>
                </div>
                <div>
                  {product?.FullDescription && (
                    <>
                      <h3 style={{ marginBottom: '12px' }}>Detalles</h3>
                      <p>
                        {product?.FullDescription?.replace(
                          /<p>|<ul>|<li>|<h4>|<strong>/g,
                          ' '
                        )?.replace(
                          /<\/p>|<\/li>|<\/ul>|<\/strong>|<\/h4>/g,
                          ' '
                        )}
                      </p>
                    </>
                  )}
                  <div style={{ marginTop: '36px' }}>
                    <h3 style={{ marginBottom: '12px' }}>Características</h3>

                    {caracteristicasGeneral.length > 0 && (
                      <div>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th className="general-table-header codes-external">
                                General
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {caracteristicasGeneral.map(
                              (caracteristica, index) => {
                                return caracteristica.value ? (
                                  <tr className="product-tag" key={index}>
                                    <td>{caracteristica.title}</td>
                                    <td>{caracteristica.value}</td>
                                  </tr>
                                ) : null
                              }
                            )}
                          </tbody>
                        </Table>
                        <br />
                      </div>
                    )}

                    {caracteristicasUnidadMedida.length > 0 && (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th
                              style={{
                                fontSize: '16px',
                              }}
                              className="general-table-header codes-external">
                              Medidas
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {caracteristicasUnidadMedida.map(
                            (caracteristica, index) => {
                              return (
                                <tr className="product-tag" key={index}>
                                  <td>{caracteristica.title}</td>
                                  <td>{caracteristica.value}</td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    )}
                    <br />
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="general-table-header codes-external">
                            Códigos Externos
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {exCodes.length > 0 &&
                          exCodes.map((xcodes, index) => {
                            return xcodes.ExternalCode ? (
                              <tr className="product-tag" key={index}>
                                <td>{xcodes.ExternalSystem}</td>
                                <td>{xcodes.ExternalCode}</td>
                              </tr>
                            ) : null
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            {product?.Documents?.length > 0 && (
              <div className="ficha-accordion-container">
                <div
                  // eslint-disable-next-line react/no-unknown-property
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid #DFDFDF',
                    padding: '10px 23px',
                  }}>
                  <div>
                    <h2
                      className="product-detail-title"
                      style={{ color: '#003082', marginBottom: '30px' }}>
                      Ficha Técnica
                    </h2>
                  </div>
                  <div>
                    {product.Documents &&
                      product?.Documents.filter(
                        document =>
                          document.CompanyId === companyId ||
                          document.MediaResourceName === 'Datasheets'
                      ).map(document => {
                        return (
                          <div
                            key={document?.DocumentId}
                            className="ficha-tecnica-info">
                            <img src={pdfIcon} alt="pdfIcon" />
                            <a
                              href={document?.URL}
                              target="_blank"
                              rel="noreferrer">
                              <p>{document.MediaResourceName}</p>
                              <p>{document.CompanyName}</p>
                            </a>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {similarProducts?.length > 0 && (
            <ProductsCarrousel
              productsRef={productsRef}
              id={id}
              data={similarProducts}
              title={'Productos similares'}
            />
          )}
        </div>
      ) : (
        <MiniLoading full={true} />
      )}
      <Modal
        show={inventory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullscreen={true}>
        <div className="inventory-modal-content">
          <div className="inventory-header">
            <h2>Inventario</h2>
            <img
              src={No}
              alt="closing button"
              onClick={() => {
                const delay = openWithInventario ? 500 : 0
                openWithInventario && handleClose()
                debounce(() => setInventory(false), delay)
              }}
            />
          </div>
          <FilteringTable
            data={inventoryData}
            columns={columns}
            isInventory={true}
            setHiddenColumns={columns => setHiddenColumns(columns)}
            hiddenColumns={getArrayHiddenColumns(hiddenColumns)}
          />
        </div>
      </Modal>
      <Modal
        show={openCMF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered>
        <div className="cmf-modal-box">
          <div className="top-cmf">
            <img src={cmfLogo} alt="logo_cmf" />
            <img
              src={No}
              onClick={() => {
                setOpenCMF(false)
                setCmfCopiado(false)
              }}
              alt="close"
            />
          </div>
          <div className="cmf-clipboard">
            <p>{cmfCode}</p>
            <CopyToClipboard text={cmfCode} onCopy={() => setCmfCopiado(true)}>
              <Clipboard />
            </CopyToClipboard>
            <ShareFill />
          </div>
          {cmfCopiado && (
            <div className="copiado-confirmation">
              <p>Copiado</p>
            </div>
          )}
        </div>
      </Modal>
      <div>
        <Modal
          show={showAlertDontAddProduct}
          backdrop="static"
          className="modal-clase-price-change"
          centered>
          <GenericModal
            setIsOpen={() => setShowAlertDontAddProduct(false)}
            showCaution={true}
            hideCheck={true}
            text={t('GENERIC_MODAL.NO_INVENTORY_ALERT')}
            mostrarButtons={false}></GenericModal>
        </Modal>
      </div>
    </>
  )
}

export default ProductDetail
