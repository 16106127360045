import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './CalculatorSummary.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FormatMoney } from 'Utils/functions'
import { removeFromCart } from 'redux/actions/cartActions'
import useValidatePermission from 'Hooks/useValidatePermission'
import { VAPermissions } from 'Utils/constants'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'
import { QuantityColumn } from 'Components/SingleProductCart/components/QuantityColumn'
import PrecioProducto from 'Components/PrecioProducto'
import { Modal } from 'react-bootstrap'
import NoImage from '../../Assets/Images/Logos/no-image.png'

const CalculatorSummary = () => {
  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const [showModalPrecio, setShowModalPrecio] = useState(false)
  const [productSelected, setProductSelected] = useState({})
  const dispatch = useDispatch()
  const [showModalSuccessUpdatePrecio, setShowModalSuccessUpdatePrecio] =
    useState(false)
  // const { productsCalculate } = useSelector(state => state.material)
  const cart = useSelector(state => state.cart.cart)
  const removeToCart = id => {
    const product = {
      ProductId: id,
    }
    dispatch(removeFromCart({ product }))
  }
  const handleModalSuccessUpdatePrecio = () => {
    if (showModalSuccessUpdatePrecio === false) {
      setShowModalSuccessUpdatePrecio(true)
      setTimeout(() => {
        setShowModalSuccessUpdatePrecio(false)
      }, 5000)
    } else {
      setShowModalSuccessUpdatePrecio(false)
    }
  }
  const setProductChangeValidation = value => {
    const index = cart.findIndex(
      item => item.ProductId === productSelected.ProductId
    )
    dispatch({
      type: 'IS_PRICE_CHANGED',
      payload: { index, value },
    })
  }

  const handleModalEditPrice = product => {
    setShowModalPrecio(!showModalPrecio)
    if (product !== undefined) {
      setProductSelected(product)
    }
  }
  return (
    <>
      <div className="container-calculator-summary">
        <h2 className="h2-tittle">
          Resumen de pedido <span>({cart.length})</span>
        </h2>
        <p className="p-description">
          Verifica los datos antes de generar la cotización
        </p>
        <div className="calculator-summary-table">
          <table className="single-product-cart inventory-table products">
            <thead>
              <tr>
                <th></th>
                <th>Producto</th>
                <th>Código</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Total</th>
                <th>Margen</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((product, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={product.Image}
                      alt={product.Image}
                      className="product-image"
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = NoImage
                      }}
                    />
                  </td>
                  <td>{product.Name}</td>
                  <td>{product.Sku}</td>
                  <td>
                    <QuantityColumn cell={product} />
                  </td>
                  <td>
                    {product.Price !== product.FinalPrice ? (
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: 'orange',
                        }}>
                        ${product.FinalPrice.toFixed(2)}
                      </p>
                    ) : null}
                    ${FormatMoney(product.Price, false, 6, true)}
                    {!isCustomerUser && (
                      <FontAwesomeIcon
                        icon={faSquarePen}
                        style={{ fontSize: '18px', marginLeft: 5 }}
                        onClick={() => handleModalEditPrice(product)}
                      />
                    )}
                  </td>
                  <td>
                    $
                    {FormatMoney(
                      product.Price * product.Quantity,
                      false,
                      6,
                      true
                    )}
                  </td>
                  <td>
                    <CostMarginVisibility
                      seeMargenProd={seeMargenProd}
                      simple
                      isMargin
                      margin={product?.Margin?.toFixed(2)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      data-testid="fa-trash-can"
                      style={{ color: '#F64E60', fontSize: '2rem' }}
                      icon={faTrashCan}
                      onClick={() => {
                        removeToCart(product.ProductId)
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            show={showModalPrecio}
            backdrop="static"
            className="modal-clase-price-change"
            centered>
            <PrecioProducto
              handleModalEditPrice={handleModalEditPrice}
              handleModalSuccesUpdatePrecio={handleModalSuccessUpdatePrecio}
              product={productSelected}
              setNuevoPrecio={() => null}
              setProductChangeValidation={setProductChangeValidation}
            />
          </Modal>
        </div>
      </div>
    </>
  )
}

export default CalculatorSummary
