import { useSelector } from 'react-redux'
import {
  AddUpdateSalesDocumentsService,
  searchSalesDocumentDetailService,
} from 'redux/services/cotizacionServices'

export const useFetchDocumentODV = () => {
  const user = useSelector(state => state.login.user)

  const fetchDocumentOdv = async quotation => {
    const hasOdv = quotation?.QuoteHeader?.RelationDoc?.find(doc =>
      doc.DocumentNumber.includes('ODV')
    )

    const noTaxes = quotation?.QuoteHeader?.TaxExempt

    if (!hasOdv) return
    const payload = {
      DocumentTypeId: hasOdv.DocumentTypeId,
      DocumentHeaderId: hasOdv.DocHeaderId,
      DocumentNumber: hasOdv.DocumentNumber,
      PageNumber: 1,
      PageSize: 1,
    }

    const respuesta = await searchSalesDocumentDetailService(payload)
    const odvAllData = respuesta?.data?.Data

    const addSalesOrderLineId = respuesta?.data?.Data?.Lines.map(prod => ({
      ...prod,
      SalesOrderLineId: prod.DocLineId,
    }))
    const odvProducts = addSalesOrderLineId
    const cotiProds = quotation.QuoteHeader.Lines
    const newArrayProducts = cotiProds.filter(prod => prod.IsActive === true)

    let totalTax = 0
    let totalPrice = 0
    let Quantity = 0

    newArrayProducts.forEach(item => {
      if (noTaxes) {
        totalTax = 0
      } else {
        totalTax += item.Tax
      }
      totalPrice += item.Price * item.Quantity
      Quantity = parseFloat(Quantity) + parseFloat(item?.Quantity)
    })

    const productCheck = newArrayProducts?.map(prod => {
      odvAllData.Amount = totalPrice
      odvAllData.AmountWithTax = noTaxes ? totalPrice : totalPrice + totalTax
      odvAllData.Tax = noTaxes ? 0 : totalTax

      const foundProduct = odvProducts?.find(
        item => item.ProductId === prod.ProductId
      )

      if (foundProduct) {
        if (
          prod.Amount !== foundProduct.Amount ||
          prod.Price !== prod.FinalPrice
        ) {
          foundProduct.Amount = prod.Amount
          foundProduct.AmountWithTax = noTaxes
            ? prod.Amount
            : prod.AmountWithTax
          foundProduct.DiscountAmount = prod.DiscountAmount
          foundProduct.Tax = noTaxes ? 0 : prod.Tax
          foundProduct.Price = prod.Price
          foundProduct.Quantity = Math.ceil(prod.Quantity)
          foundProduct.QtyOrdered = Math.ceil(prod.Quantity)
          if (prod.Price < prod.FinalPrice) {
            prod.AuthorizedPriceBy = null
            prod.AuthorizedPriceByName = null
            prod.NegotiatedPriceBy = user?.AuthenticationInfo?.UserId
            prod.NegotiatedPriceByName = user?.AuthenticationInfo?.UserName
          } else {
            prod.NegotiatedPriceBy = null
            prod.NegotiatedPriceByName = null
          }
        }
      } else {
        if (prod.Price < prod.FinalPrice) {
          prod.AuthorizedPriceBy = null
          prod.AuthorizedPriceByName = null
          prod.NegotiatedPriceBy = user?.AuthenticationInfo?.UserId
          prod.NegotiatedPriceByName = user?.AuthenticationInfo?.UserName
        } else {
          prod.NegotiatedPriceBy = null
          prod.NegotiatedPriceByName = null
        }
        return {
          ...prod,
          SalesOrderLineId: '00000000-0000-0000-0000-000000000000',
          QtyOrdered: prod.Quantity,
        }
      }
      return {
        ...prod,
        SalesOrderLineId: foundProduct?.SalesOrderLineId,
        QtyOrdered: prod.Quantity,
      }
    })

    const odvSavePayload = {
      DocumentTypeId: hasOdv.DocumentTypeId,

      SalesOrder: {
        SalesOrderHeaderId: hasOdv.DocHeaderId,
        ...odvAllData,
        Lines: productCheck,
        TaxExempt: quotation?.QuoteHeader?.TaxExempt,
      },
    }

    await AddUpdateSalesDocumentsService(odvSavePayload)
  }

  return { fetchDocumentOdv }
}
