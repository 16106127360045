import React, { useEffect, useState } from 'react'
import no from '../../Assets/Images/Login/no.svg'
import './precioProducto.css'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { cotizacionPriceChange } from '../../redux/actions/cotizacionActions'
import { setDiscount } from '../../redux/actions/cartActions'
import { FormatMoney, getMarginTotal } from 'Utils/functions'
import { NumericFormat } from 'react-number-format'
import {
  getFinalPriceAction,
  getRazonesPrecioAction,
} from 'redux/actions/clientsActions'
import { t } from 'i18next'

const PrecioProducto = ({
  handleModalEditPrice,
  setProductChangeValidation,
  handleModalSuccesUpdatePrecio,
  product,
  setNuevoPrecio,
  productsRef,
}) => {
  const [maxDiscount, setMaxDiscount] = useState(null)
  const [maxMargin, setMaxMargin] = useState(null)
  const [marginError, setMarginError] = useState(null)
  const [, setNewPerct] = useState(0)
  // const [showMoneyButton, setShowMoneyButton] = useState(true)
  const [anularMargen, setAnularMargen] = useState(false)

  const dispatchCotizacion = useDispatch()

  // Precio Negociacion
  const [precioNegociacion, setPrecioNegociacion] = useState(true)
  // Box Mostrar Razones
  const [mostrarRazones, setMostrarRazones] = useState(false)
  // Razon Precio
  const [razonPrecio, setRazonPrecio] = useState({
    NegotiatedPriceBy: useSelector(
      state => state?.login?.user?.CustomerInfo?.CustomerId
    ),
    NegotiatedPriceByName: useSelector(
      state => state?.login?.user?.AuthenticationInfo?.UserName
    ),
    NegotiatedReason: null,
    NegotiatedReasonCode: null,
    NegotiatedReasonId: null,
    Price: parseFloat(product.Price),
    FinalPrice: parseFloat(product.FinalPrice),
    BasePrice: parseFloat(product.BasePrice),
    Amount: FormatMoney(product.Amount, false, 6),
    DiscountAmount: FormatMoney(product.DiscountAmount, false, 6),
    DiscountAmountLocal: FormatMoney(product.DiscountAmount, false, 6),
    AmountWithTax: FormatMoney(product.AmountWithTax, false, 6),
    // FirstPriceBase : parseFloat(product.BasePrice),
    // FirstDiscountAmount: parseFloat(product.DiscountAmount)
  })

  // List Razones
  const [razones, setRazones] = useState([])
  // Terminar cambio precio por razon
  const companyId = useSelector(state => state.company.CompanyId)
  const companyCode = useSelector(state => state.company.CompanyCode)
  const warehouseId = useSelector(state => state.company.WarehouseId)
  const productListId = useSelector(
    state => state.cotizacion.QuoteHeader.ProductListId
  )
  const customerId = useSelector(
    state => state.cotizacion.QuoteHeader.CustomerId
  )

  const [razonSelect, setRazonSelect] = useState(null)

  // Handle Mostrar Razones
  const handleMostrarRazones = () => {
    setMostrarRazones(!mostrarRazones)
  }

  // const handlePriceButton = () => {
  //   setShowMoneyButton(!showMoneyButton)
  // }

  // Handel State
  const handleChange = value => {
    value = value.replace(/,/g, '')
    if (!precioNegociacion) {
      value =
        ((100 - parseFloat(value)) *
          (product.FinalPrice - product.DiscountAmount)) /
        100
    }
    const tempAmountWithTax =
      parseFloat(value) * product.Quantity * (1 + product.TaxCategoryValue)
    const tax = parseFloat(value) * product.TaxCategoryValue * product.Quantity
    setRazonPrecio(prevState => ({
      ...prevState,
      Price: parseFloat(value),
      Amount: parseFloat(value) * product.Quantity,
      AmountWithTax: tempAmountWithTax,
      Tax: tax,
    }))
  }

  const reasonDefault = razones.filter(
    r => r.ReasonId === 'D9C0EFFB-09F6-481B-ABDD-8B8D05081DF1'
  )
  const handleRazon = razon => {
    setRazonSelect(razon)
    setRazonPrecio(prevState => ({
      ...prevState,
      NegotiatedReason: razon.Name,
      NegotiatedReasonCode: razon.Code,
      NegotiatedReasonId: razon.ReasonId,
    }))
  }

  // Handle Check Precio Negociacion
  const handleChangeCheck = () => {
    setPrecioNegociacion(!precioNegociacion)
  }

  // PRODUCT DISCOUNT MARGIN
  const getDiscountMargin = async () => {
    const payload = {
      CompanyId: companyId,
      CompanyCode: companyCode,
      WarehouseId: warehouseId,
      ProductId: product.ProductId,
      CustomerId: customerId,
      PriceListId: productListId,
      Qty: 1,
    }
    const Data = await dispatchCotizacion(getFinalPriceAction(payload))
    if (Data && Data?.length > 1) {
      setMaxDiscount(Data[1].FinalPrice)
      setMaxMargin(Data[1].DiscountPct)
    } else {
      throw new Error(Data)
    }
  }

  // Obtener Razones Precio
  const fetchRazonesPrecio = async () => {
    const payload = {
      reasonTypeId: 'CDD84712-0748-49DC-A58C-608470D04EDC',
      pageSize: 1000,
      pageNumber: 1,
    }
    const Data = await dispatchCotizacion(getRazonesPrecioAction(payload))
    if (Data && Data?.length > 0) {
      setRazones(Data)
    } else {
      throw new Error(Data)
    }
  }

  const cambiarPrecio = async (e, overridePrice = false) => {
    if (isNaN(razonPrecio.Price) || parseFloat(razonPrecio.Price) <= 0) {
      setMarginError('ErrPriceTitle')
      return
    }

    if (razonPrecio.Price <= maxDiscount && !razonPrecio.NegotiatedReason) {
      handleMostrarRazones()
    } else {
      const newMargin = getMarginTotal(
        razonPrecio.Price,
        product.Cost || product.AvgCost || 0.0,
        product.Quantity
      )
      setNewPerct(newMargin)
      if (razonPrecio.Price >= maxDiscount || overridePrice) {
        razonPrecio.NegotiatedReason = reasonDefault[0]?.Name
        razonPrecio.NegotiatedReasonCode = reasonDefault[0]?.Code
        razonPrecio.NegotiatedReasonId = reasonDefault[0]?.ReasonId
        setNuevoPrecio(razonPrecio)
        // check if setProductChangeValidation is set
        if (setProductChangeValidation) {
          setProductChangeValidation(true)
        }
        handleModalEditPrice()
        handleModalSuccesUpdatePrecio()
        const modifiedProduct = {
          ...product,
          ...razonPrecio,
          Margin: newMargin,
          Price: razonPrecio.Price,
        }
        if (razonPrecio.Price < modifiedProduct.BasePrice) {
          const discount = modifiedProduct.BasePrice - modifiedProduct.Price
          modifiedProduct.DiscountAmount = discount
          modifiedProduct.DiscountAmountLocal = discount
          modifiedProduct.descuento = discount
        } else {
          // set discounts to 0
          modifiedProduct.DiscountAmount = 0
          modifiedProduct.DiscountAmountLocal = 0
          modifiedProduct.DiscountPct = 0
          modifiedProduct.descuento = 0
        }
        const payload = {
          product: modifiedProduct,
        }
        dispatchCotizacion(setDiscount(payload))
        dispatchCotizacion(cotizacionPriceChange(razonPrecio))
      } else {
        setMarginError(
          `Precio fuera del margen permitido por ${(
            newMargin - maxMargin
          ).toFixed(2)}%`
        )
        setAnularMargen(true)
      }
    }
  }

  useEffect(() => {
    fetchRazonesPrecio()
    getDiscountMargin()
  }, [])

  return (
    <>
      <div className="modal-box-update-precio">
        <div className="edit-precio-modal-container">
          <div className="edit-precio-modal-header">
            <h2>Solicitud de cambio de precios</h2>
            <span onClick={handleModalEditPrice} className="close-modal">
              <img src={no} alt="close" />
            </span>
          </div>
          <div className="edit-precio-modal-body">
            <p>
              Precio inicial ${FormatMoney(product?.FinalPrice, false, 2, true)}
            </p>
            <button className="razones-button" onClick={handleMostrarRazones}>
              Selecciona código de razón
            </button>
            <div className="toggle">
              <p>
                <span>{precioNegociacion ? 'Precio ' : 'Porcentaje '}</span>de
                negociación
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={precioNegociacion}
                  name="precioNegociacion"
                  value={razonPrecio.precioNuevo}
                  onChange={handleChangeCheck}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="currency-input">
              {precioNegociacion ? (
                <button className="price-sign">$</button>
              ) : (
                <button className="price-sign">%</button>
              )}
              <NumericFormat
                thousandSeparator={true}
                decimalSeparator={'.'}
                decimalScale={6}
                name="precioNuevo"
                id="precioNuevo"
                onChange={event => handleChange(event.target.value)}
                placeholder="Introduce el nuevo precio"
              />
            </div>
          </div>
          {marginError && (
            <p className="margin-error-message">{t(marginError)}</p>
          )}
          <div className="modal-buttons">
            <button className="btn-cambiar" onClick={cambiarPrecio}>
              Cambiar precio
            </button>
          </div>
        </div>
      </div>

      {/* Mostrar Razones de Cambio de Precio */}
      <Modal
        show={anularMargen}
        container={productsRef?.current ? productsRef.current : null}
        backdrop={false}
        className="modal-clase-price-change"
        centered>
        <div className="modal-price-change-allow">
          <span onClick={() => setAnularMargen(false)} className="close-modal">
            <img src={no} alt="close" />
          </span>
          <div className="price-allow-text">
            <h2>El precio esta debajo del margen permitido</h2>
            <h2>¿Deseas continuar?</h2>
          </div>
          <div className="price-allow-buttons">
            <button
              className="price-allow-true"
              onClick={(e, overridePrice = true) =>
                cambiarPrecio(e, overridePrice)
              }>
              Si
            </button>
            <button
              className="price-allow-false"
              onClick={() => setAnularMargen(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={mostrarRazones}
        // container={productsRef?.current ? productsRef.current : null}
        backdrop={false}
        className="modal-clase-price-change"
        centered>
        <div className="modal-box-update-razones">
          <div className="razones-modal-container">
            <div className="razones-modal-header">
              <h2>Código de razón</h2>
              <span onClick={handleMostrarRazones} className="close-modal">
                <img src={no} alt="close" />
              </span>
            </div>
            <div className="razones-modal-body">
              {razones.length > 0 &&
                razones.map(razon => {
                  return (
                    <li
                      key={razon?.ReasonId}
                      className={
                        razonSelect?.ReasonId === razon?.ReasonId
                          ? 'razones-option razon-selected'
                          : 'razones-option'
                      }>
                      <div className="razon-text">
                        <label htmlFor={razon.ReasonId}>{razon.Name}</label>
                        <p>{razon.Description}</p>
                      </div>
                      <div className="razones-options-buttons">
                        {razonSelect?.ReasonId === razon?.ReasonId ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          <input
                            type="radio"
                            id={razon.ReasonId}
                            name="price-change"
                            value={razon.ReasonId}
                            onClick={() => handleRazon(razon)}
                          />
                        )}
                      </div>
                    </li>
                  )
                })}
              <div className="modal-buttons">
                <button
                  className="btn-continuar"
                  onClick={handleMostrarRazones}>
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PrecioProducto
