import React, { useState } from 'react'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { getMarginTotal, marginTotal } from 'Utils/functions'
import './costMarginVisibility.css'

const CostMarginVisibility = ({
  seeCostProd,
  seeMargenProd,
  cost,
  avgCost,
  quantity,
  price,
  isMargin,
  isCost,
  istotalMargin,
  simple,
  margin,
  secondaryColor,
  symbol,
  centerInTable,
}) => {
  const [visibilityCostMargin, setVisibilityCostMargin] = useState(true)

  return (
    <div
      className={
        centerInTable
          ? 'center-table-container secondary-color'
          : secondaryColor
          ? 'cart-card-margin secondary-color'
          : 'cart-card-margin'
      }>
      <Box
        alignItems={'center'}
        display={'flex'}
        flexBasis={'row'}
        gap={'18px'}>
        {seeCostProd && isCost && (
          <p>{`Costo: $${
            visibilityCostMargin ? '**.**' : (cost || avgCost || 0.0).toFixed(2)
          }`}</p>
        )}
        {seeMargenProd && isMargin ? (
          <p>
            {!simple ? 'Margen: ' : ''}
            {symbol ? '$' : ''}
            {!visibilityCostMargin && !simple
              ? getMarginTotal(price, cost || avgCost, quantity).toFixed(2)
              : simple && !visibilityCostMargin
              ? margin
              : '**.**'}
            {!symbol ? '%' : ''}
          </p>
        ) : null}

        {seeMargenProd && istotalMargin ? (
          <p>
            {`Total Margen: ${
              visibilityCostMargin
                ? '**.**'
                : marginTotal(cost, price).toFixed(2)
            }%`}
          </p>
        ) : null}
      </Box>

      {visibilityCostMargin ? (
        <FontAwesomeIcon
          icon={faEyeSlash}
          className={
            centerInTable
              ? 'center-table-container secondary-color'
              : 'icon-margin-cost'
          }
          onClick={() => {
            setVisibilityCostMargin(!visibilityCostMargin)
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faEye}
          className={
            centerInTable
              ? 'center-table-container secondary-color'
              : secondaryColor
              ? 'icon-margin-cost secondary-color'
              : 'icon-margin-cost'
          }
          onClick={() => {
            setVisibilityCostMargin(!visibilityCostMargin)
          }}
        />
      )}
    </div>
  )
}

export default CostMarginVisibility
