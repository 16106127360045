import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import './tareas.css'
import { ROUTES } from 'Utils/constants'

function Tareas({ tarea, generals, keyProp }) {
  const status = () => {
    if (tarea.StatusDescription === 'Completado') {
      return '#89E8CF'
    } else if (tarea.StatusDescription === 'En proceso') {
      return '#FFEE91'
    } else {
      return '#FF96A0'
    }
  }

  return (
    <Row key={keyProp}>
      <div className="quote-activity">
        <Col xl={7} lg={7} md={7} sm={12} xs={12}>
          <div
            className="quote-activity-generals"
            style={{ flexBasis: generals.flex }}>
            <p>
              <span className="strong-text">
                {tarea?.Type === 'PRICE_APPR'
                  ? 'Aprobar cambio de precio'
                  : tarea?.Name}
              </span>
            </p>
            <p>Documento N°:{tarea?.DocumentNumber || tarea?.Description}</p>
            <p>Fecha de asignación: {tarea?.Date?.slice(0, 10) || 'MEH'}</p>
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6} xs={6}>
          <div
            className="quote-activity-status"
            style={{ backgroundColor: status(), borderRadius: 20 }}>
            <p>{tarea?.StatusDescription}</p>
          </div>
        </Col>

        <Col xl={1} lg={1} md={1} sm={6} xs={6}>
          <div className="quote-activity-tooltips">
            <div
              style={{
                display: 'flex',
                backgroundColor: '#11D29E',
                width: '20px',
                height: '20px',
                borderRadius: '20%',
                alignItems: 'center',
                position: 'relative',
              }}>
              <Link
                to={`${ROUTES.editQuoteHeaderId}${
                  tarea?.DocHeaderId || tarea?.Key
                }/${tarea?.DocumentNumber || tarea?.Description}`}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ width: '20px', color: 'white' }}
                />
              </Link>
            </div>
          </div>
        </Col>

        <div
          className="status-icon"
          style={{ backgroundColor: status() }}></div>
      </div>
    </Row>
  )
}

export default Tareas
