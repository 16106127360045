import React from 'react'
import { t } from 'i18next'
import { FormatMoney } from 'Utils/functions'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

export const FooterTextValue = ({ value, type, seeMargenProd, rounded }) => {
  switch (type) {
    case t('SELL_BY_PROD_T.DISCOUNT_LABEL'):
      return (
        <div>
          <p style={{ color: '#FF2A40' }}>{type}</p>
          <p style={{ color: '#FF2A40' }}>
            ${FormatMoney(value, rounded, 2, true)}
          </p>
        </div>
      )
    case t('SELL_BY_PROD_T.MARGIN_LABEL'):
      return (
        <div>
          <p>{type}</p>
          <CostMarginVisibility
            seeMargenProd={seeMargenProd}
            simple
            isMargin
            margin={value}
            secondaryColor
          />
        </div>
      )
    default:
      return (
        <div>
          <p>{type}</p>
          <p>${FormatMoney(value, rounded, 2, true)}</p>
        </div>
      )
  }
}
