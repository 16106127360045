import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import { Plus, Dash } from 'react-bootstrap-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import PrecioProducto from '../PrecioProducto'
import GenericModal from '../GenericModal'
import Modal from 'react-bootstrap/Modal'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart } from '../../redux/actions/cartActions'
import { FormatMoney } from '../../Utils/functions'
import './cartProduct.css'
import { t } from 'i18next'
import { useProductQuantity } from './hooks/useProductQuantity'
import useValidatePermission from 'Hooks/useValidatePermission'
import { VAPermissions } from 'Utils/constants'
import { useHasProductWithPriceUnderCost } from './hooks/useHasProductWithPriceUnderCost'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

function CartProduct({
  product,
  productsRef,
  setProductChangeValidation,
  disableDeleteProduct = false,
}) {
  const {
    handleProdQuantity,
    quantity,
    allowFractionalQty,
    handleAddProduct,
    handleRemoveProduct,
  } = useProductQuantity(product)
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const { hasPriceUnderCost, isCommercialSeller } =
    useHasProductWithPriceUnderCost()

  // REDUX VARIABLES
  const dispatch = useDispatch()
  // STATES PRECIO MODALS
  const [showModalPrecio, setShowModalPrecio] = useState(false)

  // STATES PRECIO UPDATE SUCCESS
  const [showModalSuccessUpdatePrecio, setShowModalSuccessUpdatePrecio] =
    useState(false)

  const handleModalEditPrice = () => {
    setShowModalPrecio(!showModalPrecio)
  }

  const handleModalSuccessUpdatePrecio = () => {
    if (showModalSuccessUpdatePrecio === false) {
      setShowModalSuccessUpdatePrecio(true)
      setTimeout(() => {
        setShowModalSuccessUpdatePrecio(false)
      }, 5000)
    } else {
      setShowModalSuccessUpdatePrecio(false)
    }
  }

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const canChangePrice = useValidatePermission(VAPermissions.priceOverrideAutho)
  const seeCostProd = useValidatePermission(VAPermissions.seeCostProd)
  const cost = product.Cost
  const avgCost = product.AvgCost
  const quantityProduct = product.Quantity
  const price = product.Price

  return (
    <div className="cart-card">
      <Row>
        <Col md={{ span: 3 }}>
          <img
            src={
              (product.Images && product.Images[0].URL) ||
              product.productImage ||
              product.DefaultProductPicture ||
              NoImage
            }
            alt="product-cart"
            className="img-fluid shadow-2-strong"
          />
        </Col>
        <Col md={{ span: 9 }}>
          <Row className="product-card-top-content">
            <Col md={{ span: 8 }} className="product-cart-text">
              <div className="product-cart-product-generals">
                <div className="top-text">
                  <p>{product.ProductName}</p>
                  <p>{product.Sku}</p>
                </div>
              </div>
            </Col>
            <Col md={{ span: 4 }}>
              <div className="remove-total">
                <div className="trash">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    onClick={async () => {
                      const payload = {
                        product,
                      }
                      dispatch(removeFromCart(payload))
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9 }} sm={{ span: 11 }}>
              <div className="product-cart-product-numbers">
                <div className="product-cart-product-generals-amount">
                  <div className="counter">
                    <Dash
                      style={{
                        border: '1px solid #F8F9FD',
                        borderRadius: '4px',
                      }}
                      onClick={handleRemoveProduct}
                    />
                    <InputMask
                      mask={
                        allowFractionalQty
                          ? /^\d{1,6}(\.\d{1,4})?$/
                          : /^\d{1,6}?$/
                      }
                      maskChar=""
                      value={quantity}
                      onChange={handleProdQuantity}
                      className="number"
                      inputMode="decimal"
                    />
                    <Plus
                      style={{
                        border: '1px solid #F8F9FD',
                        borderRadius: '4px',
                      }}
                      onClick={handleAddProduct}
                    />
                  </div>
                  <p className="multiply">X</p>
                  <div className="edit">
                    {product.FinalPrice && (
                      <p
                        style={{
                          textDecoration:
                            product?.Price !== product.FinalPrice
                              ? 'line-through'
                              : 'none',
                          color:
                            product?.Price !== product.FinalPrice
                              ? 'orange'
                              : 'black',
                        }}>
                        {'$' +
                          FormatMoney(
                            product?.Price !== product.FinalPrice
                              ? product.FinalPrice
                              : product.Price,
                            false,
                            6,
                            true
                          )}
                      </p>
                    )}
                    {product?.Price !== product.FinalPrice && (
                      <p>{'$' + FormatMoney(product?.Price, false, 6, true)}</p>
                    )}
                  </div>
                  {!isCustomerUser && (
                    <FontAwesomeIcon
                      icon={faSquarePen}
                      onClick={handleModalEditPrice}
                      title={t('Edit')}
                      className="square-pen-edit"
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={{ span: 3 }} sm={{ span: 1 }}>
              <div className="final-price-cart">
                <p>
                  {'$' +
                    FormatMoney(
                      product?.Price * product.Quantity,
                      false,
                      6,
                      true
                    )}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {seeMargenProd || seeCostProd ? (
          <CostMarginVisibility
            seeCostProd={seeCostProd}
            seeMargenProd={seeMargenProd}
            cost={cost}
            avgCost={avgCost}
            quantity={quantityProduct}
            price={price}
            isMargin
            isCost
            secondaryColor
          />
        ) : null}
      </div>
      {/* MODAL UPDATE PRICE */}
      <Modal
        container={productsRef.current}
        show={showModalPrecio}
        backdrop="static"
        className="modal-clase-price-change"
        centered>
        <PrecioProducto
          handleModalEditPrice={handleModalEditPrice}
          handleModalSuccesUpdatePrecio={handleModalSuccessUpdatePrecio}
          product={product}
          setProductChangeValidation={setProductChangeValidation}
          setNuevoPrecio={() => null}
          productsRef={productsRef}
        />
      </Modal>

      {/* MODAL SUCCESS UPDATE PRICE */}
      <Modal
        container={productsRef.current}
        show={showModalSuccessUpdatePrecio}
        backdrop="static"
        className="modal-clase-price-change"
        centered>
        <GenericModal
          setIsOpen={handleModalSuccessUpdatePrecio}
          text={
            !canChangePrice
              ? t('GENERIC_MODAL.REQUEST_PENDT_APPROVE')
              : hasPriceUnderCost && isCommercialSeller
              ? t('GENERIC_MODAL.REQUEST_PENDT_APPROVE')
              : t('GENERIC_MODAL.PRICE_SUCCESS_CHANGED')
          }
          mostrarButtons={false}></GenericModal>
      </Modal>
    </div>
  )
}

export default CartProduct
