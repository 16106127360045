import { images } from 'Assets/Images'
import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { formatStartEndDate } from 'Utils/Helpers/formatDate'

const defTermValue = (startDate, endDate) =>
  `Promoción válida del ${formatStartEndDate(
    startDate,
    endDate
  )}. Precio no incluye ITBMS`

export const Square2ToPrintNOffer = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    defTermValue(prod1?.StartDate, prod1?.FinishDate)
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    defTermValue(prod2?.StartDate, prod2?.FinishDate)
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)
  const prodPrice1 = FormatMoney(prod1?.Price || 0)
  const prodPrice2 = FormatMoney(prod2?.Price || 0)
  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        key={products.page}
        data-testid="printer-preview-left-canvas-2"
        id="printer-preview-left-canvas-square-2"
        className="printer-preview-left-canvas-square-2">
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '100%',
            top: '-12px',
          }}>
          <div
            className="printer-preview-left-content-body-square-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.Price && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ marginTop: '-18px' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{ fontSize: '102px', marginTop: '34px' }}>
                  {prodPrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ marginTop: '-18px' }}>
                  {prodPrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-40px',
                  right: 20,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  bottom: '-58px',
                  right: 20,
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '30px', marginTop: 0 }}>
                    {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2"
            style={{ top: 'calc(50% - 18px)', left: '10px' }}>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  fontSize: '8px',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms1}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '50%',
            top: '48%',
          }}>
          <div
            className="printer-preview-left-content-body-square-2-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title-2">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku-2">
              {prod2?.Sku || ''}
            </p>
            {prod2?.Price && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ marginTop: '-18px' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price-2"
                  style={{ fontSize: '102px', marginTop: '34px' }}>
                  {prodPrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ marginTop: '-18px' }}>
                  {prodPrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-40px',
                  right: 20,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  bottom: '-58px',
                  right: 20,
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '30px', marginTop: 0 }}>
                    {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2-2"
            style={{ top: 'calc(100% - 18px)', left: '10px' }}>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  fontSize: '8px',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms2}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.offer2}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
