import React, { useState } from 'react'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import { FormatMoney, validateRepeatedProduct } from '../../Utils/functions'
import Modal from 'react-bootstrap/Modal'
import './productCard.css'
import { Col } from 'react-bootstrap'
import ProductDetail from '../ProductDetail'
import { useDispatch, useSelector } from 'react-redux'

function ProductCard({ product_id: productId, data, productsRef }) {
  const cart = useSelector(state => state.cart.cart)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [openWithInventario, setOpenWithInventario] = useState(null)

  // CART
  // const cart = useSelector(state => state.cart.cart)

  const handleOpen = inventario => {
    setIsOpen(true)
    setOpenWithInventario(inventario)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Col xl={3} lg={3} md={4} sm={6} xs={12}>
        {data.empty ? (
          <div className="product-card-empty"></div>
        ) : (
          <div className="product-carrousel-card">
            <div
              className="product-carrousel-card-img"
              onClick={() => {
                handleOpen(false)
              }}>
              <img
                src={data.productImage ? data.productImage : NoImage}
                alt="product-image"
                className="img-fluid"
              />
            </div>
            <div className="product-carrousel-card-generals">
              <p className="product-carrousel-card-generals-name">
                {data?.ProductName}
              </p>
              <p className="product-carrousel-card-generals-sku">
                {data.brandName || 'Generico'}
              </p>
              <p className="product-carrousel-card-generals-sku">
                {data.SystemCode}
              </p>
              <p className="product-carrousel-card-generals-price">
                {'$' + FormatMoney(data.FinalPrice, false, 6, true)}
              </p>
            </div>
            <div className="product-carrousel-card-button">
              <button
                onClick={() => validateRepeatedProduct(data, cart, dispatch)}>
                Agregar
              </button>
            </div>
          </div>
        )}
      </Col>
      <Modal
        show={isOpen}
        onHide={handleClose}
        container={productsRef ? productsRef.current : null}
        backdrop={false}
        enforceFocus={false}
        autoFocus={false}
        className="modal-clase-producto">
        <ProductDetail
          id={data.ProductId}
          productsRef={productsRef || null}
          producto_final={data}
          price={data.FinalPrice}
          cost={data.Cost || data.AvgCost || 0.0}
          openWithInventario={openWithInventario}
          handleClose={handleClose}
        />
      </Modal>
    </>
  )
}

export default ProductCard
