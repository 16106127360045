import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen } from '@fortawesome/free-solid-svg-icons'
import './cotizacion.css'
import { Col, Row } from 'react-bootstrap'
import { ROUTES, docHeaderStatusDescription } from 'Utils/constants'

function Cotizacion({ cotizacion, generals, setCotizacionUpdated }) {
  const status = () => {
    if (
      cotizacion.DocHeaderStatusDescription !==
        docHeaderStatusDescription.open &&
      cotizacion.StatusDescription !== docHeaderStatusDescription.open
    ) {
      return '#F897A1'
    }
  }

  return (
    <div>
      <Row>
        <div className="quote-activity">
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <div
              className="quote-activity-generals"
              style={{ flexBasis: generals.flex }}>
              <p>
                <span className="strong-text">Nueva cotización</span>
              </p>
              <p>
                Documento N°:
                {cotizacion?.DocumentNumber || cotizacion?.Description}
              </p>
              <p>
                Fecha de asignación:{' '}
                {cotizacion?.DocumentDate?.slice(0, 10) ||
                  cotizacion?.Date?.slice(0, 10)}
              </p>
            </div>
          </Col>

          <Col xl={2} lg={2} md={2} sm={6} xs={6}>
            <div
              className="quote-activity-status"
              style={{ backgroundColor: status(), borderRadius: 20 }}>
              <p>
                {cotizacion?.DocHeaderStatusDescription ||
                  cotizacion?.StatusDescription}
              </p>
            </div>
          </Col>

          <Col xl={1} lg={1} md={1} sm={6} xs={6}>
            <div className="quote-activity-tooltips">
              <Link
                to={`${ROUTES.editQuoteHeaderId}${
                  cotizacion?.DocHeaderId || cotizacion?.Key
                }/${cotizacion?.DocumentNumber || cotizacion?.Description}`}>
                <FontAwesomeIcon icon={faSquarePen} />
              </Link>
            </div>
          </Col>

          <div
            className="status-icon"
            style={{ backgroundColor: status() }}></div>
        </div>
      </Row>
    </div>
  )
}

export default Cotizacion
