import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ProductDetail from '../ProductDetail'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import cmfArrow from '../../Assets/Images/Product/cmf_arrow.svg'
import Modal from 'react-bootstrap/Modal'
import './product.css'
import { useDispatch, useSelector } from 'react-redux'
import { addToList } from '../../redux/actions/listActions'
import { showProductsList } from '../../redux/actions/productActions.js'
import {
  FormatMoney,
  toTitleCase,
  validateRepeatedProduct,
} from '../../Utils/functions'
import GenericModal from 'Components/GenericModal'
import { t } from 'i18next'
import { animateScroll as scroll } from 'react-scroll'
import WarningModal from 'Components/WarningModal/WarningModal'

function Product({
  product,
  productsRef,
  inListScreen,
  setShowMaterialsCalculatorPDP,
  setProductDetail,
  isActive,
}) {
  const cart = useSelector(state => state.cart.cart)
  const location = useLocation()
  const isEditCoti = location.pathname.includes('editar_cotizacion')
  const [isOpen, setIsOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [noActiveMessage, setNoActiveShowMessage] = useState(false)
  const [openWithInventario, setOpenWithInventario] = useState(null)
  const [name, setName] = useState('')
  const customerId = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerId
  )
  const [showAlertDontAddProduct, setShowAlertDontAddProduct] = useState(false)
  const dispatch = useDispatch()
  const [closeWarningModal, setCloseWarningModal] = useState(false)
  const cotizacion = useSelector(state => state.cotizacion?.QuoteHeader)

  const handleOpen = inventario => {
    if (!inListScreen) {
      scroll.scrollToTop({
        delay: 0,
        smooth: 'linear',
        duration: 1,
      })
      setIsOpen(true)
      dispatch(showProductsList(false))
      setOpenWithInventario(inventario)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleCloseModal = () => {
    setCloseWarningModal(false)
  }

  useEffect(() => {
    setName(toTitleCase(product?.Brand))
  }, [product])

  const handleAddProductToCart = async () => {
    let cost
    if (isEditCoti && cotizacion.DocHeaderStatusAction === 'OPEN') {
      const cost =
        product?.Cost === 0 || product?.Cost === null
          ? product?.AvgCost
          : product?.Cost

      if (cost === 0 || cost === null) {
        setCloseWarningModal(true)
        return
      } else {
        setCloseWarningModal(false)
      }
    }

    if (product?.IsActive) {
      if (
        product?.FinalPrice !== undefined &&
        product?.FinalPrice !== null &&
        (cotizacion.DocHeaderStatusAction === 'OPEN' ||
          (cost !== 0 && cost !== null))
      ) {
        const added = validateRepeatedProduct(
          product,
          cart,
          dispatch,
          customerId
        )

        if (!added) {
          setShowAlertDontAddProduct(true)
          setTimeout(() => {
            setShowAlertDontAddProduct(false)
          }, 2000)
        }
      } else {
        setShowMessage(true)
        setTimeout(() => {
          setShowMessage(false)
        }, 3000)
      }
    } else {
      setNoActiveShowMessage(true)
      setTimeout(() => {
        setNoActiveShowMessage(false)
      }, 3000)
    }
  }

  const colorBadge = () => {
    if (product.WarehouseQty > 4) {
      return '#11D29E'
    } else if (product.WarehouseQty <= 4 && product.WarehouseQty >= 2) {
      return '#FFDE20'
    } else {
      return '#FF2A40'
    }
  }

  return (
    <>
      <WarningModal open={closeWarningModal} onClose={handleCloseModal} />
      <div className="product-card-wrapper">
        <div className="product-card">
          <div>
            <div
              className="product-card-image"
              id="product-card-image"
              onClick={() => handleOpen(false)}>
              <img
                src={(product.Images && product.Images[0].URL) || NoImage}
                alt={product.ProductName}
                className="img-fluid"
              />
            </div>
            <div className="product-card-generals">
              <h2
                onClick={() => handleOpen(false)}
                className="product-card-generals-title">
                {product.ProductName}
              </h2>
              <p className="product-card-brand">{name || 'Desconocido'}</p>
              <p className="product-card-sku">{product.Sku}</p>
              <div className="product-card-price">
                <p>
                  {product.FinalPrice === undefined
                    ? 'Sin precio'
                    : '$' + FormatMoney(product.FinalPrice, false, 2, true)}
                </p>
                {(product.PriceListId ===
                  '39FC82DF-2B58-4DD1-BE9C-F50A317D7266' ||
                  product.PriceListId ===
                    'FDCA4798-57A8-42FC-A778-1E6471153A0B') && (
                  <img src={cmfArrow} alt="cmf-arrow" className="img-fluid" />
                )}
              </div>

              {product.BasePrice - product.FinalPrice > 0 && (
                <div className="product-card-discount-container">
                  <p className="product-card-discount">
                    {'Precio regular $' + product.BasePrice.toFixed(2)}
                  </p>
                </div>
              )}
              <p className="product-card-store">
                Tienda{' '}
                <span
                  style={{
                    backgroundColor: colorBadge(),
                    borderRadius: '5px',
                    padding: '2px 6px',
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}>
                  {product.WarehouseQty <= 0 ||
                  product.WarehouseQty === undefined
                    ? 0
                    : product.WarehouseQty}
                </span>{' '}
              </p>
              {showMessage && (
                <p className="product-no-price">
                  No se puede agregar, no cuenta con precio
                </p>
              )}
              {noActiveMessage && (
                <p className="product-no-price">
                  Producto inactivo, validar con comercial
                </p>
              )}
            </div>
          </div>
          <div className="product-card-buttons">
            {inListScreen ? (
              <button
                onClick={() => {
                  dispatch(addToList(product))
                }}>
                Agregar a Lista
              </button>
            ) : (
              <button onClick={() => handleAddProductToCart()}>Agregar</button>
            )}
            {inListScreen ? null : (
              <button onClick={() => handleOpen(true)}>Inventario</button>
            )}
          </div>
          <div
            className="product-promo"
            style={{ display: product.Promo ? 'block' : 'none' }}>
            <p>{product.Promo || 'No Promo'}</p>
          </div>
          {product.BasePrice - product.FinalPrice > 0 && (
            <div className="product-promo">Oferta</div>
          )}
        </div>
        <div>
          <Modal
            show={isOpen}
            onHide={handleClose}
            container={productsRef.current}
            backdrop={false}
            enforceFocus={false}
            className="modal-clase-producto">
            <ProductDetail
              id={product.ProductId}
              productsRef={productsRef}
              producto_final={product}
              price={product.FinalPrice}
              cost={product.Cost || product.AvgCost || 0.0}
              openWithInventario={openWithInventario}
              handleClose={handleClose}
              setShowMaterialsCalculatorPDP={setShowMaterialsCalculatorPDP}
              setProductDetail={setProductDetail}
            />
          </Modal>
        </div>
        <div>
          {/* MODAL Alert dont Add producto to cart */}
          <Modal
            show={showAlertDontAddProduct}
            backdrop="static"
            className="modal-clase-price-change"
            centered>
            <GenericModal
              setIsOpen={() => setShowAlertDontAddProduct(false)}
              showCaution={true}
              hideCheck={true}
              text={t('GENERIC_MODAL.NO_INVENTORY_ALERT')}
              mostrarButtons={false}></GenericModal>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default Product
