import React, { useEffect, useRef, useMemo, useState } from 'react'
import { useInfiniteHits } from 'react-instantsearch-hooks-web'
import SingleProductFilteringTable from '../SingleProductFilteringTable'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import { useDispatch, useSelector } from 'react-redux'
import { getPriceInventory } from '../../redux/actions/priceProductsAction'
import { emptyCart, priceListAddToCart } from '../../redux/actions/cartActions'
import { Modal } from 'react-bootstrap'
import GenericModal from 'Components/GenericModal'
import { t } from 'i18next'
import { validateRepeatedProduct } from 'Utils/functions'

export default function InfiniteHits({
  SearchBox,
  hitComponent: HitComponent,
  client,
  setProductDetails,
  setIsOpen,
  setOpenWithInventario,
  showProducts,
  ...props
}) {
  const { hits, isLastPage, showMore, results } = useInfiniteHits()

  const sentinelRef = useRef(null)
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  // get cart from redux
  const cart = useSelector(state => state.cart.cart)
  const companyId = useSelector(state => state.company.CompanyId)
  const [showAlertDontAddProduct, setShowAlertDontAddProduct] = useState(false)

  const searchColumns = useMemo(
    () => [
      {
        Header: 'Código',
        accessor: 'codigo',
      },
      {
        Header: 'Descripción',
        accessor: 'descripcion',
      },
      {
        Header: 'Imagen',
        accessor: 'imagen',
        hideEnabled: true,
      },
      {
        Header: 'Marca',
        accessor: 'marca',
        hideEnabled: true,
      },
      {
        Header: 'Existencia',
        accessor: 'existencia',
        hideEnabled: true,
      },
      {
        Header: 'Descuento',
        accessor: 'descuento',
        hideEnabled: true,
      },
      {
        Header: 'Precio',
        accessor: 'precio',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
      },
    ],
    []
  )

  const getIdProducts = data => {
    const matches = data.map(match => {
      return match?.ProductId
    })
    return matches
  }

  const getInventoryById = async (id, client) => {
    return await dispatch(getPriceInventory(id, client))
  }

  const formatPorducts = async (products, productsId) => {
    const inventory = await getInventoryById(productsId, client)

    // format products
    const productsFormatted = products.map(product => {
      const inventoryProduct = inventory.find(
        inventory => inventory?.ProductId === product?.ProductId
      )

      return {
        ...product,
        codigo: product?.Sku,
        descripcion: product?.ShortName,
        imagen: product.Images ? product?.Images[0]?.URL : NoImage,
        marca: product?.Brand,
        existencia: inventoryProduct?.TotalQty || 0,
        descuento: inventoryProduct?.Discount || 0,
        precio: inventoryProduct?.FinalPrice || 0,
        acciones:
          inventoryProduct?.FinalPrice && inventoryProduct?.IsActive
            ? 'Agregar'
            : 'Sin Precio',
        Cost: inventoryProduct?.Cost || 0,
        AvgCost: inventoryProduct?.AvgCost || 0,
        AvgPrice: inventoryProduct?.AvgPrice || 0,
        BasePrice: inventoryProduct?.BasePrice || 0,
      }
    })

    setProducts(productsFormatted)
  }

  const filterByCompany = (data, companyId) => {
    const productsByCompany = data.filter(product => {
      if (Array.isArray(product.Company)) {
        const company = product.Company.find(
          company => company.CompanyId === companyId
        )
        if (!company) {
          return false
        }
        return true
      }
      return false
    })
    return productsByCompany
  }

  const setSearchData = data => {
    const productsByCompany = filterByCompany(data, companyId)
    const productsId = getIdProducts(productsByCompany)
    if (productsId.length !== 0) {
      formatPorducts(productsByCompany, productsId)
    }
  }

  const addToCart = async product => {
    const added = validateRepeatedProduct(
      product,
      cart,
      dispatch,
      client?.CustomerId
    )

    if (!added) {
      setShowAlertDontAddProduct(true)
      setTimeout(() => {
        setShowAlertDontAddProduct(false)
      }, 2000)
    }
  }

  const checkProductInCartByClient = () => {
    const newCart = [...cart]
    dispatch(emptyCart())
    saveCart(newCart)
  }

  const saveCart = newCart => {
    dispatch(priceListAddToCart(newCart, client?.CustomerId))
  }

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          entry.isIntersecting && !isLastPage && showMore()
        })
      })
      observer.observe(sentinelRef.current)
      return () => observer.disconnect()
    }
  }, [isLastPage])

  useEffect(() => {
    client && checkProductInCartByClient()
  }, [client])

  useEffect(() => {
    if (hits?.length !== 0 && results?.query !== '') {
      setSearchData(hits)
    }
  }, [results?.query, results?.page])

  return (
    <div>
      <div className="ais-InfiniteHits">
        <div className="ais-InfiniteHits-list">
          <SingleProductFilteringTable
            sentinelRef={sentinelRef}
            SearchBox={SearchBox}
            data={products}
            columns={searchColumns}
            hideFooter={true}
            addToCart={addToCart}
            setProductDetails={setProductDetails}
            setIsOpen={setIsOpen}
            setOpenWithInventario={setOpenWithInventario}
          />
        </div>
      </div>
      <div>
        {/* MODAL Alert dont Add producto to cart */}
        <Modal
          show={showAlertDontAddProduct}
          backdrop="static"
          className="modal-clase-price-change"
          centered>
          <GenericModal
            setIsOpen={() => setShowAlertDontAddProduct(false)}
            showCaution={true}
            hideCheck={true}
            text={t('GENERIC_MODAL.NO_INVENTORY_ALERT')}
            mostrarButtons={false}></GenericModal>
        </Modal>
      </div>
    </div>
  )
}
