import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import { ROUTES } from 'Utils/constants'

const SalesOrderAprove = ({ saleOrder, generals }) => {
  const [iconEditOdv, setIconEditOdv] = useState(true)

  const status = () => {
    if (saleOrder.StatusDescription === 'Completado') {
      return '#89E8CF'
    } else if (saleOrder.StatusDescription === 'En proceso') {
      return '#FFEE91'
    } else {
      return '#FF96A0'
    }
  }
  useEffect(() => {
    if (saleOrder.StatusDescription === 'Completado') {
      setIconEditOdv(false)
    } else {
      setIconEditOdv(true)
    }
  }, [saleOrder.StatusDescription])

  return (
    <div>
      <Row>
        <div className="quote-activity">
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <div
              className="quote-activity-generals"
              style={{ flexBasis: generals.flex }}>
              <p>
                <span className="strong-text">{saleOrder?.Name}</span>
              </p>
              <p>
                Documento N°:
                {saleOrder?.DocumentNumber || saleOrder?.Description}
              </p>
              <p>
                Fecha de asignación:{' '}
                {saleOrder?.DocumentDate?.slice(0, 10) ||
                  saleOrder?.Date?.slice(0, 10)}
              </p>
            </div>
          </Col>

          <Col xl={2} lg={2} md={2} sm={6} xs={6}>
            <div
              className="quote-activity-status"
              style={{ backgroundColor: status(), borderRadius: 20 }}>
              <p>
                {saleOrder?.DocHeaderStatusDescription ||
                  saleOrder?.StatusDescription}
              </p>
            </div>
          </Col>

          <Col xl={1} lg={1} md={1} sm={6} xs={6}>
            <div className="quote-activity-tooltips">
              {iconEditOdv && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#11D29E',
                      width: '20px',
                      height: '20px',
                      borderRadius: '20%',
                      alignItems: 'center',
                      position: 'relative',
                    }}>
                    <Link
                      to={`${ROUTES.saleOrderAprove}${
                        saleOrder.DocHeaderId || saleOrder.Key
                      }/${saleOrder.DocumentNumber || saleOrder.Description}`}>
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ width: '20px', color: 'white' }}
                      />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </Col>

          <div
            className="status-icon"
            style={{ backgroundColor: status() }}></div>
        </div>
      </Row>
    </div>
  )
}

export default SalesOrderAprove
