import React, { useState } from 'react'
import { Download } from 'react-bootstrap-icons'
import { CircularProgress, Popover } from '@mui/material'
import './downloadButton.css'
import { documentTypeId } from 'Utils/constants'

export const DownloadButton = ({ disabled, download }) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const [loadingQuote, setLoadingQuote] = useState(false)
  const [loadingOdc, setLoadingOdc] = useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownloadPdf = async docType => {
    await download(false, docType)
    handleClose()
  }

  const handleDownloadQuote = async () => {
    try {
      setLoadingQuote(true)
      await handleDownloadPdf(documentTypeId.QUOTATION)
    } catch (error) {
      console.log('download error: ', error)
    } finally {
      setLoadingQuote(false)
    }
  }

  const handleDownloadOdc = async () => {
    try {
      setLoadingOdc(true)
      await handleDownloadPdf(documentTypeId.ODC)
    } catch (error) {
      console.log('download error: ', error)
    } finally {
      setLoadingOdc(false)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <button
        aria-describedby={id}
        onClick={handleClick}
        disabled={disabled}
        style={{
          backgroundColor: disabled ? 'gray' : '#11D29E',
          color: disabled ? 'lightgray' : '#FFFFFF',
        }}>
        <Download />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <div className="popover-buttons-wrapper">
          <button
            className={
              loadingQuote
                ? 'popover-content-button-loading'
                : 'popover-content-button'
            }
            disabled={loadingQuote}
            onClick={handleDownloadQuote}>
            {loadingQuote ? (
              <CircularProgress
                sx={{
                  '& .MuiCircularProgress-svg': { color: '#FFFFFF' },
                }}
                size={21}
              />
            ) : (
              'Cotización'
            )}
          </button>
          <button
            disabled={loadingOdc}
            onClick={handleDownloadOdc}
            className={
              loadingOdc
                ? 'popover-content-button-loading'
                : 'popover-content-button'
            }>
            {loadingOdc ? (
              <CircularProgress
                sx={{
                  '& .MuiCircularProgress-svg': { color: '#FFFFFF' },
                }}
                size={21}
              />
            ) : (
              'Orden de compra'
            )}
          </button>
        </div>
        <div className="popover-triangle" />
      </Popover>
    </div>
  )
}
