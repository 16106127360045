/* eslint-disable no-useless-catch */
import { ROUTES } from 'Utils/constants'
import {
  COTIZACION_SUCCESS,
  COTIZACION_INIT,
  COTIZACION_UPDATE,
  COTIZACION_ADD_PRODUCT,
  COTIZACION_ADD_AMOUNT,
  COTIZACION_ADD_CLIENT,
  COTIZACION_REMOVE_CLIENT,
  COTIZACION_ADD_ADDRESS,
  APPROVE_PRICE_CHANGE,
  COTIZACION_ADD_PDV,
  COTIZACION_QUITAR_ADDRESS,
  COTIZACION_GUARDAR_TYPO_DOCUMENT,
  COTIZACION_ELIMINAR_PRODUCTO,
  COTIZACION_ADD_COMMENT,
  COTIZACION_ADD_AMOUNT_EDIT,
  COTIZACION_ADD_SUCURSAL,
  ADD_COMPANY_COTIZACION,
  COTIZACION_CAMBIO_TIPO,
  ADD_PRICE_CHANGE,
  ADD_SALES_AGENT,
  ADD_CHANNEL,
  MOSTRAR_DESCUENTO,
  MODIFICAR_CLIENTE_CONTADO,
  SET_PRICE_LIST,
  COTIZACION_ADD_CREATED_BY_NAME,
  COTIZACION_UPDATE_PLANID_LINES,
  COTIZACION_UPDATE_WAREHOUSE_LINES,
  ACTION_MODAL_CUSTOMER,
} from '../types'

import {
  AddUpdateSalesDocumentsService,
  getPdfCotizacionService,
  getSalesAgentsService,
  searchSalesDocumentDetailService,
  searchSalesDocumentsService,
  sendEmailCotizacionService,
  uploadDocumentHistory,
  uploadFileService,
  salesRelatedDocumentsService,
} from 'redux/services/cotizacionServices'

export const agregarCotizacion = productos => {
  // agregar producto
  const cotizacionAddProduct = product => ({
    type: COTIZACION_ADD_PRODUCT,
    payload: product,
  })
  return async (dispatch, getState) => {
    dispatch(cotizacionAddProduct({ Lines: productos.Lines }))
  }
}

export const agregarCliente = cliente => {
  const cotizacionAddClient = client => ({
    type: COTIZACION_ADD_CLIENT,
    payload: client,
  })

  return async dispatch => {
    try {
      dispatch(
        cotizacionAddClient({
          CustomerId: cliente.id,
          CustomerNameDocument: cliente.cliente,
          CustomerExternalCode: cliente.externalCode,
          CustomerTypeId: cliente.customerType,
          EmailAddress: cliente.correo,
          PriceListId: cliente.priceListId,
          Telephone: cliente.phoneNumber,
        })
      )
    } catch (error) {
      throw error
    }
  }
}

export const cotizacionRemoveClient = () => ({
  type: COTIZACION_REMOVE_CLIENT,
})
export const cotizacionAddAmountEdit = amount => ({
  type: COTIZACION_ADD_AMOUNT_EDIT,
  payload: amount,
})
export const activeModalCustomer = action => ({
  type: ACTION_MODAL_CUSTOMER,
  payload: action,
})

export const addAmounts = montos => {
  const cotizacionAddAmount = amount => ({
    type: COTIZACION_ADD_AMOUNT,
    payload: amount,
  })

  return async dispatch => {
    try {
      dispatch(cotizacionAddAmount(montos))
    } catch (error) {
      throw error
    }
  }
}

export const cotizacionReStart = () => ({
  type: COTIZACION_INIT,
})

export const addPriceList = payload => ({
  type: SET_PRICE_LIST,
  payload,
})

export const agregarDireccion = direccion => ({
  type: COTIZACION_ADD_ADDRESS,
  payload: direccion,
})

export const agregarPDV = pdv => ({
  type: COTIZACION_ADD_PDV,
  payload: pdv,
})
export const addSalesAgentFullName = name => ({
  type: COTIZACION_ADD_CREATED_BY_NAME,
  payload: name,
})
export const updateCotizacionCompany = estado => ({
  type: ADD_COMPANY_COTIZACION,
  payload: estado,
})
export const agregarSucursal = sucursal => ({
  type: COTIZACION_ADD_SUCURSAL,
  payload: sucursal,
})
export const updatePlanIdLineas = ({
  fulfillmentPlanId,
  fulfillmentPlanDescription,
  cart,
}) => ({
  type: COTIZACION_UPDATE_PLANID_LINES,
  payload: {
    cart,
    fulfillmentPlanId,
    fulfillmentPlanDescription,
  },
})

export const updateWarehouseIdLineas = ({
  WarehouseId,
  WarehouseCode,
  WarehouseDescription,
  WarehouseAddress,
}) => ({
  type: COTIZACION_UPDATE_WAREHOUSE_LINES,
  payload: {
    WarehouseId,
    WarehouseCode,
    WarehouseDescription,
    WarehouseAddress,
  },
})

export const cotizacionUpdate = cotizacion => ({
  type: COTIZACION_UPDATE,
  payload: cotizacion,
})

export const cotizacionChangeType = tipo => ({
  type: COTIZACION_CAMBIO_TIPO,
  payload: tipo,
})

export const addComment = c => ({
  type: COTIZACION_ADD_COMMENT,
  payload: c,
})

export const addChannel = channel => ({
  type: ADD_CHANNEL,
  payload: channel,
})

export const guardarCotizacion = (currentQuotation, props) => {
  const cotizacionSave = quotation => ({
    type: COTIZACION_SUCCESS,
    payload: {
      DocumentTypeId: currentQuotation.DocumentTypeId,
      QuoteHeader: {
        ...quotation.SalesDocumentInfo,
      },
    },
  })

  return async dispatch => {
    try {
      const {
        data: { Data },
      } = await AddUpdateSalesDocumentsService(currentQuotation)

      dispatch(cotizacionSave(Data))

      props.history.push(
        `${ROUTES.editQuoteHeaderId}${Data.SalesDocumentInfo?.DocHeaderId}/${Data.SalesDocumentInfo?.DocumentNumber}`
      )
      return Data
    } catch (error) {
      throw ('ERROR AL GUARDAR:', error)
    }
  }
}

export const updateCotizacion = comment => {
  const addComment = c => ({
    type: COTIZACION_ADD_COMMENT,
    payload: c,
  })

  return async (dispatch, getState) => {
    dispatch(addComment(comment))
    const cotizacion = getState().cotizacion
    cotizacion.QuoteHeader.Comment = comment
    try {
      await AddUpdateSalesDocumentsService(cotizacion)
    } catch (error) {
      throw new Error('ERROR AL GUARDAR: ' + error)
    }
  }
}

export const quitarDireccion = () => ({
  type: COTIZACION_QUITAR_ADDRESS,
})

export const guardarTypeDocument = typoDocument => ({
  type: COTIZACION_GUARDAR_TYPO_DOCUMENT,
  payload: typoDocument,
})

export const eliminarProducto = index => ({
  type: COTIZACION_ELIMINAR_PRODUCTO,
  payload: index,
})

export const cotizacionPriceChange = negotiation => ({
  type: ADD_PRICE_CHANGE,
  payload: negotiation,
})

export const cotizacionPriceChangeApproval = approvalInfo => ({
  type: APPROVE_PRICE_CHANGE,
  payload: approvalInfo,
})

export const addSalesAgent = payload => ({
  type: ADD_SALES_AGENT,
  payload,
})

export const modificarClienteContado = payload => ({
  type: MODIFICAR_CLIENTE_CONTADO,
  payload,
})

export const mostrarDescuento = payload => ({
  type: MOSTRAR_DESCUENTO,
  payload,
})

export const searchSalesDocuments = payload => async () => {
  try {
    const response = await searchSalesDocumentsService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {
    return err
  }
}

export const salesRelatedDocuments = payload => async () => {
  try {
    const response = await salesRelatedDocumentsService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {
    return err
  }
}

export const downloadPdfAction = (payload, setPdfLoading) => async () => {
  try {
    const response = await getPdfCotizacionService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      window.open(
        data?.Data?.BlobResponse?.FileUrl,
        '_blank',
        'noopener,noreferrer'
      )
      return data.Data
    }
  } catch (err) {
    throw err
  } finally {
    setPdfLoading(null)
  }
}

export const getPdfCotizacionAction = payload => async () => {
  try {
    const response = await getPdfCotizacionService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    throw err
  }
}

export const sendEmailCotizacion = (payload, setEmailLoading) => async () => {
  try {
    const response = await sendEmailCotizacionService(payload)
    const { data, status } = response
    if (status === 200 && data?.PDFResponse) {
      return data
    }
  } catch (err) {
    throw err
  } finally {
    setEmailLoading(null)
  }
}

export const getSalesAgents =
  (payload, setSearch, setSalesAgents, setLoading) => async dispatch => {
    try {
      setLoading(true)
      const response = await getSalesAgentsService(payload)
      const { data, status } = response
      if (status === 200 && data?.Data) {
        setSalesAgents(data.Data)
        setSearch(data.Data)
      }
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }

export const searchSalesDocumentDetail = payload => async () => {
  try {
    const response = await searchSalesDocumentDetailService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data
    }
  } catch (err) {
    throw err
  }
}

export const uploadFileAction = payload => async () => {
  try {
    const response = await uploadFileService(payload)
    const { data, status } = response
    if (status === 200 && data?.data) {
      return data
    } else {
      return data
    }
  } catch (err) {
    // throw err;
    return err
  }
}
export const uploadDocumentHistoryAction = payload => async () => {
  try {
    const response = await uploadDocumentHistory(payload)
    const { data, status } = response
    if (status === 200 && data?.data) {
      return data
    } else {
      return data
    }
  } catch (err) {
    // throw err;
    return err
  }
}
