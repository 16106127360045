import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addMany, addOne, removeOne } from 'redux/actions/cartActions'

export const useProductQuantity = product => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.cart)

  const [quantity, setQuantity] = useState(product.Quantity)

  const allowFractionalQty = product.AllowFractionalQty
  const handleProdQuantity = e => {
    const value = e.target.value
    const regex = /^\d*\.?\d{0,4}$/
    if (!regex.test(value)) return
    if (!allowFractionalQty && value.includes('.')) return
    setQuantity(value === '' ? '' : value)
    if (value === '') return
    if (
      allowFractionalQty &&
      value.toString()[value.toString().length - 1] === '.'
    )
      return

    const valor =
      value.toString().slice(-2) === '.0' ? value : parseFloat(value)
    const cleanValue = isNaN(valor) ? '' : valor

    if (
      cleanValue > 0 &&
      value.toString()[value.toString().length - 1] !== '.'
    ) {
      const payload = {
        product,
        numberOfProd: parseFloat(value),
      }
      dispatch(addMany(payload))
    }
    setQuantity(value)
    return value
  }

  const handleRemoveProduct = () => {
    let newQuantity

    if (quantity === '') {
      newQuantity = 0
    } else {
      const [integerPart, decimalPart] = quantity.toString().split('.')
      const incrementedInteger = parseInt(integerPart) - 1
      newQuantity = decimalPart
        ? `${incrementedInteger}.${decimalPart}`
        : `${incrementedInteger}`
    }

    setQuantity(newQuantity)
    dispatch(removeOne({ product, newQuantity: parseFloat(newQuantity) }))
    return newQuantity
  }

  const handleAddProduct = () => {
    if (quantity >= 999999) return

    let newQuantity

    if (quantity === '') {
      newQuantity = 1
    } else {
      const [integerPart, decimalPart] = quantity.toString().split('.')
      const incrementedInteger = parseInt(integerPart) + 1
      newQuantity = decimalPart
        ? `${incrementedInteger}.${decimalPart}`
        : `${incrementedInteger}`
    }

    setQuantity(newQuantity)
    dispatch(addOne({ product, newQuantity: parseFloat(newQuantity) }))
    return newQuantity
  }

  useEffect(() => {
    const fixedQuantity = product?.Quantity
    setQuantity(fixedQuantity)
  }, [cart])

  return {
    handleProdQuantity,
    allowFractionalQty,
    quantity,
    handleAddProduct,
    handleRemoveProduct,
  }
}
