import { useSelector } from 'react-redux'
import { creditAccountsService } from 'redux/services/clientsServices'

export const useValidateCreditAccountNumber = () => {
  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const validateCreditAccountNumber = async value => {
    const isCreditCommercial = value?.credit === 'yes'
    let creditAccountNumber = null

    if (
      isCreditCommercial &&
      !value?.accountItemSelected?.CreditAccountNumber
    ) {
      const data = await creditAccountsService({
        customerId: useSelectorQuoteObject?.QuoteHeader?.CustomerId,
        companyId: useSelectorQuoteObject?.QuoteHeader?.CompanyId,
      })
      const currentCreditAccount = data?.data?.Data?.find(
        account =>
          account?.CreditAccountId?.toUpperCase() ===
          value?.accountItemSelected?.CreditAccountId?.toUpperCase()
      )
      creditAccountNumber = currentCreditAccount?.CreditAccountNumber
    }
    return creditAccountNumber
  }

  return { validateCreditAccountNumber }
}
