import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FormatMoney } from 'Utils/functions'

export const TableContent = ({
  rows,
  addToCart,
  sentinelRef,
  prepareRow,
  showModalProducts,
}) => {
  const handleClickProduct = product => {
    showModalProducts('product', product)
  }

  const handleClickInventary = product => {
    showModalProducts('inventary', product)
  }

  return (
    <>
      {rows?.map((row, i) => {
        prepareRow(row)
        return (
          <tr key={i} {...row.getRowProps()}>
            {row.cells.map(cell => {
              return cell.column.Header === 'Imagen' ? (
                <td
                  onClick={() => {
                    handleClickProduct(cell.row.original)
                  }}
                  key={cell.value}
                  {...cell.getCellProps({
                    style: {
                      maxWidth: '100px',
                      textAlign: 'center',
                    },
                  })}>
                  <img
                    src={cell.value}
                    alt="product-image"
                    style={{ maxWidth: '42px' }}
                  />
                </td>
              ) : cell.column.Header === 'Precio' ||
                cell.column.Header === 'Descuento' ? (
                <td {...cell.getCellProps()}>
                  $
                  {cell.value === 0
                    ? '0.00'
                    : FormatMoney(cell.value, true, 2, true)}
                </td>
              ) : cell.column.Header === 'Existencia' ? (
                <td
                  {...cell.getCellProps()}
                  onClick={() => {
                    handleClickInventary(cell.row.original)
                  }}>
                  {cell.render('Cell')}
                </td>
              ) : cell.column.Header === 'Acciones' ? (
                <td key={cell.value}>
                  {cell.value === 'Agregar' ? (
                    <FontAwesomeIcon
                      style={{ color: '#003082', fontSize: '2rem' }}
                      icon={faCirclePlus}
                      onClick={() => {
                        addToCart(cell.row.original)
                      }}
                    />
                  ) : !cell.row.original?.precio ? (
                    <p>Sin Precio</p>
                  ) : (
                    <p>Inactivo</p>
                  )}
                </td>
              ) : cell.column.Header === 'Descripción' ||
                cell.column.Header === 'Código' ? (
                <td
                  key={cell.value}
                  onClick={() => {
                    handleClickProduct(cell.row.original)
                  }}
                  {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              ) : (
                <td key={cell.value} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              )
            })}
          </tr>
        )
      })}
      <tr
        className="ais-InfiniteHits-sentinel"
        ref={sentinelRef}
        aria-hidden="true"
      />
    </>
  )
}
