import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MiniLoading from '../../Components/MiniLoading'
import Cotizacion from '../../Components/Cotizacion'
import Tareas from '../../Components/Tareas'
import SalesOrderAprove from 'Components/SalesOrderAprove/SalesOrderAprove'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Navigation from '../../Components/Navigation'
import Pagination from '../../Components/Pagination'
import './misTareas.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import searchIcon from '../../Assets/Images/BuscarCotizacion/search.svg'
import {
  getSummaryTaskAction,
  getTaskTypesAction,
} from 'redux/actions/tasksActions'

function MisTareas() {
  const dispatch = useDispatch()
  // CONSTANTS
  const [tasks, setTasks] = useState([])
  const [, setCotizacionUpdated] = useState(false)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [, setShowModal] = useState(false)
  const [tasksTypes, setTasksTypes] = useState([])

  // DOCUMENT TYPE FILTER
  const [typeValue, setTypeValue] = useState('ALL')
  const [typeName, setTypeName] = useState('Todo')
  const [showTypes, setShowTypes] = useState(false)

  // STATUS FILTER
  const [statusList, setStatusList] = useState([
    { StatusId: null, Name: 'Todos' },
  ])
  const [status, setStatus] = useState({ StatusId: null, Name: 'Todos' })
  const [showStatus, setShowStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // PAGINATION LOGIC
  const [currentPage, setCurrentPage] = useState(1)
  const [registers, setRegisters] = useState(null)
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(3)
  const pageSize = 16
  const pageNums = []
  const totalPages = Math.ceil(registers / pageSize)

  // REDUX STATE
  const salesAgentId = useSelector(
    state => state.login?.user.CustomerInfo?.CustomerId
  )

  for (let i = 1; i <= totalPages; i++) {
    pageNums.push(i)
  }

  const generals = {
    flex: '45%',
  }

  const fetchTaskTypes = async () => {
    const response = await dispatch(getTaskTypesAction())
    if (response?.data?.Data) {
      setTasksTypes(response.data.Data)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
  }

  useEffect(() => {
    fetchTaskTypes()
  }, [])

  const fetchTasks = async () => {
    setTasks([])
    setErrorMessage(null)
    setIsLoading(true)
    setShowModal(false)

    const payload = {
      Type: typeValue,
      StatusId: status && status.StatusId ? status.StatusId : '',
      SalesAgentId: salesAgentId,
      WarehouseId: null,
      SearchText: search,
      AppType: null,
      PageSize: pageSize,
      PageNumber: currentPage,
    }
    const response = await dispatch(getSummaryTaskAction(payload))
    if (response && response.data?.Data.length > 0) {
      setTasks(response?.data?.Data)
      setRegisters(response?.data?.Data[0]?.TotalRegisters)
    } else {
      setErrorMessage('Sin Resultados.')
      console.error('Error:', response.toString())
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (typeValue) {
      fetchTasks()
    }
  }, [typeValue, search, currentPage, status])

  const searchQuote = e => {
    if (e.keyCode === 13) {
      setSearch(e.target.value.trimEnd().trimStart())
      setCurrentPage(1)
      setLastIndex(3)
      setFirstIndex(0)
    }
  }

  const searchTextRef = useRef(null)

  const searchQuoteText = value => {
    if (value !== '') {
      setSearch(value.trimEnd().trimStart())
      setCurrentPage(1)
      setLastIndex(3)
      setFirstIndex(0)
    }
  }

  const handleTypes = () => {
    setShowTypes(!showTypes)
  }

  const handleType = (e, type) => {
    restore()
    setTypeName(type.Name)
    setTypeValue(type.Code)
    setStatusList(type.Status)
    setStatus(type.Status[0])
  }

  const handleSelectedStatus = (e, status) => {
    restore()
    setStatus(status)
  }

  const handleStatus = () => {
    setShowStatus(!showStatus)
  }

  const handleEmpty = e => {
    if (e.target.value.length === 0) {
      setSearch('')
    }
  }

  const restore = () => {
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
    setShowStatus(false)
    setShowTypes(false)
  }

  return (
    <>
      <Navigation />
      <section className="tareas-main">
        <div className="tareas-header">
          <h2>Mis Tareas</h2>
        </div>
        <div className="tareas-content">
          <Row className="tareas-search ">
            <Col sm={7} className="tareas-search-bar">
              <div className="position-relative">
                <input
                  ref={searchTextRef}
                  disabled={isLoading}
                  type="text"
                  placeholder="Buscar"
                  onChange={handleEmpty}
                  onKeyDown={searchQuote}
                />
                <img
                  src={searchIcon}
                  className="task-search-icon"
                  alt="search"
                  onClick={() => searchQuoteText(searchTextRef.current.value)}
                />
              </div>
            </Col>
            <Col className="tareas-search-filter">
              <div className="tareas-filter-content" onClick={handleTypes}>
                <h2>{typeName}</h2>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <ul
                className={
                  showTypes
                    ? 'tareas-filter-options'
                    : 'tareas-filter-options-hidden'
                }>
                {tasksTypes &&
                  tasksTypes?.map(type => {
                    return (
                      <li
                        className="filter-option"
                        onClick={e => handleType(e, type)}
                        key={type.Code}>
                        <input
                          type="radio"
                          className="radio"
                          id={type.Code}
                          value={type.Code}
                          name="tipo"
                        />
                        <label htmlFor={type.Code}>{type.Name}</label>
                      </li>
                    )
                  })}
              </ul>
            </Col>
            <Col className="tareas-search-filter">
              <div className="tareas-filter-content" onClick={handleStatus}>
                <h2>{status?.Name}</h2>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <ul
                className={
                  showStatus
                    ? 'tareas-filter-options'
                    : 'tareas-filter-options-hidden'
                }>
                {statusList.map(status => {
                  return (
                    <li
                      className="filter-option"
                      onClick={e => handleSelectedStatus(e, status)}
                      key={status.StatusId}>
                      <input
                        type="radio"
                        className="radio"
                        id={status.StatusId}
                        name="tipo"
                        value={status.StatusId}
                      />
                      <label htmlFor={status.StatusId}>{status.Name}</label>
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>
        </div>
        {isLoading ? (
          <MiniLoading />
        ) : (
          <>
            <div className="tareas-listado">
              <div className="tareas-listado-left">
                {tasks &&
                  tasks.slice(0, 8).map(task => {
                    if (task.Type === 'DOC_QUOTE') {
                      return (
                        <Cotizacion
                          key={task.DocumentNumber}
                          cotizacion={task}
                          generals={generals}
                          setCotizacionUpdated={setCotizacionUpdated}
                        />
                      )
                    } else if (task.Type === 'SALE_ORDER') {
                      return (
                        <SalesOrderAprove
                          key={task.DocumentNumber}
                          saleOrder={task}
                          generals={generals}
                          // setCotizacionUpdated={setCotizacionUpdated}
                        />
                      )
                    } else {
                      return (
                        <Tareas
                          key={task.Key ? task.Key : task.Date}
                          keyProp={task.Key ? task.Key : task.Date}
                          tarea={task}
                          generals={generals}
                        />
                      )
                    }
                  })}
              </div>
              <div className="tareas-listado-right">
                {tasks &&
                  tasks.slice(8, 16).map(task => {
                    if (task.Type === 'DOC_QUOTE') {
                      return (
                        <Cotizacion
                          key={task.DocumentNumber}
                          cotizacion={task}
                          generals={generals}
                          setCotizacionUpdated={setCotizacionUpdated}
                        />
                      )
                    } else if (task.Type === 'SALE_ORDER') {
                      return (
                        <SalesOrderAprove
                          key={task.DocumentNumber}
                          saleOrder={task}
                          generals={generals}
                          // setCotizacionUpdated={setCotizacionUpdated}
                        />
                      )
                    } else {
                      return (
                        <Tareas
                          key={task.Key ? task.Key : task.Date}
                          keyProp={task.Key ? task.Key : task.Date}
                          tarea={task}
                          generals={generals}
                        />
                      )
                    }
                  })}
              </div>
            </div>
            {errorMessage ? (
              <div className="error-message-mis-tareas">
                <p>{errorMessage}</p>
              </div>
            ) : (
              <div className="mis-tareas-pagination">
                <Pagination
                  type={'paginationTarea'}
                  firstIndex={firstIndex}
                  lastIndex={lastIndex}
                  currentPage={currentPage}
                  pageNums={pageNums}
                  setCurrentPage={setCurrentPage}
                  setFirstIndex={setFirstIndex}
                  setLastIndex={setLastIndex}
                />
              </div>
            )}
          </>
        )}
      </section>
    </>
  )
}

export default MisTareas
