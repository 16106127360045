import React, { useEffect, useState } from 'react'
import './materialsCalculator.css'
import No from '../../Assets/Images/Login/no.svg'
import info from '../../Assets/Images/Product/info-circle-materials.svg'
import { Modal } from 'react-bootstrap'
import {
  calculateDoCalculator,
  calculateDoFormula,
  getByIdCalculator,
  getByIdProductTaxonomyCalculator,
} from 'redux/actions/materialsCalculatorActions'
import {
  ROUTES,
  inputFilterable,
  materialsCalculatorTypeIds,
} from 'Utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import LightTooltip from 'Components/LightTooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { ExclamationTriangleFill, X, Plus, Dash } from 'react-bootstrap-icons'
import { getPriceInventoryGeneral } from 'redux/actions/priceProductsAction'
import { priceListAddToCart } from 'redux/actions/cartActions'
import { addSalesAgentFullName } from 'redux/actions/cotizacionActions'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const MaterialsCalculator = ({
  showCalculator,
  setShowCalculator,
  isPLP,
  productDetail,
}) => {
  const dispatch = useDispatch()
  const changedRef = React.useRef(false)

  const [loading, setLoading] = useState(false)
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false)
  const [isDeleteOption, setIsDeleteOption] = useState(false)
  const [calculatorId, setCalculatorId] = useState(null)
  const [isModalErrors, setIsModalErrors] = useState({
    active: false,
    message: '',
  })
  const [isLoadingCalculateFormula, setIsLoadingCalculateFormula] = useState({
    active: false,
    id: '',
  })
  const [selectedOptions, setSelectedOptions] = useState({})
  const [sections, setSections] = useState([])
  const [dataSections, setDataSections] = useState([])
  const [productsAttributes, setProductsproductsAttributes] = useState([])
  const [responsesFormula, setResponsesFormula] = useState([])
  const [imgDescription, setImgDescription] = useState(null)
  const userName = useSelector(
    state => state?.login?.user?.CustomerInfo?.FullName
  )
  const { CompanyId } = useSelector(state => state.company)
  const companyId = useSelector(state => state.company.CompanyId)
  const companyCode = useSelector(state => state.company.CompanyCode)
  const warehouse = useSelector(state => state.company.WarehouseId)
  const warehouseCode = useSelector(state => state.company.WarehouseCode)

  const history = useHistory()

  const requestId = `vad-${companyCode}-${warehouseCode}-${getOS()}-${uuidv4()}`

  function getOS() {
    const { userAgent } = window.navigator
    return (
      ['Windows', 'MacOS', 'Linux', 'Android', 'iOS'].find(os =>
        userAgent.includes(os)
      ) || 'Unknown'
    )
  }

  const generateInitialState = sections => {
    const initialState = {}
    sections.forEach(section => {
      section.Inputs.forEach(input => {
        if (input.Required) {
          if (
            input.Name === 'paredesIgualesSuperficie' ||
            input.Name === 'paredesIgualesApertura'
          ) {
            initialState[`input-${input.Id}`] = 1
          } else {
            initialState[`input-${input.Id}`] = ''
          }
        }
      })
    })
    return initialState
  }

  const getPrices = async PriceInventoryPayload => {
    try {
      const response = await dispatch(
        getPriceInventoryGeneral(PriceInventoryPayload)
      )
      return response?.data?.Data
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPriceInventory = async ({ hits, CustomerId }) => {
    const PriceInventoryData = {
      CompanyId: companyId,
      CompanyCode: companyCode,
      WarehouseId: warehouse,
      ProductIds: hits.map(item => item?.ProductId),
      CustomerId: CustomerId || null,
      UoMId: null,
    }

    const priceData = await getPrices(PriceInventoryData)

    let productArrayWithPrice = []
    if (hits && hits.length > 0) {
      productArrayWithPrice = [...hits]
    }
    try {
      // eslint-disable-next-line array-callback-return
      const data = productArrayWithPrice
        .map(item => {
          const currentProduct = priceData.find(
            product =>
              product?.ProductId.toUpperCase() === item?.ProductId.toUpperCase()
          )
          if (!!currentProduct === true) {
            return {
              ...item,
              Brand: item.BrandName,
              Images: [{ URL: item.Image }],
              Description: item.Name,
              ProductName: item.Name,
              BasePrice: currentProduct.BasePrice,
              Cost: currentProduct.Cost || currentProduct.AvgCost,
              ProductId: currentProduct.ProductId,
              FinalPrice: currentProduct.FinalPrice,
              TotalQty: currentProduct.TotalQty,
              WarehouseQty: currentProduct.WarehouseQty,
              PriceListId: currentProduct.PriceListId,
            }
          } else {
            return null
          }
        })
        .filter(item => item !== null)
      return { data, priceData }
    } catch (error) {
      return console.error('Error al filtrar precios', error)
    }
  }
  const generateValidationSchema = sections => {
    const shape = {}

    sections.forEach(section => {
      section.Inputs.forEach(input => {
        const required = input.Required

        if (required) {
          shape[`input-${input.Id}`] = Yup.string().required(
            'Este campo es requerido'
          )
        }
      })
    })
    return Yup.object().shape(shape)
  }

  const [validationSchema, setValidationSchema] = useState(
    generateValidationSchema([])
  )
  const [initialValues, setInitialValues] = useState(generateInitialState([]))

  const generatePayload = selectedOptions => {
    const SelectedInputOptions = []
    const Inputs = []

    for (const key in selectedOptions) {
      // eslint-disable-next-line no-prototype-builtins
      if (selectedOptions.hasOwnProperty(key)) {
        const option = selectedOptions[key]

        if (!option.type) {
          const { id, name, value } = option
          SelectedInputOptions.push({
            id,
            name,
            value,
            IsForCondition: false,
          })
        } else {
          const { type, name, value } = option
          Inputs.push({
            type,
            name,
            value,
          })
        }
      }
    }

    const payload = {
      CalculatorId: calculatorId,
      SelectedInputOptions,
      Inputs,
      ...(productDetail?.ProductId
        ? { ProductId: productDetail.ProductId }
        : {}),
    }

    return payload
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,

    validate: values => {
      const errors = {}
      setIsModalErrors({
        active: true,
        message: 'Faltan campos por completar.',
      })
      return errors
    },
    onSubmit: async values => {
      setIsModalErrors({
        active: false,
        message: '',
      })
      setIsLoadingCalculate(true)

      const payload = generatePayload(selectedOptions)

      const response = await dispatch(
        calculateDoCalculator({ payload, requestId })
      )
      if (response?.length) {
        const { data: priceInventoryData, priceData: productPricesInfo } =
          await fetchPriceInventory({
            hits: response?.map(r => r?.Product),
          })

        const newProduct = response
          .map(res => {
            const currentProduct = priceInventoryData?.find(
              p =>
                p?.ProductId.toUpperCase() ===
                res.Product?.ProductId.toUpperCase()
            )

            const productPriceInfo = productPricesInfo?.find(
              p =>
                p?.ProductId.toUpperCase() ===
                res.Product?.ProductId.toUpperCase()
            )

            if (!res.Product || !currentProduct) {
              return null
            }

            return {
              ...res.Product,
              ...currentProduct,
              BasePrice: currentProduct.BasePrice,
              Cost:
                productPriceInfo?.Cost ||
                productPriceInfo?.AvgCost ||
                currentProduct.Cost ||
                0,
              Quantity: res.Qty,
              AllowFractionalQty: false,
            }
          })
          .filter(product => product !== null)

        const dataRes = await dispatch(priceListAddToCart(newProduct))

        dispatch(addSalesAgentFullName(userName))
        if (dataRes) {
          history.push(ROUTES.materialsPreOrder)
          return
        }
        if (!newProduct.length) setIsLoadingCalculate(false)
        setIsModalErrors({
          active: true,
          message: 'No se encontraron valores en los productos',
        })
        return
      } else {
        const extractMessage = message => {
          const parts = message.split(':')
          return parts.length > 1 ? parts[1].trim() : message
        }

        setIsModalErrors({
          active: true,
          message: extractMessage(response?.Status?.Message),
        })
      }

      setIsLoadingCalculate(false)
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await dispatch(
        isPLP
          ? getByIdCalculator({
              id: materialsCalculatorTypeIds.ceiling,
              requestId,
            })
          : getByIdProductTaxonomyCalculator({
              departmentId: productDetail.DepartmentId,
              categoryId: productDetail.CategoryId,
              subCategoryId: productDetail.SubCategoryId,
              productId: productDetail.ProductId,
              companyId: CompanyId,
              requestId,
            })
      )
      setLoading(false)

      if (data && data?.Sections) {
        setImgDescription(data?.Gallery[0]?.Url)
        setCalculatorId(data?.Id)
        const filteredInputs = data?.Sections.flatMap(section =>
          section.Inputs.filter(
            input =>
              !input.ParentsOptions ||
              input.ParentsOptions.trim() === '' ||
              input.ParentsOptions.split(',').every(
                option => option.trim() === ''
              )
          ).map(input => {
            const shouldBeUndefined = [
              'largoSuperficie',
              'paredesIgualesSuperficie',
              'botonCalculoSuperficie',
              'anchoApertura',
              'paredesIgualesApertura',
              'botonCalculoApertura',
            ].includes(input.Name)

            return {
              ...input,
              sectionId: section.Id,
              GroupInputs: section.GroupInputs,
              ...(shouldBeUndefined && {
                HelpMessage: undefined,
                Label: undefined,
              }),
            }
          })
        )

        const sectionsWithFilteredInputs = data?.Sections.map(section => ({
          ...section,
          Inputs: section.Inputs.filter(
            input =>
              !input.ParentsOptions ||
              input.ParentsOptions.trim() === '' ||
              input.ParentsOptions.split(',').every(
                option => option.trim() === ''
              )
          ),
        }))

        setDataSections(data?.Sections)
        setSections(filteredInputs)
        setValidationSchema(
          generateValidationSchema(sectionsWithFilteredInputs)
        )
        setInitialValues(generateInitialState(sectionsWithFilteredInputs))
      }
      if (data && data?.Products) {
        setProductsproductsAttributes(data.Products)
      }
    }
    fetchData()
  }, [dispatch])

  const buildProductAttributes = (products, selectedOptions) => {
    const selectedOptionsArray = Object.values(selectedOptions).filter(
      option => option?.section?.InputType[0].Code === 'DROPDOWN'
    )

    const productWithAttributes = new Map()

    // Crear el nuevo array basado en los atributos de los productos y las opciones seleccionadas
    productsAttributes.forEach(product => {
      return product.Attributes.forEach(attribute => {
        return selectedOptionsArray.forEach(option => {
          if (
            attribute.Globalidentifier === option.name &&
            attribute.Value === option.value
          ) {
            if (!productWithAttributes.has(product.ProductId)) {
              productWithAttributes.set(product.ProductId, {
                [attribute.Globalidentifier]: attribute.Value,
              })

              return
            }

            productWithAttributes.set(product.ProductId, {
              ...productWithAttributes.get(product.ProductId),
              [attribute.Globalidentifier]: attribute.Value,
            })
          }
        })
      })
    })

    productWithAttributes.forEach((val, k, ma) => {
      if (Object.keys(val).length < selectedOptionsArray.length) {
        ma.delete(k)
      }
    })

    return productsAttributes.filter(c =>
      productWithAttributes.has(c.ProductId)
    )
  }

  const getOptionsMapped = (options, preFilteredProducts) => {
    return options.map(option => {
      let isDisabled = false

      if (
        inputFilterable.includes(option.Name) &&
        option.InputType[0].Code === 'DROPDOWN'
      ) {
        const productAvailable = preFilteredProducts
          .map(product => ({
            ...product,
            Attributes: product.Attributes.filter(
              attributes =>
                attributes.Globalidentifier ===
                  option.option.Globalidentifier &&
                attributes.Value === option.option.Value
            ),
          }))
          .filter(products => products.Attributes.length > 0)

        isDisabled = productAvailable.length === 0
      }

      return {
        id: option.option.Id,
        label: option.option.Label,
        value: option.option.Value,
        disabled: isDisabled,
      }
    })
  }

  function filterDisabledOptions(validationsInputs, filterGetOptionsMapped) {
    // Obtener los IDs de filterGetOptionsMapped que tienen disabled true
    const disabledIds = filterGetOptionsMapped
      .filter(item => item.disabled) // Filtrar solo los items con disabled true
      .map(item => item.id)

    // Filtrar validationsInputs basado en los IDs con disabled true
    const filteredInputs = validationsInputs.map(input => {
      // Solo modificar Options si el input tiene Options
      if (input.Options && input.Options.length > 0) {
        // Filtrar las Options que NO coinciden con los IDs en disabledIds
        const filteredOptions = input.Options.filter(
          option => !disabledIds.includes(option.Id)
        )

        // Actualizar Options con los filtrados, pero solo si hay alguno filtrado
        if (filteredOptions.length > 0) {
          return { ...input, Options: filteredOptions }
        } else {
          return input // Mantener input sin cambios si no hay opciones filtradas
        }
      }
      return input // Mantener input sin cambios si no tiene Options
    })

    return filteredInputs
  }

  function reorderValidationInputs(validationInputs, selectedOptions) {
    // Busca si existe un objeto con name igual a "material" en selectedOptions
    const materialOption = Object.values(selectedOptions).some(
      option =>
        option.name === 'material' &&
        (option.value === 'Fibramineral' ||
          option.value === 'Fibrocemento' ||
          option.value === 'Yeso')
    )

    const index = validationInputs.findIndex(
      input => input.Id === '07418261-842c-444f-9741-5f09c1d0e5a4'
    )

    if (materialOption) {
      // Encuentra el índice del objeto con el Id especificado en validationInputs

      if (index !== -1) {
        // Saca el objeto del arreglo
        const [item] = validationInputs.splice(index, 1)
        // Inserta el objeto en la posición 2
        validationInputs.splice(2, 0, item)
      }
    } else {
      const [item] = validationInputs.splice(index, 1)
      // Inserta el objeto en la posición 2
      validationInputs.splice(3, 0, item)
    }

    return validationInputs
  }

  const handleCalculateDoFormulate = async sectionId => {
    // Filtrar las opciones seleccionadas para la sección dada

    setIsLoadingCalculateFormula({
      active: true,
      id: sectionId,
    })
    const filter = Object.values(selectedOptions).filter(
      select => select.section.sectionId === sectionId
    )

    // Extraer los CalculatorFormulaId correspondientes a la sección dada
    const ids = sections
      .filter(
        sec =>
          sec.sectionId === sectionId &&
          sec.InputType[0].Code === 'BUTTON_FORMULA'
      )
      .flatMap(button =>
        button?.CalculatorFormulaIds.map(id => id.CalculatorFormulaId)
      )

    // Arreglo para almacenar las respuestas agrupadas
    const groupedResponses = []

    // Ejecutar generatePayload y calculateDoFormula para cada id
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i]
      const payload = generatePayload(filter)
      const response = await dispatch(
        calculateDoFormula({ payload, CalculatorFormulaId: id })
      )

      // Si estamos en el primer id, añadimos un nuevo array para el grupo
      if (i % ids.length === 0) {
        groupedResponses.push({ sectionId, responses: [] })
      }

      // Añadir la respuesta al último grupo en groupedResponses
      groupedResponses[groupedResponses.length - 1].responses.push({
        id,
        response,
      })
    }

    setResponsesFormula(prevResponses => [
      ...prevResponses,
      ...groupedResponses,
    ])

    setIsLoadingCalculateFormula({
      active: false,
      id: sectionId,
    })
  }

  useEffect(() => {
    if (dataSections?.length) {
      const filteredInputs = dataSections.flatMap(section =>
        section.Inputs.filter(input => {
          const selectedIds = Object.keys(selectedOptions || {}).map(
            key => selectedOptions[key]?.id
          )

          return (
            !input.ParentsOptions ||
            input.ParentsOptions.trim() === '' ||
            selectedIds.some(id => input.ParentsOptions.split(',').includes(id))
          )
        }).map(input => {
          const shouldBeUndefined = [
            'largoSuperficie',
            'paredesIgualesSuperficie',
            'botonCalculoSuperficie',
            'anchoApertura',
            'paredesIgualesApertura',
            'botonCalculoApertura',
          ].includes(input.Name)

          return {
            ...input,
            sectionId: section.Id,
            GroupInputs: section.GroupInputs,
            ...(shouldBeUndefined && {
              HelpMessage: undefined,
              Label: undefined,
            }),
          }
        })
      )

      const allParentsOptionsNullOrEmpty = filteredInputs.every(input => {
        const selectedIds = Object.keys(selectedOptions || {}).map(
          key => selectedOptions[key]?.id
        )

        return (
          !input.ParentsOptions ||
          input.ParentsOptions.trim() === '' ||
          selectedIds.some(
            id =>
              input.ParentsInputs ===
              selectedOptions[`input-${id}`]?.section.ParentsInputs
          )
        )
      })

      const validationInputs = allParentsOptionsNullOrEmpty
        ? sections
        : filteredInputs

      const filterBuildProductAttributes = buildProductAttributes(
        productsAttributes,
        selectedOptions
      ) // Filtra los productos con los atributos que coinciden por el globalidentifier y value de las opciones seleccionadas

      const allOptions = []

      validationInputs.forEach(item => {
        if (
          item.Options &&
          Array.isArray(item.Options) &&
          item.InputType &&
          Array.isArray(item.InputType)
        ) {
          item.Options.forEach(option => {
            allOptions.push({
              InputType: item.InputType,
              option,
              Name: item.Name,
              Id: item.Id,
            })
          })
        }
      })

      const filterGetOptionsMapped = getOptionsMapped(
        allOptions,
        filterBuildProductAttributes,
        filteredInputs
      ) // Finalmente deshablita las opciones para cuadrícula y modelo

      // Llamamos a la función y mostramos el resultado
      const filterInputsDisabled = filterDisabledOptions(
        filteredInputs,
        filterGetOptionsMapped
      ) // Filtra las opciones finales que no están deshabilitadas

      const allOptionsFilter = []

      filterInputsDisabled.forEach(item => {
        if (
          item.Options &&
          Array.isArray(item.Options) &&
          item.InputType &&
          Array.isArray(item.InputType)
        ) {
          item.Options.forEach(option => {
            allOptionsFilter.push({
              InputType: item.InputType,
              option,
              Name: item.Name,
              Id: item.Id,
            })
          })
        }
      })

      const orderedValidationInputs = reorderValidationInputs(
        filterInputsDisabled,
        selectedOptions
      ) // Ordena la cuadrícula

      const sectionsWithFilteredInputs = dataSections.map(section => ({
        ...section,
        Inputs: validationInputs.filter(
          input => input.sectionId === section.Id
        ),
      })) // Agrega las secciones a los inputs

      setSections(orderedValidationInputs)
      setValidationSchema(generateValidationSchema(sectionsWithFilteredInputs))
      setInitialValues(generateInitialState(sectionsWithFilteredInputs))
      setIsDeleteOption(false)
    }
  }, [selectedOptions])

  const handleReset = () => {
    const filteredInputs = dataSections.flatMap(section =>
      section.Inputs.filter(
        input =>
          !input.ParentsOptions ||
          input.ParentsOptions.trim() === '' ||
          input.ParentsOptions.split(',').every(option => option.trim() === '')
      ).map(input => ({
        ...input,
        sectionId: section.Id,
        GroupInputs: section.GroupInputs,
        ...(input.Name === 'long' ||
          input.Name === 'altoSuperficie' ||
          input.Name === 'paredesIgualesSuperficie' ||
          input.Name === 'botonCalculoSuperficie' ||
          input.Name === 'paredesIgualesSuperficie' ||
          input.Name === 'anchoApertura' ||
          input.Name === 'paredesIgualesApertura' ||
          (input.Name === 'botonCalculoApertura' && {
            HelpMessage: undefined,
            Label: undefined,
          })),
      }))
    )

    const sectionsWithFilteredInputs = dataSections.map(section => ({
      ...section,
      Inputs: filteredInputs.filter(input => input.sectionId === section.Id),
    }))

    setSections(filteredInputs)
    setValidationSchema(generateValidationSchema(sectionsWithFilteredInputs))
    setInitialValues(generateInitialState(sectionsWithFilteredInputs))
    formik.setValues(initialValues)
    formik.setTouched({})
    formik.setErrors({})
    setResponsesFormula([])

    // Filtrar y mantener solo los valores de las llaves especificadas (valor por defecto de paredes y abertura)
    const preservedKeys = [
      'input-e3230645-a46d-44ee-9e6e-410d2e42d08b',
      'input-aaa35302-24f6-4f84-b6d0-49f34ea371d8',
    ]

    setSelectedOptions(prevSelectedOptions => {
      const newSelectedOptions = {}
      preservedKeys.forEach(key => {
        if (prevSelectedOptions[key] !== undefined) {
          newSelectedOptions[key] = prevSelectedOptions[key]
        }
      })
      return newSelectedOptions
    })
  }

  const handleInputChange = (
    e,
    input,
    section,
    sectionFixedIndex,
    isAutoFill = false
  ) => {
    const { name, value, type } = e.target
    const inputId = type === 'select-one' ? JSON.parse(value).Id : value
    const inputValue = type === 'select-one' ? JSON.parse(value).Value : value
    const filterName = section.Options.filter(
      inputName => inputName.Label === JSON.parse(value).Label
    )[0]

    const newSelectedOptions = {
      ...selectedOptions,
      [name]: {
        id: inputId,
        ...(input.InputType[0].Code === 'NUMERIC'
          ? { type: input.InputType[0].Code }
          : {}),
        name: filterName?.CalculatorAttributeId
          ? filterName?.Globalidentifier
          : input.Name,
        input,
        section,
        value: inputValue,
        fieldIndexes: sectionFixedIndex,
        autoFill: isAutoFill,
      },
    }

    formik.setFieldValue(`input-${section.Id}`, inputId)

    const withNoReset =
      section?.Name === 'altoSuperficie' ||
      section?.Name === 'largoSuperficie' ||
      section?.Name === 'anchoJunta' ||
      section?.Name === 'altoApertura'

    const keys = Object.keys(selectedOptions)
    sectionFixedIndex?.[0] === 1 &&
      !withNoReset &&
      keys.forEach(key => {
        const index = selectedOptions[key].fieldIndexes

        if (
          index?.[0] === sectionFixedIndex?.[0] &&
          index?.[1] > sectionFixedIndex?.[1] &&
          (value === '' || JSON.parse(value)?.Name !== 'medidas')
        ) {
          delete newSelectedOptions[key]
          formik.setFieldValue(key, '')
        }
      })

    setSelectedOptions(newSelectedOptions)
  }

  const handleRadioButtonChange = (input, section) => {
    if (
      input.Id === materialsCalculatorTypeIds.suspended ||
      input.Id === materialsCalculatorTypeIds.flat
    ) {
      formik.setValues(initialValues)
      formik.setTouched({})
      formik.setErrors({})
      setSelectedOptions(prev => ({
        [`input-${section.Id}`]: {
          id: input.Id,
          value: input.Value,
          name: input.Name,
        },
      }))
    } else {
      setSelectedOptions(prev => ({
        ...prev,
        [`input-${section.Id}`]: {
          id: input.Id,
          value: input.Value,
          name: section.Name,
          input,
          section,
        },
      }))
    }

    formik.setFieldValue(`input-${section.Id}`, input.Id)
  }

  const handleResetSelector = (section, sectionFixedIndex) => {
    // Resetea el valor del campo y su estado de "tocado" en Formik
    setIsDeleteOption(true)
    formik.setFieldValue(`input-${section.Id}`, '')
    formik.setTouched({
      [`input-${section.Id}`]: false,
    })
    formik.setValues({
      ...formik.values,
      [`input-${section.Id}`]: '',
    })

    const withNoReset =
      section?.Name === 'altoSuperficie' ||
      section?.Name === 'largoSuperficie' ||
      section?.Name === 'anchoJunta' ||
      section?.Name === 'altoApertura'

    const keys = Object.keys(selectedOptions)
    !withNoReset &&
      keys.forEach(key => {
        const index = selectedOptions[key].fieldIndexes

        if (
          (index?.[0] === sectionFixedIndex?.[0] &&
            index?.[1] > sectionFixedIndex?.[1]) ||
          selectedOptions[key]?.input?.Options?.length === 1
        ) {
          delete selectedOptions[key]
          formik.setFieldValue(key, '')
        }
      })

    setSelectedOptions(prev => {
      const newSelectedOptions = { ...prev }
      delete newSelectedOptions[`input-${section.Id}`]

      // Elimina también las claves cuyo selectedOptions[key].Options.length sea igual a 1
      Object.keys(newSelectedOptions).forEach(key => {
        if (newSelectedOptions[key]?.Options?.length === 1) {
          delete newSelectedOptions[key]
        }
      })

      return newSelectedOptions
    })
    changedRef.current = false
  }

  const fieldRequerided = () => {
    return (
      <Modal
        show={isModalErrors.active}
        backdrop={'static'}
        style={{ zIndex: 99999 }}
        className="modal-clase-price-change"
        centered>
        <div className="modal-price-change-allow">
          <span>
            <img
              src={No}
              onClick={() =>
                setIsModalErrors({
                  active: false,
                  message: '',
                })
              }
              alt="close"
            />
          </span>
          <div className="d-flex justify-content-center">
            <ExclamationTriangleFill className="check-costs-page-warning-icon" />
          </div>
          <div className="alert-cart-text">
            <p style={{ fontSize: 18 }}>{isModalErrors.message}</p>
          </div>
        </div>
      </Modal>
    )
  }

  useEffect(() => {
    const handleWheel = event => {
      if (event.target.type === 'number') {
        event.preventDefault()
      }
    }

    document.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      document.removeEventListener('wheel', handleWheel)
    }
  }, [])

  const validationInputType = (section, type) => {
    return section.InputType[0].Code === type
  }

  const filteredOptions = (section, sectionFixedIndex, e) => {
    const input = section.Options?.length === 1 ? section.Options[0] : null

    const newInput = {
      ...input,
      sectionId: section.Id,
      GroupInputs: section.GroupInputs,
    }

    const selectedArray = Object.values(selectedOptions)
    const search = newInput?.sectionId
      ? selectedArray.find(
          option => option?.section?.Id === newInput?.sectionId
        )
      : null

    if (input && !search && !isDeleteOption) {
      const event = {
        target: {
          name: `input-${section.Id}`,
          value: JSON.stringify(input),
          type: 'select-one',
          disabled: true,
        },
      }
      const isAutoFill = true
      handleInputChange(event, section, section, sectionFixedIndex, isAutoFill)
    }
    return section.Options.filter(
      sec => sec.Id !== 'A3DBAFE3-B932-4402-B22D-392206E500B9'
    )
  }

  useEffect(() => {
    // Inicializar los valores en Formik
    sections.forEach(input => {
      if (input.Required) {
        if (
          input.Name === 'paredesIgualesSuperficie' ||
          input.Name === 'paredesIgualesApertura'
        ) {
          const currentValue = formik.values[`input-${input.Id}`]

          // Solo establece el valor si no tiene uno
          if (currentValue === undefined || currentValue === null) {
            formik.setFieldValue(`input-${input.Id}`, 1)
            setSelectedOptions(prev => ({
              ...prev,
              [`input-${input.Id}`]: {
                id: input.Id,
                value: 1,
                name: input.Name,
                type: 'NUMERIC',
                input,
                section: input,
              },
            }))
          }
        }
      }
    })
  }, [sections])

  const handleIncrease = input => {
    const currentValue = formik.values[`input-${input.Id}`]

    const counter = parseInt(currentValue) + 1
    formik.setFieldValue(`input-${input.Id}`, counter)
    setSelectedOptions(prev => ({
      ...prev,
      [`input-${input.Id}`]: {
        id: input.Id,
        value: counter,
        name: input.Name,
        type: 'NUMERIC',
        input,
        section: input,
      },
    }))
  }

  const onChangeInputCounter = (input, value) => {
    if (value === '') {
      formik.setFieldValue(`input-${input.Id}`, '')
      setSelectedOptions(prev => ({
        ...prev,
        [`input-${input.Id}`]: {
          id: input.Id,
          value: '',
          name: input.Name,
          type: 'NUMERIC',
          input,
          section: input,
        },
      }))

      return
    }
    const newValue = value < 1 ? 1 : parseInt(value)

    const newSelectedOptions = {
      ...selectedOptions,
      [`input-${input.Id}`]: {
        id: input.Id,
        value: newValue,
        name: input.Name,
        type: 'NUMERIC',
        input,
        section: input,
      },
    }

    formik.setFieldValue(`input-${input.Id}`, newValue)

    setSelectedOptions(newSelectedOptions)
  }

  const handleDecrease = input => {
    const currentValue = formik.values[`input-${input.Id}`]

    const counter = parseInt(currentValue) - 1
    if (currentValue > 1) {
      formik.setFieldValue(`input-${input.Id}`, counter)

      setSelectedOptions(prev => ({
        ...prev,
        [`input-${input.Id}`]: {
          id: input.Id,
          value: counter,
          name: input.Name,
          type: 'NUMERIC',
          input,
          section: input,
        },
      }))
    }
  }
  const handleDisabledButtonFormula = sectionId => {
    const formikValues = formik.values
    const filterSections = sections.filter(
      sec =>
        sec.sectionId === sectionId &&
        !validationInputType(sec, 'BUTTON_FORMULA')
    )

    // Crea un conjunto de los IDs de filterSections para una búsqueda rápida
    const sectionIds = new Set(filterSections.map(sec => sec.Id))

    // Obtiene las claves de formik.values
    const formikKeys = Object.keys(formikValues)

    // Verifica si todos los IDs buscados están presentes en formik.values
    const allIdsPresent = [...sectionIds].every(id =>
      formikKeys.includes(`input-${id}`)
    )

    const allValuesNotEmpty = [...sectionIds].every(
      id => formikValues[`input-${id}`] !== ''
    )

    return !(allIdsPresent && allValuesNotEmpty)
  }

  const handleFormatNumber = e => {
    const cursorPosition = e.target.selectionStart
    const value = e.target.value.replace(/[^0-9.]/g, '')
    if (value !== e.target.value) {
      e.target.value = value
      e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1)
    } else {
      e.target.setSelectionRange(cursorPosition, cursorPosition)
    }
  }

  let globalSectionIndex = 0

  return (
    <div className={isPLP ? 'container-main padding' : 'container-main'}>
      <span
        className={
          isPLP
            ? 'icon-close margin-icon-close-plp'
            : 'icon-close margin-icon-close-pdp'
        }
        onClick={() => {
          setShowCalculator(false)
        }}>
        <img src={No} alt="close" />
      </span>

      {loading ? (
        <div className="container-spinner">
          <CircularProgress
            size="5rem"
            sx={{
              '& .MuiCircularProgress-svg': { color: '#003082' },
            }}
          />
        </div>
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className={isPLP ? 'container-main-modalcalculator' : ''}>
          {isPLP && (
            <div className="descriptive-container border-line">
              <h2 className="title">Cielo raso</h2>
              <div className="image-description">
                <img src={imgDescription} />
              </div>
            </div>
          )}
          <div
            className={
              isPLP
                ? 'calculation-container border-line'
                : 'calculation-container'
            }>
            <h2 className="title">Cálculo de materiales</h2>
            <p className="text-description">
              Este cálculo es aproximado. El definitivo debe ser realizado por
              un profesional habilitado
            </p>

            {sections?.length &&
              Object.entries(
                sections
                  .sort((a, b) => a.Ordered - b.Ordered)
                  .reduce((acc, section) => {
                    if (section.GroupInputs) {
                      if (!acc[section.sectionId]) {
                        acc[section.sectionId] = []
                      }
                      acc[section.sectionId].push(section)
                    } else if (section.Name !== 'long') {
                      acc[section.Id] = [section]
                    }
                    return acc
                  }, {})
              ).map(([sectionId, groupedSections]) => (
                <div className="container-inputs" key={sectionId}>
                  {groupedSections.length > 0 && (
                    <div style={{ display: 'flex', marginBottom: 10 }}>
                      <p style={{ marginTop: 5, marginBottom: 5 }}>
                        {groupedSections[0].Label}
                      </p>
                      {groupedSections[0].HelpMessage && (
                        <LightTooltip
                          title={groupedSections[0].HelpMessage.replace(
                            /<\/?(p|strong)>/g,
                            ''
                          )}
                          placement="right">
                          <img
                            style={{ marginLeft: 5 }}
                            src={info}
                            alt="info-circle"
                          />
                        </LightTooltip>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      groupedSections[0].GroupInputs
                        ? 'container-inputs-formula'
                        : ''
                    }
                    style={{
                      display: groupedSections[0].GroupInputs
                        ? 'flex'
                        : 'block',
                    }}>
                    {groupedSections.map(section => {
                      const sectionFixedIndex = [1, globalSectionIndex]
                      globalSectionIndex++ // Incrementar el índice global

                      return (
                        <div
                          key={section.Id}
                          style={{
                            display: section.GroupInputs ? 'flex' : 'block',
                          }}>
                          {validationInputType(section, 'DROPDOWN') ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <div className="calculator-selector-wrapper">
                                {selectedOptions[`input-${section.Id}`] &&
                                  !selectedOptions[`input-${section.Id}`]
                                    ?.autoFill && (
                                    <button
                                      type="button"
                                      className="reset-selector-button"
                                      onClick={() =>
                                        handleResetSelector(
                                          section,
                                          sectionFixedIndex
                                        )
                                      }>
                                      <X />
                                    </button>
                                  )}
                                <select
                                  className="select-calculator"
                                  name={`input-${section.Id}`}
                                  onChange={e => {
                                    handleInputChange(
                                      e,
                                      section,
                                      section,
                                      sectionFixedIndex
                                    )
                                    formik.handleChange(e)
                                  }}
                                  value={formik.values[`input-${section.Id}`]}
                                  disabled={
                                    filteredOptions(section).length === 1 ||
                                    selectedOptions[`input-${section.Id}`]
                                  }
                                  defaultValue="">
                                  {filteredOptions(section).length > 1 && (
                                    <option value="" disabled>
                                      Seleccione
                                    </option>
                                  )}
                                  {filteredOptions(
                                    section,
                                    sectionFixedIndex
                                  ).map((input, inputIndex) => (
                                    <option
                                      key={input.Id}
                                      value={JSON.stringify(input)}>
                                      {input.Label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            <div key={`section-${globalSectionIndex}`}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <div
                                  style={{
                                    display: !validationInputType(
                                      section,
                                      'NUMERIC'
                                    )
                                      ? 'flex'
                                      : 'block',
                                    flexWrap: validationInputType(
                                      section,
                                      'COLORPALLETE'
                                    )
                                      ? 'wrap'
                                      : 'nowrap',
                                  }}>
                                  {section?.Options.length ? (
                                    section.Options.map((input, inputIndex) => (
                                      <div
                                        key={`${globalSectionIndex}-${inputIndex}`}
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                          display: 'flex',
                                          marginRight: validationInputType(
                                            section,
                                            'RADIOBUTTON'
                                          )
                                            ? 70
                                            : 10,
                                        }}>
                                        {validationInputType(
                                          section,
                                          'RADIOBUTTON'
                                        ) ? (
                                          <>
                                            <input
                                              style={{ marginRight: 5 }}
                                              type="radio"
                                              name={`input-${section.Id}`} // Same name for all radios in the section
                                              onClick={() =>
                                                handleRadioButtonChange(
                                                  input,
                                                  section
                                                )
                                              }
                                              checked={
                                                selectedOptions[
                                                  `input-${section.Id}`
                                                ]?.id === input?.Id
                                              }
                                              onChange={formik.handleChange}
                                              value={input.Id}
                                            />
                                            <p>{input.Label}</p>
                                          </>
                                        ) : validationInputType(
                                            section,
                                            'COLORPALLETE'
                                          ) ? (
                                          <input
                                            style={{
                                              backgroundColor: input.Label,
                                            }}
                                            className={`container-palette ${
                                              selectedOptions[
                                                `input-${section.Id}`
                                              ] &&
                                              selectedOptions[
                                                `input-${section.Id}`
                                              ]?.input.Id === input.Id
                                                ? 'border-selected'
                                                : 'border-normal'
                                            }`}
                                            type="radio"
                                            name={`input-${section.Id}`} // Same name for all radios in the section
                                            onClick={() =>
                                              handleRadioButtonChange(
                                                input,
                                                section
                                              )
                                            }
                                            onChange={formik.handleChange}
                                            value={input.Id}
                                          />
                                        ) : null}
                                      </div>
                                    ))
                                  ) : validationInputType(section, 'NUMERIC') &&
                                    section.Name !== 'width' &&
                                    section.Name !== 'long' ? (
                                    <div className="calculator-selector-wrapper">
                                      {(section.Name ===
                                        'paredesIgualesSuperficie' ||
                                        section.Name ===
                                          'paredesIgualesApertura') && (
                                        <div className="container-counter-buttons">
                                          <Plus
                                            style={{
                                              border: '1px solid #F8F9FD',
                                              borderRadius: '4px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                              handleIncrease(section)
                                            }
                                          />
                                          <Dash
                                            style={{
                                              border: '1px solid #F8F9FD',
                                              borderRadius: '4px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                              handleDecrease(section)
                                            }
                                          />
                                        </div>
                                      )}
                                      <div
                                        style={{ display: 'flex' }}
                                        key={`section-${globalSectionIndex}`}>
                                        <input
                                          className="form-control"
                                          style={{ marginRight: 5 }}
                                          type="text"
                                          step="any"
                                          pattern="[0-9]*[.]?[0-9]+"
                                          inputMode="decimal"
                                          name={`input-${section.Id}`}
                                          placeholder={t(
                                            section.GroupInputs
                                              ? section.Placeholder
                                              : ''
                                          )}
                                          onInput={e => {
                                            const value = e.target.value
                                            handleFormatNumber(e)
                                            section.Name ===
                                              'paredesIgualesSuperficie' ||
                                            section.Name ===
                                              'paredesIgualesApertura'
                                              ? onChangeInputCounter(
                                                  section,
                                                  value
                                                )
                                              : handleInputChange(
                                                  e,
                                                  section,
                                                  section,
                                                  sectionFixedIndex
                                                )
                                          }}
                                          value={
                                            formik.values[`input-${section.Id}`]
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : validationInputType(
                                      section,
                                      'BUTTON_FORMULA'
                                    ) ? (
                                    <button
                                      type="button"
                                      className="calculator-buttons-formula"
                                      disabled={handleDisabledButtonFormula(
                                        section.sectionId
                                      )}
                                      onClick={() =>
                                        handleCalculateDoFormulate(
                                          section.sectionId
                                        )
                                      }>
                                      {isLoadingCalculateFormula.active &&
                                      isLoadingCalculateFormula.id ===
                                        section.sectionId ? (
                                        <FontAwesomeIcon
                                          icon={faSpinner}
                                          className="spinner"
                                          style={{
                                            height: 25,
                                            width: 35,
                                            padding: 4,
                                          }}
                                        />
                                      ) : section.Name ===
                                        'botonCalculoSuperficie' ? (
                                        'Calcular pared'
                                      ) : (
                                        'Calcular abertura'
                                      )}
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                  <div className="container-results-formula">
                    {responsesFormula?.length > 0 &&
                      responsesFormula.map(
                        (resp, index) =>
                          resp.sectionId === sectionId && (
                            <div
                              key={index}
                              className="container-calculation-formula">
                              {resp.responses.map((res, resIndex) => (
                                <p key={resIndex}>
                                  {res.response.ResultName}:{' '}
                                  <span>
                                    {res.response.Qty}
                                    {res.response?.ResultSufix?.toUpperCase()}
                                  </span>
                                </p>
                              ))}
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))}

            <div className="container-measures">
              {sections?.length &&
                sections
                  .filter(
                    section =>
                      (!section.Options?.length && section.Name === 'width') ||
                      section.Name === 'long'
                  )
                  .flatMap((section, sectionIndex) => {
                    const sectionFixedIndex = [3, sectionIndex]
                    return (
                      <div
                        className="container-inputs"
                        style={{
                          justifyContent: section.Label
                            ? 'space-between'
                            : 'flex-end',
                        }}
                        key={section.Id}>
                        <div key={`section-${sectionIndex}`}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <input
                              className="form-control"
                              style={{ marginRight: 5 }}
                              type="text"
                              name={`input-${section.Id}`}
                              step="any"
                              pattern="[0-9]*[.]?[0-9]+"
                              inputMode="decimal"
                              onInput={e => {
                                handleFormatNumber(e)
                                handleInputChange(
                                  e,
                                  section,
                                  section,
                                  sectionFixedIndex
                                )
                                formik.handleChange(e)
                              }}
                              value={formik.values[`input-${section.Id}`]}
                              placeholder={t(
                                `MODAL_CALCULATOR_T.${section.Name}`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
            </div>
            <div className="calculator-buttons">
              <button
                type="button"
                disabled={isLoadingCalculate}
                className="calculator-buttons-clear"
                onClick={handleReset}>
                Limpiar todo
              </button>
              <button
                type="submit"
                className="calculator-buttons-calculate"
                disabled={isLoadingCalculate}>
                {isLoadingCalculate ? (
                  <FontAwesomeIcon icon={faSpinner} className="spinner" />
                ) : (
                  'Calcular proyecto'
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {fieldRequerided()}
    </div>
  )
}

export default MaterialsCalculator
