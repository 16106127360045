import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { sizeOfMarginLeft } from './Square1'
import { FormatMoney } from 'Utils/functions'
import { formatEndDate } from 'Utils/Helpers/formatDate'

const defTermValue = date =>
  `Promoción válida hasta el ${formatEndDate(
    date
  )}. La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF). *Valor cuota referencial, aplican condiciones. La responsabilidad de CMF se limita a los aspectos del crédito. Accede a www.cmf.com.pa para más información.`

export const Square1ToPrintNCmfOffer = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms1 =
    terms?.find(term => term?.prodId === prod?.ProductId)?.value ||
    defTermValue(prod?.FinishDate)
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        data-testid="printer-preview-left-canvas-1"
        className="printer-preview-left-canvas">
        <div className="printer-preview-left-content-body">
          <p
            className="printer-preview-left-content-body-title"
            style={{ lineHeight: '30px' }}>
            {prod?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-sku">
            {prod?.Sku || ''}
          </p>
          {prod?.Price && (
            <div
              className="printer-preview-left-content-body-price-wrapper"
              style={{
                marginLeft:
                  sizeOfMarginLeft[
                    prod?.Price?.toString()?.split('.')?.[0]?.length
                  ],
              }}>
              <p className="printer-preview-mini-text">$</p>
              <p
                className="printer-preview-left-content-body-price"
                style={{
                  fontSize: '114px',
                }}>
                {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text">
                {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod?.BasePrice && (
            <div
              style={{
                position: 'absolute',
                bottom: '-22px',
                right: 0,
                width: '130px',
                height: '1px',
                background: 'red',
                transform: 'rotate(-6deg)',
              }}
            />
          )}
          {prod?.BasePrice && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: '4px',
                position: 'absolute',
                bottom: '-34px',
                right: '0',
              }}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                  color: '#000',
                }}>
                Antes
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    marginTop: '-12px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '32px', marginTop: 0 }}>
                  {FormatMoney(
                    prod?.BasePrice?.toString()?.split('.')?.[0] || 0
                  ) || ''}
                  .
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    marginTop: '-12px',
                  }}>
                  {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer"
          style={{ bottom: -2 }}>
          <div
            className="printer-preview-left-content-footer-quotes"
            style={{ opacity: prod?.cmfCredit ? 1 : 0 }}>
            <p></p>
            <p className="printer-preview-left-content-footer-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-quote-text">
              ${FormatMoney(prod?.cmfCredit || 0) || ''}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: 'none',
                fontSize: '9px',
                background: 'transparent',
                height: '40.5px',
                lineHeight: '10px',
              }}>
              {terms1}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.cmfOffer1}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
