import React, { useState, useEffect } from 'react'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import cmfArrow from '../../Assets/Images/Product/cmf_arrow.svg'
import '../Product/product.css'
import './listProduct.css'
import { useDispatch } from 'react-redux'
import {
  priceListAddToCart,
  removeFromCart,
} from '../../redux/actions/cartActions.js'
import { FormatMoney, toTitleCase } from '../../Utils/functions'
import { Col } from 'react-bootstrap'

function ListProduct({ product, checkedState, setCheckedState, position }) {
  const [showMessage] = useState(false)
  const [name, setName] = useState('')
  const dispatch = useDispatch()

  const handleOnChange = e => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )

    setCheckedState(updatedCheckedState)
    if (e.target.checked) {
      dispatch(priceListAddToCart([product]))
    } else {
      dispatch(removeFromCart({ product }))
    }
  }

  useEffect(() => {
    setName(toTitleCase(product?.Brand))
  }, [product])

  return (
    <>
      <Col
        style={{
          marginBottom: '20px',
        }}
        // key={position}
      >
        <div className="product-card">
          <div>
            <input
              type="checkbox"
              name="check-product"
              className="product-list-checkbox"
              checked={checkedState[position]}
              onChange={handleOnChange}
              disabled={!product.FinalPrice > 0}
            />
          </div>
          <div className="product-card-image" id="product-card-image">
            <img
              src={(product.Images && product.Images[0].URL) || NoImage}
              alt={product.ProductName}
              className="img-fluid"
            />
          </div>
          <div className="product-card-generals">
            <h2 className="product-card-generals-title">
              {product.ProductName}
            </h2>
            <p className="product-card-brand">{name || 'Desconocido'}</p>
            <p className="product-card-sku">{product.Sku}</p>
            <div className="product-card-price">
              <p>
                {product.FinalPrice === undefined
                  ? 'Sin precio'
                  : '$' + FormatMoney(product.FinalPrice, false, 2, true)}
              </p>
              {(product.PriceListId ===
                '39FC82DF-2B58-4DD1-BE9C-F50A317D7266' ||
                product.PriceListId ===
                  'FDCA4798-57A8-42FC-A778-1E6471153A0B') && (
                <img src={cmfArrow} alt="cmf-arrow" className="img-fluid" />
              )}
            </div>
            {product.BasePrice - product.FinalPrice > 0 && (
              <div className="product-card-discount-container">
                <p className="product-card-discount">
                  {'Precio regular $' + product.BasePrice.toFixed(2)}
                </p>
              </div>
            )}
            <div></div>
          </div>
          <div>
            {showMessage ? (
              <p className="product-no-price">
                No se puede agregar, no cuenta con precio
              </p>
            ) : (
              ''
            )}
          </div>
          <div
            className="product-promo"
            style={{ display: product.Promo ? 'block' : 'none' }}>
            <p>{product.Promo || 'No Promo'}</p>
          </div>
          {product.BasePrice - product.FinalPrice > 0 && (
            <div className="product-promo">Oferta</div>
          )}
        </div>
      </Col>
    </>
  )
}

export default ListProduct
