import React, { useState, useEffect, useContext, useMemo } from 'react'
import QRCode from 'qrcode'
import No from '../../Assets/Images/Login/no.svg'
import CheckIcon from '../../Assets/Images/Login/check.png'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSquarePen } from '@fortawesome/free-solid-svg-icons'
import { useGetCotizacionPrice } from 'Utils/Helpers/calculateAmount'

import {
  Envelope,
  Check,
  X,
  BoxArrowUp,
  CurrencyDollar,
  Whatsapp,
} from 'react-bootstrap-icons'
import Navigation from '../../Components/Navigation'
import CircularProgress from '@mui/material/CircularProgress'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import './editarCotizacion.css'
import Modal from '@mui/material/Modal'
import MetodoEntrega from '../../Components/MetodoEntrega'
import Box from '@mui/material/Box'
import CrearCliente from '../../Views/Clientes/CrearCliente'
import { VAContext } from '../../Context/VAContext'
import RouterPrompt from '../../Components/RouterPrompt'
import {
  cotizacionUpdate,
  guardarCotizacion,
  eliminarProducto,
  modificarClienteContado,
  cotizacionPriceChangeApproval,
  mostrarDescuento,
  searchSalesDocumentDetail,
  sendEmailCotizacion,
} from '../../redux/actions/cotizacionActions.js'
import {
  downloadPdfFuntion,
  hasChangePriceApprove,
  updateHiddenColumns,
  isGerentsStore,
  FormatMoney,
} from '../../Utils/functions'
import * as Yup from 'yup'
import { emptyCart } from '../../redux/actions/cartActions.js'
import { useFormik } from 'formik'
import ChangeSalesman from '../../Components/ChangeSalesman'
import FilteringTable from '../../Components/FilteringTable'
import LightTooltip from '../../Components/LightTooltip'
import ConfirmStartSalesOrder from '../../Components/SalesOrder/ConfirmStartSalesOrder'
import GenerateSalesOrder from '../../Components/SalesOrder/GenerateSalesOrder'
import CurrencyInput from 'react-currency-input-field'
import MiniLoading from 'Components/MiniLoading'
import { creditAccountsAction } from 'redux/actions/catalogActions'
import { Row } from 'react-bootstrap'
import { useFreeTaxes } from 'state/context/CotizationTaxesContext'
import {
  ROUTES,
  docHeaderStatusDescription,
  docHeaderStatusId,
  documentTypeId,
  fullfilmentIds,
  VAPermissions,
} from 'Utils/constants'
import { t } from 'i18next'
import GenericModal from 'Components/GenericModal'
import RelationalDocLabel from 'Components/RelationalDocLabel'
import MarginText from 'Components/MarginText/MarginText'
import { useCheckFinalPrice } from 'Components/ActionTable/hooks/useCheckFinalPrice'
import Loading from 'Components/Loading'
import useValidatePermission from 'Hooks/useValidatePermission'
import {
  GetApprovalSalesOrder,
  getOdcDocument,
  searchSalesDocumentDetailService,
} from 'redux/services/cotizacionServices'
import { DownloadButton } from './components/DownloadButton'
import { useFetchDocumentODV } from 'Hooks/useUpdateDocumentOdv'
function TopButtons({
  pdf,
  sendEmail,
  needToBeAuth,
  handlePriceChange,
  lines,
  hasProducts,
}) {
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const wareHouseID = useSelector(
    state => state.login?.user?.CustomerInfo.Warehouses
  )

  const quotationWarehouseId = useSelector(
    state => state.cotizacion.QuoteHeader.WarehouseId
  )

  const isGerentsStoreUsers = isGerentsStore(loginInfo.UserRoles)
  const aproveDiscount = useValidatePermission(VAPermissions.aproveDiscount)
  const isWareHouse = wareHouseID.some(
    w => w.WarehouseId === quotationWarehouseId
  )

  const isSuperSuperUser =
    aproveDiscount && !isGerentsStoreUsers
      ? true
      : aproveDiscount && isWareHouse

  return (
    <div className="top-action-table-buttons">
      <button
        style={{
          backgroundColor:
            needToBeAuth && isSuperSuperUser && hasProducts
              ? '#FF2A40'
              : 'gray',
          color: needToBeAuth && isSuperSuperUser ? '#FFFFFF' : 'lightgray',
        }}
        disabled={
          isCustomerUser || !(needToBeAuth && isSuperSuperUser) || !hasProducts
        }
        onClick={() => handlePriceChange()}>
        <CurrencyDollar />
      </button>
      <DownloadButton disabled={isCustomerUser} download={pdf} />
      <button
        onClick={sendEmail}
        disabled={isCustomerUser}
        style={{
          backgroundColor: '#12C1F8',
          color: '#FFFFFF',
        }}>
        <Envelope />
      </button>
      <button
        style={{
          backgroundColor: '#4ECC5C',
          color: '#FFFFFF',
        }}
        disabled={isCustomerUser}
        onClick={() => {
          pdf(true)
        }}>
        <Whatsapp />
      </button>
    </div>
  )
}

function SubtotalNumbers() {
  const useSelectorDetalle = useSelector(state => state.cotizacion.QuoteHeader)
  const { getCotizacionPrices } = useGetCotizacionPrice()
  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )
  const { freeTaxes } = useFreeTaxes()

  useEffect(() => {
    getCotizacionPrices({ quote: useSelectorDetalle, freeTaxes })
  }, [freeTaxes])

  return (
    <div className="money-data-totals">
      <p>
        $
        {showDiscountOption
          ? FormatMoney(
              useSelectorDetalle.SubTotal + useSelectorDetalle.DiscountAmount,
              true,
              2,
              true
            )
          : FormatMoney(useSelectorDetalle?.Amount, true, 2, true)}
      </p>
      {showDiscountOption && (
        <p>${FormatMoney(useSelectorDetalle.DiscountAmount, false, 2, true)}</p>
      )}
      {!freeTaxes && (
        <p>${FormatMoney(useSelectorDetalle?.Tax, true, 2, true)}</p>
      )}
      {freeTaxes && showDiscountOption ? (
        <p>${FormatMoney(useSelectorDetalle?.SubTotal, true, 2, true)} </p>
      ) : freeTaxes && !showDiscountOption ? (
        <p>${FormatMoney(useSelectorDetalle?.SubTotal, true, 2, true)} </p>
      ) : (
        <p>
          $
          {FormatMoney(
            Number(
              FormatMoney(useSelectorDetalle?.Amount, true, 2).replace(/,/g, '')
            ) +
              Number(
                FormatMoney(useSelectorDetalle?.Tax, true, 2).replace(/,/g, '')
              ),
            true,
            2,
            true
          )}
        </p>
      )}
    </div>
  )
}

function SubtotalText() {
  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )
  const { freeTaxes } = useFreeTaxes()
  return (
    <div className="money-data-text">
      <p>Sub Total</p>
      {showDiscountOption ? <p>Descuento</p> : null}
      {!freeTaxes && <p style={{ color: 'black' }}>ITBMS</p>}
      <p>Total</p>
    </div>
  )
}

function EditarCotizacion(props) {
  const isCustomer = useSelector(state => state.login.isCustomer)
  const { getCotizacionPrices } = useGetCotizacionPrice()
  const { checkFinalPrice } = useCheckFinalPrice()

  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const coti = useSelector(state => state?.cotizacion?.QuoteHeader)

  const userId = useSelector(
    state => state?.login?.user?.CustomerInfo?.CustomerId
  )
  const userName = useSelector(
    state => state?.login?.user?.CustomerInfo?.FullName
  )
  const { fetchDocumentOdv } = useFetchDocumentODV()
  const [lines, setLines] = useState(null)
  const [fulfillmentPlanId, setFulfillmentPlanId] = useState(null)
  const cotizacionFinal = useSelector(state => state?.cotizacion)
  const [cotizacionInicial, setCotizacionInicial] = useState({
    DocumentTypeId: cotizacionFinal.DocumentTypeId,
    QuoteHeader: {
      QuoteHeaderId: cotizacionFinal?.DocHeaderId,
      ...cotizacionFinal,
    },
  })
  const [activeButton, setActiveButton] = useState(false)

  const hasOdv = cotizacionFinal?.QuoteHeader?.RelationDoc?.find(doc =>
    doc.DocumentNumber.includes('ODV')
  )
  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )
  const [loading, setLoading] = useState(false)
  const [showFailMessageModal, setShowFailMessageModal] = useState(false)

  const userInfo = useSelector(state => state.login.user)

  const [showHasChangesModal, setShowHasChangesModal] = useState(false)
  const [isEditLoading, setIsEditLoading] = useState(false)
  const [date, setDate] = useState(null)
  const { id, header } = useParams()
  const [cotizacion, setCotizacion] = useState(null)
  const [qrSource, setQrSource] = useState('')
  const [showModalEditClient, setShowModalEditClient] = useState(false)
  const [showModalEditDelivery, setShowModalEditDelivery] = useState(false)
  const [showModalEliminarProduct, setShowModalEliminarProduct] =
    useState(false)
  const [showMessageEmail, setMessageEmail] = useState(false)
  const [editClienteContado, setEditClienteContado] = useState(null)
  const [clienteTemp, setClienteTemp] = useState(null)
  const [, setDireccion] = useState(null) // Establece si hay direccion o hay retiro en sucursal
  const [customerId, setCustomerId] = useState(null) // Establece si hay direccion o hay retiro en sucursal
  const [isAuthorized, setIsAuthorized] = useState(null)
  const [address, setAddress] = useState(null)
  const [loadingSendEmail, setLoadingSendEmail] = useState(false)

  const [initialValues, setInitialValues] = useState({
    comment: '',
    taxExempt: false,
  })
  const [comment, setComment] = useState('')
  const [change, setChange] = useState(false)
  const [, setEmailLoading] = useState(false)
  const [hasButtons, setHasButtons] = useState(false)
  const [showModalEmail, setShowModalEmail] = useState(false)
  const [isDeletingProduct, setIsDeletingProduct] = useState(false)
  const [showModalConfirmStartSalesOrder, setShowModalConfirmStartSalesOrder] =
    useState(false)
  const [confirmStartSalesOrder, setConfirmStartSalesOrder] = useState(false)
  const [showModalGenerateSalesOrder, setShowModalGenerateSalesOrder] =
    useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [showModalPriceChangeApproval, setShowModalPriceChangeApproval] =
    useState(false)
  const [productsChanged, setProductsChanged] = useState([])
  const [email, setEmail] = useState('')

  const { selectClient } = useContext(VAContext)
  const [agent, setAgent] = useState({})

  const [isModal, setIsModal] = useState(false)
  const [needToBeAuth, setNeedToBeAuth] = useState(false)

  const [indexProductCurrent, setIndexProductCurrent] = useState(0)
  const [newPriceProductCurrent, setNewPriceProductCurrent] = useState(null)

  const [showModalApprovedPrice, setShowModalApprovedPrice] = useState(false)
  const [showModalRejectedPrice, setShowModalRejectedPrice] = useState(false)

  const [isCreate, setIsCreate] = useState(false)

  const isSaveButtonAvailable = cotizacionFinal?.QuoteHeader?.Lines?.length > 0

  const deleteProductAvaible = cotizacionFinal?.QuoteHeader?.Lines?.length === 1

  const { isRedirect } = useSelector(state => state.sellOrder)

  // columnas ocultas
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [hiddenColumnsInitial, setHiddenColumnsInitial] = useState([])
  const [updatedColumnsVisibility, setUpdatedColumnsVisibility] =
    useState(false)

  const [columns, setColumns] = useState([])

  const canChangeSeller = useValidatePermission(VAPermissions.changeSeller)

  const fullfilmentId = fullfilmentIds?.homeDelivery?.fulfillmentPlanId
  useEffect(() => {
    const defaultColumns = [
      {
        Header: 'Referencia',
        accessor: 'Referencia',
        Footer: <MarginText />,
        hideEnabled: true,
      },
      {
        Header: 'Descripción',
        accessor: 'Descripción',
      },
      {
        Header: 'Imagen',
        accessor: 'Imagen',
        hideEnabled: true,
      },
      {
        Header: 'Marca',
        accessor: 'Marca',
        hideEnabled: true,
      },
      {
        Header: 'País de Origen',
        accessor: 'País de Origen',
        hideEnabled: true,
      },
      {
        Header: 'Cantidad',
        accessor: 'Cantidad',
      },
      {
        Header: 'Precio Unitario',
        accessor: 'Precio Unitario',
      },
    ]
    defaultColumns.push({
      Header: 'Descuento',
      accessor: 'Descuento',
      hideEnabled: true,
    })
    defaultColumns.push({
      Header: 'Precio Total',
      accessor: 'Precio Total',
      Footer: <SubtotalText />,
    })

    defaultColumns.push({
      Header: 'Acciones',
      accessor: 'Acciones',
      Footer: <SubtotalNumbers />,
    })

    setColumns(defaultColumns)
  }, [])

  // BOTON GUARDAR
  const [open, setOpen] = useState(false)
  const [saveIsLoading, setSaveIsLoading] = useState(false)

  const closeAllModals = () => {
    setShowModalEditDelivery(false)
    setShowModalEditClient(false)
  }
  const location = window.location.href
  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const [visibleIcons, setVisibleIcons] = useState(false)

  const checkApprovalState = async DocHeaderId => {
    try {
      const data = await GetApprovalSalesOrder(DocHeaderId)
      setVisibleIcons(data?.data?.Data?.[0]?.Approval)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {}, [
    location,
    useSelectorQuoteObject?.QuoteHeader?.RelationDoc?.[0]?.DocHeaderId,
  ])

  const showButtons = () => {
    if (
      cotizacion?.DocHeaderStatusAction === 'OPEN' ||
      cotizacion?.DocHeaderStatusAction === 'DRAFT'
    ) {
      setHasButtons('block')
    } else {
      setHasButtons('none')
    }
  }

  const stateInitial = {
    email: '',
  }

  const isApproved = (lines, reason) => {
    // eslint-disable-next-line array-callback-return
    lines?.map(line => {
      if (reason && line.AuthorizedPriceBy !== null) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    })
  }

  const setTableData = data => {
    if (!data) return []
    const products = data.map(product => {
      return {
        ...product,
        Referencia: product?.SystemCode,
        Descripción: product?.ProductName,
        Imagen: product?.DefaultProductPicture || NoImage,
        Marca: product?.Brand,
        'País de Origen': 'Panamá',
        Cantidad: product?.Quantity,
        'Precio Unitario':
          !showDiscountOption || product?.Price > product?.BasePrice
            ? product?.BasePrice === null
              ? 0
              : product?.Price
            : product?.BasePrice,
        Descuento: product?.DiscountAmount,
        'Precio Total': product?.Price * product?.Quantity,
      }
    })
    return products
  }

  const productsData = useMemo(
    () => setTableData(coti?.Lines),
    [coti?.Lines, cotizacion, showDiscountOption]
  )

  useEffect(() => {
    setLines(coti?.Lines)
    setEmail(coti?.CustomerEmail || coti?.EmailAddress)
    isApproved(coti?.Lines, coti?.NegotiatedReasonId)
  }, [coti])

  const formik = useFormik({
    initialValues: stateInitial,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Por favor, introduzca un email válido')
        .nullable(),
    }),
    validate: values => {
      const errors = {}

      return errors
    },
    onSubmit: values => {
      sendEmail()
    },
  })

  const handleModal = () => {
    setShowModalEditClient(!showModalEditClient)
    clientToEdit(customerId)
  }

  const clientToEdit = customerId => {
    selectClient(customerId, false, true, true)
  }

  const handleDelivery = () => {
    setShowModalEditDelivery(!showModalEditDelivery)
  }

  const status = () => {
    if (
      cotizacion.DocHeaderStatusDescription !== docHeaderStatusDescription.open
    ) {
      return '#F897A1'
    }
  }

  const handleClienteContado = () => {
    setEditClienteContado(!editClienteContado)
  }

  const modifyCliente = () => {
    if (clienteTemp.length > 0) {
      setCotizacion({
        ...cotizacion,
        CustomerNameDocument: clienteTemp,
      })
      dispatch(modificarClienteContado(clienteTemp))
      setEditClienteContado(false)
    }
  }

  const openPriceChangeModal = () => {
    setIndexProductCurrent(0)
    setShowModalPriceChangeApproval(true)
  }
  const { freeTaxes, setFreeTaxes } = useFreeTaxes()

  const fetchCotizacion = async () => {
    setLoading(true)
    const payload = {
      DocumentTypeId: documentTypeId.QUOTATION,
      DocumentHeaderId: header,
      DocumentNumber: id,
      PageNumber: 1,
      PageSize: 1,
    }
    const { Data } = await dispatch(searchSalesDocumentDetail(payload))
    let allData = Data
    if (Data?.Origin) Data.Origin = 'VA'
    if (Data && Data?.CompanyName) {
      if (location.includes('editar_cotizacion')) {
        setFreeTaxes(!!Data?.TaxExempt)
        setInitialValues(prev => ({
          ...prev,
          taxExempt: !!Data?.TaxExempt,
        }))
        const odvDocHeaderId = Data?.RelationDoc?.find(doc =>
          doc.DocumentNumber.includes('ODV')
        )?.DocHeaderId
        if (odvDocHeaderId) {
          await checkApprovalState(odvDocHeaderId)
          const {
            data: { Data: odvProducts },
          } = await searchSalesDocumentDetailService({
            DocumentTypeId: documentTypeId.ODV,
            DocumentHeaderId: odvDocHeaderId,
            DocumentNumber: null,
            PageNumber: 1,
            PageSize: 1,
          })
          const allDataLines = allData?.Lines?.map(prod => {
            const odvProduct = odvProducts?.Lines?.find(
              odvProd => odvProd.ProductId === prod.ProductId
            )
            const notDeliveredByOdv = odvProduct?.QtyDelivered === 0
            const maxDiscount =
              odvProduct?.FinalPrices?.[1]?.FinalPrice ||
              prod?.FinalPrices?.[1]?.FinalPrice ||
              odvProduct?.FinalPrice
            const negativeMarginProds = odvProduct?.Price < maxDiscount
            return {
              ...prod,
              Sku: prod?.SystemCode || prod.Sku,
              notDeliveredByOdv,
              negativeMarginProds,
            }
          })
          allData = {
            ...allData,
            Lines: allDataLines,
          }
        } else {
          allData = {
            ...allData,
            Lines: allData?.Lines?.map(prod => {
              return {
                ...prod,
                Sku: prod?.SystemCode || prod.Sku,
                notDeliveredByOdv: true,
              }
            }),
          }
        }
      }
      setCotizacion(allData)
      setCotizacionInicial({
        DocumentTypeId: allData?.DocumentTypeId,
        QuoteHeader: { QuoteHeaderId: allData?.DocHeaderId, ...allData },
      })
      dispatch(cotizacionUpdate({ data: allData, QuoteHeaderId: header }))
      setNeedToBeAuth(allData?.NegotiatedReasonId)
      dispatch(
        creditAccountsAction({
          customerId: allData?.CustomerId,
          companyId: allData?.CompanyId,
        })
      )
      setFulfillmentPlanId(allData.Lines[0]?.FulfillmentPlanId)
      setAgent({
        SalesAgentFullName: allData.SalesAgentFullName,
        SalesAgentId: allData.SalesAgentId,
        SalesAgentNumberId: allData.SalesAgentNumberId,
        SalesAgentRoleDescription: allData.SalesAgentRoleDescription,
      })
      setCustomerId(allData.CustomerId)
      setComment(allData?.Comment)
      setInitialValues(prev => ({
        ...prev,
        comment: allData?.Comment,
      }))
      setDate(new Date(allData?.DocumentDate))

      if (coti?.Address_AddressId) {
        setAddress({
          Address_AddressDetail: allData.Address_AddressDetail,
          Address_AddressId: allData.Address_AddressId,
          Address_AddressType: allData.Address_AddressType,
          Address_AddressTypeId: allData.Address_AddressTypeId,
          Address_BuildingId: allData.Address_BuildingId,
          Address_BuildingName: allData.Address_BuildingName,
          Address_BuildingType: allData.Address_BuildingType,
          Address_BuildingTypeId: allData.Address_BuildingTypeId,
          Address_CountryId: allData.Address_CountryId,
          Address_CountryName: allData.Address_CountryName,
          Address_DistrictId: allData.Address_DistrictId,
          Address_DistrictName: allData.Address_DistrictName,
          Address_HomeOrFloorNumber: allData.Address_HomeOrFloorNumber,
          Address_Latitude: allData.Address_Latitude,
          Address_Longitude: allData.Address_Longitude,
          Address_NeighborhoodId: allData.Address_NeighborhoodId,
          Address_NeighborhoodName: allData.Address_NeighborhoodName,
          Address_ProvinceId: allData.Address_ProvinceId,
          Address_ProvinceName: allData.Address_ProvinceName,
          Address_Street: allData.Address_Street,
          Address_TownshipId: allData.Address_TownshipId,
          Address_TownshipName: allData.Address_TownshipName,
          WarehouseId: allData?.Lines?.[0]?.WarehouseId, // se setea el warehouse que se muestra en la cotización, si se requiere el de las lineas cambiar a -- Lines[0]?.WarehouseId
          WarehouseDescription: allData?.Lines?.[0].WarehouseDescription,
          WarehouseAddress: allData?.Lines?.[0].WarehouseAddress,
          Telephone_Number: allData.Telephone_Number,
        })
      }
    } else {
      setLoading(false)
      throw new Error(allData)
    }
    setLoading(false)
  }

  const downloadPdf = async (Whatsapp = false, docType) => {
    const odvRelated = cotizacion?.RelationDoc?.find(
      doc => doc?.DocumentTypeId === documentTypeId.ODV
    )
    if (docType === documentTypeId.ODC && odvRelated?.DocumentTypeId) {
      const odcDocument = await getOdcDocument({
        documentTypeId: odvRelated.DocumentTypeId,
        documentId: odvRelated.DocHeaderId,
      })
      const pdfUrl = odcDocument?.data?.Data?.find(
        item => item?.DocumentUrl !== null
      )?.DocumentUrl
      if (pdfUrl) {
        return window.open(pdfUrl, '_blank', 'noopener,noreferrer')
      }
    }
    await downloadPdfFuntion(
      Whatsapp,
      header,
      dispatch,
      setShowFailMessageModal,
      docType
    )
  }

  const sendEmail = async () => {
    formik.setErrors({})
    if (formik.values.email === '') {
      setShowModalEmail(true)
      return
    }
    setEmailLoading(true)
    const payload = {
      DocumentTypeId: documentTypeId.QUOTATION,
      DocHeaderId: header,
      DocumentNumber: id,
      Email: formik.values.email,
    }
    setLoadingSendEmail(true)
    const Data = await dispatch(sendEmailCotizacion(payload, setEmailLoading))
    if (!Data && !Data?.PDFResponse) {
      throw new Error(Data)
    } else {
      setMessageEmail(true)
      setTimeout(() => {
        setMessageEmail(false)
        setEmailLoading(false)
        formik.setValues({ email: '' })
        setShowModalEmail(false)
        setMessageEmail(false)
      }, 3000)
    }
    setLoadingSendEmail(false)
  }

  const setQr = () => {
    QRCode.toDataURL(id)
      .then(response => setQrSource(response))
      .catch(err => console.error(err))
  }

  const handleCartPopulation = async () => {
    setIsEditLoading(true)
    try {
      const lineProducts = cotizacion?.Lines?.map(prod => {
        return {
          ...prod,
          hasDiscount: prod.BasePrice > prod.Price,
          qty: prod.Quantity,
        }
      })

      await checkFinalPrice({
        prods: lineProducts,
        customerId: cotizacion.CustomerId,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsEditLoading(false)
      history.push(`${ROUTES.editQuoteHeaderId}${header}/${id}/edit_cart`)
    }
  }

  const handleCartEdit = async (product, index) => {
    const parsedIndex = parseInt(index)
    dispatch(emptyCart())
    dispatch(eliminarProducto(parsedIndex))
    setShowModalEliminarProduct(true)
    getCotizacionPrices({ quote: cotizacion, freeTaxes, setCotizacion })
    setIsDeletingProduct(true)
    setTimeout(() => {
      setShowModalEliminarProduct(false)
    }, 1000)
  }

  useEffect(() => {
    fetchCotizacion()
  }, [])

  useEffect(() => {
    hiddenColumnDiscount(true)
  }, [cotizacion?.DocHeaderStatusAction])

  useEffect(() => {
    setQr()
    if (lines) {
      checkIfAuthorized()
      const productsWithPriceChange = lines.filter(
        line =>
          line?.NegotiatedReasonId &&
          line?.NegotiatedReasonId !== null &&
          line.NegotiatedReasonId !== ''
      )
      // remove approved products
      const productsWithPriceChangeFiltered = productsWithPriceChange.filter(
        line => line.AuthorizedPriceByName === null
      )
      setProductsChanged(productsWithPriceChangeFiltered)
    }
  }, [lines])

  useEffect(() => {
    showButtons()
  }, [cotizacion])

  useMemo(() => {
    if (isRedirect) {
      setIsCreate(true)
    } else {
      setIsCreate(false)
    }
    dispatch({
      type: 'SET_IS_REDIRECT',
      payload: false,
    })
  }, [])

  const saveCotizacion = async () => {
    setOpen(true)

    const payload = {
      id: docHeaderStatusId.open,
      name: 'Guardar como documento',
      value: 'Abierto',
      valueState: 'OPEN',
      disabled: !isAuthorized,
    }
    const needReload = false
    await saveType(payload, needReload)
    setChange(false)
  }

  const checkIfAuthorized = () => {
    // const priceApproved = lines.map(line => {
    //   if (line.AuthorizedPriceBy) {
    //     setPriceChangeApproval(true)
    //   }
    // })
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const hiddenColumnDiscount = bool => {
    if (cotizacion?.DocHeaderStatusAction) {
      dispatch(mostrarDescuento(bool))
    }
  }
  const hasProdWithNoCost = cotizacion?.Lines?.some(item => item.Cost === 0)

  const handlePriceChangeApprove = productLines => {
    const checkPendingPriceChange = productLines.map(product => {
      if (
        product?.NegotiatedReasonId &&
        !product?.AuthorizedPriceBy &&
        !product?.AuthorizedPriceByName
      ) {
        return {
          ...product,
          AuthorizedPriceBy: userInfo.AuthenticationInfo.UserId,
          AuthorizedPriceByName: userInfo.CustomerInfo.FullName,
        }
      }

      return product
    })

    return checkPendingPriceChange
  }

  const asyncSave = async (item, dispatch) => {
    setOpen(false)
    setSaveIsLoading(true)
    const newColumns = []
    const newHiddenColumns = hiddenColumns.map(column => {
      return column.ColumnName
    })
    const columnsVisible = updateHiddenColumns(newHiddenColumns, columns)
    columnsVisible.forEach(column => {
      newColumns.push({
        ...column,
        documentId: cotizacionFinal.QuoteHeader.QuoteHeaderId,
      })
    })
    const adjustedQuote = {
      ...cotizacionFinal,
      QuoteHeader: {
        ...cotizacionFinal.QuoteHeader,
        HeaderStatusId: item.id,
        CustomerEmail: cotizacionFinal.QuoteHeader.EmailAddress,
        Lines: handlePriceChangeApprove(cotizacionFinal.QuoteHeader?.Lines),
        TaxExempt: freeTaxes,
      },
      Columns: newColumns,
    }

    const res = await dispatch(guardarCotizacion(adjustedQuote, props))

    if (hasOdv) {
      await fetchDocumentOdv({
        QuoteHeader: { ...res.SalesDocumentInfo },
      })
    }
  }

  const saveType = async (item, needReload = true) => {
    try {
      await asyncSave(item, dispatch)
    } catch (error) {
      console.error(error)
    } finally {
      needReload && window.location.reload()
    }
  }

  const cotizationSaveTypes = [
    {
      id: docHeaderStatusId.cancel,
      name: 'Cancelar',
      value: 'Cancelar',
      valueState: 'CANCEL',
      disabled: false,
    },
    {
      id: docHeaderStatusId.draft,
      name: 'Guardar como borrador',
      value: 'Borrador',
      valueState: 'DRAFT',
      disabled: cotizacionFinal.QuoteHeader.DocHeaderStatusAction === 'OPEN',
    },
    ...(!isCustomerUser
      ? [
          {
            id: docHeaderStatusId.open,
            name: 'Guardar documento',
            value: 'Abierto',
            valueState: 'OPEN',
            disabled:
              cotizacionFinal?.QuoteHeader?.DocHeaderStatusAction === 'OPEN'
                ? false
                : cotizacionFinal?.QuoteHeader?.DocHeaderStatusAction ===
                    'DRAFT' && !hasProdWithNoCost
                ? needToBeAuth && !isAuthorized
                : !isAuthorized,
          },
        ]
      : []),
  ]

  useState(() => {
    window.addEventListener('popstate', closeAllModals)
    setIsModal(false)
  }, [])

  useEffect(() => {
    if (
      !updatedColumnsVisibility &&
      columns?.length > 0 &&
      cotizacionFinal?.QuoteHeader?.Column
    ) {
      const newColumns = []
      const items = Object.keys(cotizacionFinal?.QuoteHeader?.Column[0] || {})
      // eslint-disable-next-line array-callback-return
      items.map(item => {
        if (cotizacionFinal?.QuoteHeader?.Column[0][item] !== 0) {
          newColumns.push(item)
        }
      })
      const updateColumns = updateHiddenColumns(newColumns, columns)
      setHiddenColumns(updateColumns || [])
      // setinitial Hidden columns to activate button when changes columns
      const hiddenColumnsCopy = updateColumns.filter(column =>
        column.Status !== false ? column.ColumnName : false
      )
      setHiddenColumnsInitial(hiddenColumnsCopy || [])
      setUpdatedColumnsVisibility(true)
    }
    // validación para dehabilitar botón de guardar
    if (
      JSON.stringify(cotizacionFinal) !== JSON.stringify(cotizacionInicial) &&
      isSaveButtonAvailable
    ) {
      setActiveButton(true)
    } else {
      setActiveButton(false)
    }
  }, [cotizacionFinal])

  useEffect(() => {
    if (
      (JSON.stringify(hiddenColumns) !== JSON.stringify(hiddenColumnsInitial) ||
        isDeletingProduct) &&
      isSaveButtonAvailable
    ) {
      setActiveButton(true)
    } else {
      setActiveButton(false)
    }
  }, [hiddenColumns])

  useEffect(() => {
    const hasNewCommentaries = initialValues.comment !== comment
    const hasNewTaxes = initialValues.taxExempt !== freeTaxes
    const hasChanges = hasNewCommentaries || hasNewTaxes
    const hasCartChanges =
      JSON.stringify(cotizacionFinal) !== JSON.stringify(cotizacionInicial) &&
      isSaveButtonAvailable
    if (hasChanges && isSaveButtonAvailable) {
      setActiveButton(true)
    } else if (!hasChanges && !hasCartChanges) {
      setActiveButton(false)
    }
  }, [comment, freeTaxes, cotizacionFinal])

  const getArrayHiddenColumns = hiddenColumns => {
    const hiddenColumnsCopy = hiddenColumns.filter(column =>
      column.Status === false ? column.ColumnName : false
    )
    return hiddenColumnsCopy.map(column => column.ColumnName)
  }

  // Change Salesman
  const [openSalesman, setOpenSalesman] = useState(false)

  const handleOpen = state => {
    setOpenSalesman(state)
  }

  const generateSalesOrder = () => {
    dispatch({
      type: 'CLEAN_SELL_ORDER',
      payload: null,
    })
    handleModalConfirmStartSalesOrder()
  }

  const handleModalConfirmStartSalesOrder = () => {
    setShowModalConfirmStartSalesOrder(!showModalConfirmStartSalesOrder)
  }

  useEffect(() => {
    if (confirmStartSalesOrder) {
      setConfirmStartSalesOrder(false)
      handleModalGenerateSalesOrder()
    }
  }, [confirmStartSalesOrder])

  const handleModalGenerateSalesOrder = () => {
    setShowModalGenerateSalesOrder(!showModalGenerateSalesOrder)
  }

  const processSalesOrder = values => {
    dispatch({ type: 'SELL_ORDER_FILE_ATTACHED', payload: values.file })
    dispatch({ type: 'IS_COMMERCIAL_CREDIT_SELECTED', payload: values })
    history.push(ROUTES.salePreorder)
  }

  const checkStatusProductsApproved = bool => {
    indexProductCurrent === productsChanged.length - 1 &&
      setShowModalPriceChangeApproval(false)
    if (bool) {
      const approvedLines = lines.map(line => {
        if (line.ProductId === productsChanged[indexProductCurrent].ProductId) {
          line.AuthorizedPriceBy = userId
          line.AuthorizedPriceByName = userName
          line.Price =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.Price
              : line.Price
          line.Amount =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.Amount
              : line.Amount
          line.AmountWithTax =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.AmountWithTax
              : line.AmountWithTax
          line.DiscountAmount =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.DiscountAmount
              : line.DiscountAmount
          line.DiscountAmountLocal =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.DiscountAmountLocal
              : line.DiscountAmountLocal
          line.DiscountPct =
            newPriceProductCurrent !== null
              ? newPriceProductCurrent?.DiscountPct
              : line.DiscountPct
        }
        return line
      })
      // remove product from productsChanged
      const newProductsChanged = productsChanged.filter(
        product =>
          product.ProductId !== productsChanged[indexProductCurrent].ProductId
      )
      setProductsChanged(newProductsChanged)
      setNewPriceProductCurrent(null)
      dispatch(
        cotizacionPriceChangeApproval({
          Lines: approvedLines,
        })
      )
      setShowModalApprovedPrice(true)
      // set setShowModalApprovedPrice to false after 2 seconds
      setTimeout(() => {
        setShowModalApprovedPrice(false)
        setActiveButton(true)
      }, 2000)
    } else {
      setIndexProductCurrent(indexProductCurrent + 1)
      setShowModalRejectedPrice(true)
      // set setShowModalRejectedPrice to false after 2 seconds
      setTimeout(() => {
        setShowModalRejectedPrice(false)
      }, 2000)
    }
  }

  const handleChangePriceProduct = value => {
    if (value === '' || value === undefined || value === null) {
      return
    }
    const Price = parseFloat(value)
    const AmountWithTax =
      productsChanged[indexProductCurrent].Quantity *
      (Price * productsChanged[indexProductCurrent].TaxCategoryValue + Price)
    const Amount = productsChanged[indexProductCurrent].Quantity * Price
    const DiscountAmount =
      productsChanged[indexProductCurrent].BasePrice - Price < 0
        ? 0
        : productsChanged[indexProductCurrent].Quantity *
          (productsChanged[indexProductCurrent].BasePrice - Price)
    const DiscountPct =
      ((productsChanged[indexProductCurrent].BasePrice - Price) * 100) / Price <
      0
        ? 0
        : ((productsChanged[indexProductCurrent].BasePrice - Price) * 100) /
          Price
    const newProduct = {
      ...productsChanged[indexProductCurrent],
      Price,
      Amount,
      AmountWithTax,
      DiscountAmount,
      DiscountAmountLocal: DiscountAmount,
      DiscountPct,
    }
    setNewPriceProductCurrent(newProduct)
  }

  const validateSalesOrderAvaliability = () => {
    let isSaleOrderNotAvaliable = false

    if (
      coti.DocHeaderStatusDescription !== docHeaderStatusDescription.open ||
      !cotizacion?.CustomerId
    ) {
      isSaleOrderNotAvaliable = true
    }

    return isSaleOrderNotAvaliable
  }
  const handleClose = (needUpdate = false) => {
    setIsModal(false)
    setShowModalEditClient(false)
    setChange(false)
    needUpdate && fetchCotizacion()
  }

  const renderEditQoutationComponent = () => {
    const isDocumentCanceledOrClosed =
      cotizacion?.DocHeaderStatusDescription ===
        docHeaderStatusDescription.canceled ||
      cotizacion?.DocHeaderStatusDescription ===
        docHeaderStatusDescription.closed
    return (
      <>
        {cotizacion && date && (
          <div className="cotizacion-generada-container">
            <div className="cotizacion-generada">
              <div className="cotizacion-editar-header">
                {isCreate ? (
                  <p className="titulo-cotizacion cotizacion-alerta">
                    Se ha generado tu cotización número:{' '}
                    {cotizacion.DocumentNumber}
                  </p>
                ) : (
                  <p className="titulo-cotizacion cotizacion-alert">
                    Cotización número: {cotizacion.DocumentNumber}
                  </p>
                )}
              </div>
              <div className="info-general">
                <div className="info-general-cliente">
                  {cotizacion?.CustomerId ? (
                    <>
                      <p>
                        Cliente:{' '}
                        {cotizacion?.CustomerFullName ||
                          cotizacion?.CustomerNameDocument ||
                          'Sin Información'}
                      </p>
                      {editClienteContado && (
                        <input
                          type="text"
                          name="cliente-contado"
                          placeholder="Ingresa el nombre del cliente"
                        />
                      )}
                      <p>
                        {/* cliente.CustomerInfo.Email */}
                        Email: {email || 'Sin Información'}
                      </p>
                      <p>
                        Teléfono:{' '}
                        {cotizacion?.Cellphone_Number ||
                          cotizacion?.Telephone_Number ||
                          'Sin Información'}
                      </p>
                    </>
                  ) : (
                    <>
                      {!editClienteContado && (
                        <div>
                          <p>
                            Cliente:{' '}
                            <span>
                              {cotizacion.CustomerNameDocument ||
                                'Sin Información'}
                            </span>
                          </p>
                        </div>
                      )}
                      {editClienteContado && (
                        <p>
                          <span className="cliente-contado-edit">
                            <input
                              className="edit-contado"
                              type="text"
                              name="cliente-contado"
                              onChange={e => setClienteTemp(e.target.value)}
                              placeholder={cotizacion.CustomerNameDocument}
                            />
                            <div className="edit-contado-buttons">
                              <Check onClick={modifyCliente} />
                              <X onClick={handleClienteContado} />
                            </div>
                          </span>
                        </p>
                      )}

                      <p>
                        Email: {cotizacion.EmailAddress || 'Sin Información'}
                      </p>
                      <p>
                        Teléfono:{' '}
                        {cotizacion.Cellphone_Number || 'Sin Información'}
                      </p>
                    </>
                  )}
                  {cotizacion.DocHeaderStatusDescription !==
                    docHeaderStatusDescription.canceled &&
                  cotizacion.DocHeaderStatusDescription !==
                    docHeaderStatusDescription.closed &&
                  !isCustomer ? (
                    <FontAwesomeIcon
                      icon={faSquarePen}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (customerId) {
                          if (change && activeButton) {
                            setShowHasChangesModal(true)
                            setIsModal(false)
                          } else handleModal()
                        } else {
                          handleClienteContado()
                        }
                      }}
                    />
                  ) : null}
                </div>
                <div className="info-general-retiro">
                  {coti?.QuoteAddressId ? (
                    <>
                      <p>Envío a domicilio</p>
                      <p>
                        {t('Address', {
                          value:
                            coti?.QuoteAddressStreet || t('whithoutDatail'),
                        })}
                      </p>
                      <p>
                        {`${coti?.Address_DistrictName}, ${coti?.Address_CountryName}`}
                      </p>
                      <p>
                        {t('Alias', {
                          value:
                            coti?.QuoteAddressDetail || t('whithoutDatail'),
                        })}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>Retiro en sucursal</p>
                      <p>{coti?.Lines?.[0]?.WarehouseAddress}</p>
                      <p>{coti?.Lines?.[0]?.WarehouseDescription}</p>
                      <p>{coti?.Telephone_Number}</p>
                    </>
                  )}
                  {cotizacion.DocHeaderStatusDescription !==
                    docHeaderStatusDescription.canceled &&
                  cotizacion.DocHeaderStatusDescription !==
                    docHeaderStatusDescription.closed ? (
                    <FontAwesomeIcon
                      icon={faSquarePen}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsModal(true)
                        window.history.pushState(
                          { id: 1 },
                          null,
                          '?q=open-client-modal'
                        )
                        handleDelivery()
                      }}
                    />
                  ) : null}
                </div>
                <div className="info-general-vendedor">
                  <FontAwesomeIcon
                    icon={faSquarePen}
                    onClick={() => handleOpen(true)}
                    style={{
                      display: canChangeSeller ? 'block' : 'none',
                      cursor: 'pointer',
                    }}
                  />
                  <p>Vendedor: {agent.SalesAgentFullName}</p>
                  <p>
                    Fecha:{' '}
                    {cotizacion.CreatedDate.slice(0, 19).replace('T', ' ')}
                  </p>
                  <p>Cotización: {cotizacion.DocumentNumber}</p>
                  <p>Canal utilizado: {cotizacion.Channel}</p>
                  <div className="cotizacion-labels">
                    <p className="status" style={{ backgroundColor: status() }}>
                      {cotizacion.DocHeaderStatusDescription}
                    </p>
                    {hasChangePriceApprove(coti.Lines) && (
                      <p className="status-pending">
                        Cambio de precio pendiente
                      </p>
                    )}
                  </div>
                </div>
                <div className="info-general-codigo-qr">
                  <img src={qrSource} alt="QR" />
                </div>
              </div>
              <FilteringTable
                data={productsData}
                columns={columns}
                isProductPage={true}
                hasButtons={hasButtons}
                deleteProductAvaible={deleteProductAvaible}
                visibleIcons={visibleIcons}
                setHiddenColumns={columns => setHiddenColumns(columns)}
                hiddenColumns={getArrayHiddenColumns(hiddenColumns)}
                hiddenColumnDiscount={bool => {
                  hiddenColumnDiscount(bool)
                }}
                handleCartEdit={(product, index) =>
                  handleCartEdit(product, index)
                }
                handleCartPopulation={() => handleCartPopulation()}
                generateSalesOrder={generateSalesOrder}
                isPriceNotApproved={validateSalesOrderAvaliability()}
                // change the value here for show or hidde sales order button
                isSaleOrderGenerated={
                  cotizacion.DocHeaderStatusId !== docHeaderStatusId.draft &&
                  !cotizacion.RelationDoc?.some(docs =>
                    docs.DocumentNumber.includes('ODV')
                  )
                }
                isDispatchGenerated={
                  cotizacion.DocHeaderStatusId !== docHeaderStatusId.draft &&
                  !cotizacion.RelationDoc?.some(docs =>
                    docs.DocumentNumber.includes('SD')
                  )
                }
                top
                topButtons={
                  <TopButtons
                    pdf={downloadPdf}
                    sendEmail={sendEmail}
                    needToBeAuth={needToBeAuth}
                    handlePriceChange={openPriceChangeModal}
                    lines={lines}
                    isAuthorized={isAuthorized}
                    hasProducts={productsChanged.length > 0}
                  />
                }
                hideFooter={null}
                isInventory={null}
              />
            </div>
            <div className="cotizacion-generada-nota">
              <h2>Nota</h2>
              <p>
                Esta cotización es válida únicamente por 15 días; la
                disponibilidad de los productos aquí mostrados, podrán variar
                durante estos 15 días. Los precios de oferta incluidos en la
                cotización se regirán por las fechas establecidas de la
                promoción.
              </p>
            </div>
            <div className="cotizacion-generada-documento">
              <div>
                <h2 style={{ fontSize: '16px' }}>Adjuntar documento</h2>
                <div className="adjuntar">
                  <label htmlFor="cotizacion-file-upload">
                    <BoxArrowUp /> Examinar
                  </label>
                  <input id="cotizacion-file-upload" type="file" />
                </div>
              </div>
              <div>
                <h2 style={{ fontSize: '16px' }}>Agregar comentario</h2>
                <textarea
                  value={comment || ''}
                  disabled={isDocumentCanceledOrClosed}
                  onChange={e => setComment(e.target.value)}
                />
              </div>
              <div>
                <div style={{ fontSize: '14px' }}>
                  {cotizacion?.RelationDoc &&
                    cotizacion?.RelationDoc?.length > 0 && (
                      <>
                        <div
                          className="footer-left-text-title row"
                          style={{
                            fontSize: '1.4rem',
                          }}>
                          {t('DISPATCH_ORDER_T.RELATED_DOCS')}
                        </div>
                        {cotizacion?.RelationDoc?.map((item, index) => {
                          return (
                            <Row className="footer-left-text" key={index}>
                              <RelationalDocLabel
                                value={item}
                                document={cotizacion}
                              />
                            </Row>
                          )
                        })}
                      </>
                    )}
                </div>
              </div>
            </div>
            <div className="cotizacion-save-button">
              <LightTooltip
                title={cotizationSaveTypes.map((item, index) => {
                  return (
                    <button
                      key={index}
                      disabled={item.disabled}
                      className="products-cart-button-tooltip"
                      onClick={() => saveType(item)}>
                      {item.name}
                    </button>
                  )
                })}
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                arrow={true}
                open={open}
                onClose={handleTooltipClose}
                disableFocusListener
                disableTouchListener>
                <button
                  onClick={() => {
                    setOpen(true)
                  }}
                  style={{ display: hasButtons }}
                  className={
                    !activeButton ? 'guardar_button_disabled' : 'guardar_button'
                  }
                  disabled={!activeButton}>
                  {saveIsLoading ? (
                    <CircularProgress
                      sx={{
                        '& .MuiCircularProgress-svg': { color: '#FFFFFF' },
                      }}
                      size="2rem"
                    />
                  ) : (
                    'Guardar'
                  )}
                </button>
              </LightTooltip>
            </div>
          </div>
        )}

        <Modal open={showModalEditDelivery}>
          <Box>
            <div
              className="delivery-info-container"
              style={{
                position: 'relative',
                height: '94vh',
                overflowY: 'scroll',
              }}>
              <img
                style={{
                  position: 'absolute',
                  top: '40px',
                  right: '50px',
                }}
                src={No}
                alt="close"
                onClick={() => {
                  window.history.back()
                  setIsModal(false)
                  setShowModalEditDelivery(false)
                  setChange(true)
                }}
              />
              <MetodoEntrega
                setSelectedAddress={setAddress}
                selectedAddress={address}
                setDireccion={setDireccion}
                handleMetodoEntrega={handleDelivery}
                setFulfillmentPlanId={setFulfillmentPlanId}
              />
            </div>
          </Box>
        </Modal>

        {/* aceptar cambios de precios */}
        <Modal open={showModalPriceChangeApproval}>
          <Box
            className="modal-box"
            style={{
              height: 'auto',
            }}>
            <div className="restore-container">
              <div className="modal-header-restore">
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}>
                  Solicitud de cambio de precios
                </p>
                <img
                  src={No}
                  alt="close"
                  onClick={() => {
                    setShowModalPriceChangeApproval(false)
                  }}
                />
              </div>
              <div
                style={{
                  paddingLeft: '9px',
                  paddingRight: '9px',
                }}>
                <div>
                  <p
                    style={{
                      fontSize: '16px',
                    }}>
                    Vendedor: {agent?.SalesAgentFullName}
                  </p>
                  <p>
                    {fulfillmentPlanId === fullfilmentId ? (
                      <p
                        style={{
                          fontSize: '16px',
                        }}>
                        {coti?.Address_Street} - {coti?.Address_AddressDetail}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: '16px',
                        }}>
                        {cotizacion?.Lines?.[0]?.WarehouseDescription}
                      </p>
                    )}
                  </p>
                </div>
                {productsChanged &&
                  productsChanged[indexProductCurrent] &&
                  productsChanged.length > 0 && (
                    <div>
                      <div
                        className="price-change-approval"
                        style={{ marginTop: '24px' }}>
                        <div className="price-change-approval-item">
                          <div className="image">
                            <img
                              src={
                                productsChanged?.[indexProductCurrent]
                                  ?.DefaultProductPicture ?? NoImage
                              }
                              alt="product-img"
                              style={{ maxWidth: '100px' }}
                            />
                          </div>
                          <div className="info">
                            <p className="name">
                              {productsChanged[indexProductCurrent].ProductName}
                            </p>
                            <p>{productsChanged[indexProductCurrent].Sku}</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                              }}>
                              {productsChanged[indexProductCurrent]
                                .FinalPrice &&
                              productsChanged[indexProductCurrent].FinalPrice <
                                productsChanged[indexProductCurrent].BasePrice
                                ? '$' +
                                  productsChanged[indexProductCurrent]
                                    .FinalPrice
                                : '$' +
                                  productsChanged[indexProductCurrent]
                                    .BasePrice}
                            </p>
                          </div>
                        </div>
                      </div>
                      <button className="approval-price-button-modal disabled">
                        {productsChanged[indexProductCurrent].NegotiatedReason}
                      </button>
                      <div>
                        <p
                          style={{
                            fontSize: '16px',
                          }}>
                          Precio de negociación
                        </p>
                      </div>
                      <CurrencyInput
                        aria-label="price"
                        aria-describedby="price-sign"
                        placeholder={
                          '$' + productsChanged[indexProductCurrent].Price
                        }
                        name="precioNuevo"
                        className="input-price"
                        decimalsLimit={2}
                        onValueChange={value => handleChangePriceProduct(value)}
                      />
                      <div className="button-list mt-4">
                        <button
                          className="approval-price-button-modal"
                          onClick={() => {
                            checkStatusProductsApproved(false)
                          }}>
                          No aprobar precio
                        </button>
                        <button
                          className="approval-price-button-modal active"
                          onClick={() => {
                            checkStatusProductsApproved(true)
                          }}>
                          Aprobar precio
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Box>
        </Modal>

        {/* EDITAR CLIENTE */}
        <Modal open={showModalEditClient}>
          <Box className="modal-box-client">
            <div className="restore-container">
              <div className="modal-header-restore">
                <h2>Editar cliente</h2>
                <img src={No} alt="close" onClick={() => handleClose(false)} />
              </div>
              <CrearCliente
                handleClose={() => handleClose(true)}
                needAutoclose
              />
            </div>
          </Box>
        </Modal>

        {/* ELIMINAR PRODUCTO */}
        <Modal open={showModalEliminarProduct}>
          <Box className="modal-box">
            <div className="closing-button modal-cotizacion">
              <img
                src={No}
                onClick={() => setShowModalEliminarProduct(false)}
                alt="close"
              />
            </div>
            <div className="modal-content-text">
              <img
                src={CheckIcon}
                alt="check"
                style={{
                  width: '46px',
                  height: '46px',
                  marginBottom: 9,
                }}
              />
              <h2>El producto ha sido eliminado exitosamente</h2>
            </div>
          </Box>
        </Modal>
        {/* modal email  */}
        <Modal open={showModalEmail}>
          <Box className="modal-box">
            <div className="restore-container">
              <div className="modal-header-restore">
                <h2>Enviar cotización</h2>
                <span
                  onClick={() => {
                    formik.setErrors({})
                    setShowModalEmail(false)
                  }}
                  className="close-modal">
                  <img src={No} alt="close" />
                </span>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Ingresa tu correo electrónico"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div
                      style={{ marginTop: '5px' }}
                      className="input-feedback">
                      <strong>{formik.errors.email}</strong>
                    </div>
                  ) : null}
                  {showMessageEmail && (
                    <div className="alert alert-success mt-2" role="alert">
                      <strong>Correo enviado exitosamente</strong>
                    </div>
                  )}

                  <div className="modal-buttons">
                    <button
                      type="button"
                      onClick={() => {
                        formik.setErrors({})
                        setShowModalEmail(false)
                      }}
                      className="btn modal-cancel">
                      Cancelar
                    </button>
                    <button type="submit" className="btn modal-continue">
                      {loadingSendEmail ? (
                        <FontAwesomeIcon icon={faSpinner} className="spinner" />
                      ) : (
                        'Enviar'
                      )}
                    </button>
                  </div>
                  {/* AGREGAR UN ALERT */}
                </div>
              </form>
            </div>
          </Box>
        </Modal>

        {/* Modal confirmar iniciar orden de venta */}
        <Modal open={showModalConfirmStartSalesOrder}>
          <Box>
            <ConfirmStartSalesOrder
              setShowModalConfirmStartSalesOrder={
                handleModalConfirmStartSalesOrder
              }
              setConfirmStartSalesOrder={setConfirmStartSalesOrder}
            />
          </Box>
        </Modal>
        {/* Modal generar orden de venta */}
        <Modal open={showModalGenerateSalesOrder}>
          <Box>
            <GenerateSalesOrder
              setShowModalGenerateSalesOrder={handleModalGenerateSalesOrder}
              processSalesOrder={processSalesOrder}
            />
          </Box>
        </Modal>

        <Modal
          open={showModalApprovedPrice}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-box-message">
            <div className="modal-box-channel-message">
              <div className="closing-button">
                <img
                  src={No}
                  onClick={() => setShowModalApprovedPrice(false)}
                  alt="close"
                />
              </div>
              <div className="modal-box-channel-message-content">
                <img src={CheckIcon} alt="check" />
                <h2>
                  El precio ha sido
                  <br />
                  cambiado exitosamente
                </h2>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={showFailMessageModal}
          onClose={() => {
            setShowFailMessageModal(false)
          }}>
          <Box>
            <GenericModal
              hideCheck={true}
              showCaution={true}
              mostrarButtons={false}
              setConfirm={() => setShowFailMessageModal(false)}
              setIsOpen={() => setShowFailMessageModal(false)}
              text={t('GENERIC_MODAL.ERROR_SAVING_QUOTATION')}></GenericModal>
          </Box>
        </Modal>
        <Modal
          open={showModalRejectedPrice}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-box-message">
            <div className="modal-box-channel-message">
              <div className="closing-button">
                <img
                  src={No}
                  onClick={() => setShowModalRejectedPrice(false)}
                  alt="close"
                />
              </div>
              <div className="modal-box-channel-message-content">
                <img src={CheckIcon} alt="check" />
                <h2>
                  La solicitu de cambio de precio
                  <br />
                  no fue aprobada
                </h2>
              </div>
            </div>
          </Box>
        </Modal>
        <ChangeSalesman
          handleOpen={handleOpen}
          open={openSalesman}
          setAgent={setAgent}
        />
      </>
    )
  }

  return (
    <>
      {isEditLoading && <Loading full />}
      {window.location.search.length === 0 && (
        <RouterPrompt
          show={change && !isModal}
          showWithoutNavigation={showHasChangesModal && activeButton}
          setShowHasChangesModal={setShowHasChangesModal}
          title="¿Quieres guardar los cambios efectuados en la cotización?"
          cancelText="No"
          okText="Si"
          onOK={() => true}
          onCancel={() => false}
          handleSave={saveCotizacion}
          address={address}
        />
      )}
      <Navigation />
      {loading ? (
        <MiniLoading full={true} noMargin={true} />
      ) : (
        renderEditQoutationComponent()
      )}
    </>
  )
}

export default EditarCotizacion
