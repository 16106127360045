import { faSquarePen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'
import { FormatMoney } from '../../Utils/functions'
import { VAPermissions } from 'Utils/constants'
import PrecioProducto from '../PrecioProducto'
import './SummaryOrderCart.css'
import { QuantityColumn } from './components/QuantityColumn'
import { useTableData } from './hooks/useTableData'
import { removeFromCart } from 'redux/actions/cartActions'
import useValidatePermission from 'Hooks/useValidatePermission'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

function SummaryOrderCart() {
  const productsRef = useRef(null)
  const dispatch = useDispatch()
  const { columns } = useTableData()
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const cart = useSelector(state => state.cart.cart)
  const products = useSelector(state => state.cart.cart)
  const [showModalPrecio, setShowModalPrecio] = useState(false)
  const [productSelected, setProductSelected] = useState({})

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)

  const seeCostProd = useValidatePermission(VAPermissions.seeCostProd)

  const rightBorderTable = () => {
    if (seeCostProd && seeMargenProd) {
      return 7
    } else if (
      (!seeCostProd && seeMargenProd) ||
      (seeCostProd && !seeMargenProd)
    ) {
      return 6
    } else {
      return 5
    }
  }

  const formatData = products?.map(product => {
    return {
      ...product,
      cantidad: product?.Quantity ? product?.Quantity : 1,
      precio: product?.FinalPrice || product?.precio,
      margen: product?.Margin ? product?.Margin.toFixed(2) : 0,
      total: product?.Amount,
    }
  })

  const [showModalSuccessUpdatePrecio, setShowModalSuccessUpdatePrecio] =
    useState(false)

  const tableInstance = useTable({ columns, data: formatData })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = tableInstance

  const handleModalEditPrice = product => {
    setShowModalPrecio(!showModalPrecio)
    if (product !== undefined) {
      setProductSelected(product)
    }
  }
  const removeToCart = product => {
    dispatch(removeFromCart({ product }))
  }
  const setProductChangeValidation = value => {
    const index = cart.findIndex(
      item => item.ProductId === productSelected.ProductId
    )
    dispatch({
      type: 'IS_PRICE_CHANGED',
      payload: { index, value },
    })
  }

  const handleModalSuccessUpdatePrecio = () => {
    if (showModalSuccessUpdatePrecio === false) {
      setShowModalSuccessUpdatePrecio(true)
      setTimeout(() => {
        setShowModalSuccessUpdatePrecio(false)
      }, 5000)
    } else {
      setShowModalSuccessUpdatePrecio(false)
    }
  }

  return (
    <div className="tableContainer-summaryOrder ">
      <table
        className="single-product-cart inventory-table products"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()} ref={productsRef}>
                {row.cells.map(cell => {
                  return cell.column.Header === 'Acciones' ? (
                    <td key={cell.value} {...cell.getCellProps()}>
                      <FontAwesomeIcon
                        style={{ color: '#F64E60', fontSize: '2rem' }}
                        icon={faTrashCan}
                        onClick={() => {
                          removeToCart(cell.row.original)
                        }}
                      />
                    </td>
                  ) : cell.column.Header === 'Total' ? (
                    <td {...cell.getCellProps()}>
                      ${FormatMoney(cell.value, true, 2, true)}
                    </td>
                  ) : cell.column.Header === 'Costo' ? (
                    <td {...cell.getCellProps()}>
                      ${FormatMoney(cell.row.original.Cost, true, 2, true)}
                    </td>
                  ) : cell.column.Header === 'Margen' ? (
                    <td {...cell.getCellProps()}>
                      {seeMargenProd && (
                        <CostMarginVisibility
                          seeMargenProd={seeMargenProd}
                          simple
                          isMargin
                          margin={cell.value}
                        />
                      )}
                    </td>
                  ) : cell.column.Header === 'Cantidad' ? (
                    <td {...cell.getCellProps()}>
                      <QuantityColumn cell={cell.row.original} />
                    </td>
                  ) : cell.column.Header === 'Precio' ? (
                    <td {...cell.getCellProps()}>
                      {cell?.row?.original?.Price !==
                      cell?.row?.original?.FinalPrice ? (
                        <p
                          style={{
                            textDecoration: 'line-through',
                            color: 'orange',
                          }}>
                          ${(cell?.row?.original?.FinalPrice).toFixed(2)}
                        </p>
                      ) : null}
                      ${FormatMoney(cell?.row?.original?.Price, false, 6, true)}
                      {!isCustomerUser && (
                        <FontAwesomeIcon
                          icon={faSquarePen}
                          style={{ fontSize: '18px' }}
                          onClick={() =>
                            handleModalEditPrice(cell.row.original)
                          }
                        />
                      )}
                    </td>
                  ) : (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group, i) => (
            <tr key={i} {...group.getFooterGroupProps()}>
              {group.headers.map((column, index) => (
                <td
                  key={index}
                  style={{
                    border: 'none',
                    borderLeft: index !== 0 && 'none',
                    borderRight: index !== rightBorderTable() && 'none',
                  }}
                  {...column.getFooterProps()}>
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <Modal
        show={showModalPrecio}
        backdrop="static"
        className="modal-clase-price-change"
        centered>
        <PrecioProducto
          handleModalEditPrice={handleModalEditPrice}
          handleModalSuccesUpdatePrecio={handleModalSuccessUpdatePrecio}
          product={productSelected}
          setNuevoPrecio={() => null}
          setProductChangeValidation={setProductChangeValidation}
        />
      </Modal>
    </div>
  )
}

export default SummaryOrderCart
