import { React, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Navigation from '../../Components/Navigation'
import './aprobarOrdenDeVentas.css'
import { CustomSwitch } from 'Components/Switch/CustomSwitch.jsx'
import { documentTypeId, VAPermissions } from 'Utils/constants'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

import {
  searchSalesDocumentDetailService,
  AddUpdateSalesDocumentsService,
} from 'redux/services/cotizacionServices'
import No from '../../Assets/Images/Login/no.svg'
import CheckIcon from '../../Assets/Images/Login/check.png'
import MiniLoading from 'Components/MiniLoading'
import { Modal } from 'react-bootstrap'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import useValidatePermission from 'Hooks/useValidatePermission'

const AprobarOrdenDeVentas = () => {
  const { saleOrderDocHeaderId, saleOrderDocumentNumber } = useParams()
  const [odvProducts, setOdvProducts] = useState([])
  const [allOdvdata, setallODVdata] = useState([])
  const [loading, setLoading] = useState(true)
  const { user } = useSelector(state => state.login)
  const [sendingSubmit, setSendingSubmit] = useState(false)
  const aproveDiscount = useValidatePermission(VAPermissions.aproveDiscount)
  const seeCostProd = useValidatePermission(VAPermissions.seeCostProd)
  const [showModalPriceChangeApproval, setShowModalPriceChangeApproval] =
    useState(false)
  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const fetchDocumentODV = async () => {
    const payload = {
      DocumentTypeId: documentTypeId.ODV,
      DocumentHeaderId: saleOrderDocHeaderId,
      DocumentNumber: saleOrderDocumentNumber,
      PageNumber: 1,
      PageSize: 1,
    }

    try {
      const respuesta = await searchSalesDocumentDetailService(payload)
      const addSalesOrderLineId = respuesta?.data?.Data?.Lines.map(prod => ({
        ...prod,
        SalesOrderLineId: prod.DocLineId,
      }))
      setallODVdata({
        ...respuesta?.data?.Data,
        Lines: addSalesOrderLineId,
      })

      const productsNeedAuth = addSalesOrderLineId?.filter(
        prod =>
          prod.Price < prod.FinalPrice &&
          prod.AuthorizedPriceBy === null &&
          prod.AuthorizedPriceByName === null &&
          prod.QtyOrdered - prod.QtyCanceled - prod.QtyDelivered !== 0
      )
      const productosWithIsChecked = productsNeedAuth?.map(producto => ({
        ...producto,
        isChecked: false,
      }))

      setOdvProducts(productosWithIsChecked)

      setLoading(false)
    } catch (error) {
      console.error('Error al obtener los detalles del documento:', error)
    }
  }

  useEffect(() => {
    fetchDocumentODV()
  }, [saleOrderDocumentNumber])

  const isAllchecked = !odvProducts.some(items => items?.isChecked !== true)
  const someItemChecked = odvProducts.some(item => item?.isChecked === true)

  const handleAllSwitchChange = state => {
    setOdvProducts(products =>
      products.map(prod => ({
        ...prod,
        isChecked: state,
      }))
    )
  }

  const handleSwitchChange = productId => {
    setOdvProducts(products =>
      products.map(prod =>
        prod.SystemCode === productId
          ? { ...prod, isChecked: !prod.isChecked }
          : prod
      )
    )
  }

  const updateProductsAuth = async () => {
    const prodsUpdated = odvProducts?.map(item => {
      if (item?.isChecked) {
        return {
          ...item,
          AuthorizedPriceBy: user?.AuthenticationInfo?.UserId,
          AuthorizedPriceByName: user?.AuthenticationInfo?.UserName,
        }
      } else {
        return item
      }
    })

    const updatedProducts = allOdvdata?.Lines?.map(originalProduct => {
      const productFiltered = prodsUpdated.find(
        item => item.SystemCode === originalProduct.SystemCode
      )
      return productFiltered
        ? { ...originalProduct, ...productFiltered }
        : originalProduct
    })

    const payload = {
      DocumentTypeId: documentTypeId.ODV,
      SalesOrder: {
        SalesOrderHeaderId: saleOrderDocHeaderId,
        ...allOdvdata,
        Lines: updatedProducts,
      },
    }

    try {
      await AddUpdateSalesDocumentsService(payload)
      setTimeout(() => {
        history.back()
      }, 2000)
    } catch (error) {
      console.error('Error al actualizar productos y documentos:', error)
    }
  }

  return (
    <>
      <Navigation />
      <section className="aproved-main" data-testid="AprobarOrdenDeVentas">
        <div className="aproved-header">
          <h2>Aprobación de precio de orden de ventas</h2>
        </div>
        {loading ? (
          <MiniLoading />
        ) : (
          <>
            <Modal show={showModalPriceChangeApproval} centered>
              <Box>
                <div className="aprove-modal-box-message">
                  <div className="closing-button">
                    <img
                      src={No}
                      onClick={() => setShowModalPriceChangeApproval(false)}
                      alt="close"
                    />
                  </div>
                  <div className="modal-box-channel-message-content">
                    <img src={CheckIcon} alt="check" />
                    <h2>
                      El precio ha sido
                      <br />
                      cambiado exitosamente
                    </h2>
                  </div>
                </div>
              </Box>
            </Modal>

            <div style={{ backgroundColor: 'var(--main-color)' }}>
              <div className="space-between">
                <p className="header-info-odv">
                  Orden N°: {saleOrderDocumentNumber}
                </p>
                <p className="header-info-odv">
                  Cliente: {allOdvdata.CustomerFullName}
                </p>
                <p className="header-info-odv">
                  Vendedor: {allOdvdata.SalesAgentFullName}
                </p>
                <p className="header-info-odv">
                  Fecha de creación: {allOdvdata.CreatedDate.slice(0, 10)}
                </p>
              </div>

              <p className="importantMessage">
                <span style={{ fontWeight: 'bold' }}>Importante: </span>
                En la columna de acciones, puedes aprobar o denegar los precios
                de los productos que estén por debajo del margen establecido.
              </p>
            </div>
            <div className="general-container">
              <div className="table-container-aprove">
                <table className="inventory-table-aprove">
                  <thead className="table-header">
                    <tr>
                      <th>Referencia</th>
                      <th>Descripción</th>
                      <th>Precio inicial</th>
                      <th>Precio negociado</th>
                      {seeCostProd ? <th>Costo</th> : null}
                      {seeMargenProd ? <th>Margen de Ganancia</th> : null}
                      <th>
                        <span style={{ marginRight: 12 }}>Acciones</span>
                        <CustomSwitch
                          color="#11D29E"
                          checked={isAllchecked}
                          onChange={() => handleAllSwitchChange(!isAllchecked)}
                          rol="checkbox"
                          data-testid="global-switch"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {odvProducts?.map((product, index) => (
                      <tr
                        className="inventory-table-aprove-row inventory-table-aprove-col"
                        key={product.SystemCode}>
                        <td>{product.SystemCode}</td>
                        <td>{product.ProductName}</td>
                        <td>${product.BasePrice}</td>
                        <td>${product.Price}</td>
                        {seeCostProd ? <td>${product.Cost}</td> : null}
                        {seeMargenProd ? (
                          <td>
                            <CostMarginVisibility
                              seeMargenProd={seeMargenProd}
                              seeCostProd={seeCostProd}
                              simple
                              isMargin
                              margin={(
                                ((product.Price - product.Cost) /
                                  product.Price) *
                                100
                              ).toFixed(2)}
                              centerInTable
                            />
                          </td>
                        ) : null}
                        <td>
                          <div>
                            <CustomSwitch
                              color="#11D29E"
                              checked={product.isChecked || false}
                              onChange={isChecked =>
                                handleSwitchChange(product.SystemCode)
                              }
                              rol="checkbox"
                              data-testid={`product-switch-${index}`}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="aprove-save-button">
              <button
                className={
                  aproveDiscount && someItemChecked
                    ? 'guardar_button'
                    : 'disabled'
                }
                disabled={!aproveDiscount || !someItemChecked}
                onClick={() => {
                  updateProductsAuth()
                  setSendingSubmit(true)
                  setShowModalPriceChangeApproval(true)
                }}>
                {sendingSubmit ? (
                  <CircularProgress size={20} />
                ) : (
                  <p>Guardar</p>
                )}
              </button>
            </div>
          </>
        )}
      </section>
    </>
  )
}

export default AprobarOrdenDeVentas
