import React from 'react'
import { Modal, Box } from '@mui/material'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'
import No from '../../Assets/Images/Login/no.svg'

const WarningModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="modal-box-message"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <img
          src={No}
          alt="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '25px',
            right: '30px',
            cursor: 'pointer',
          }}
        />
        <ExclamationTriangleFill
          style={{
            fontSize: '40px',
            color: '#FF2A40',
            marginBottom: '19px',
          }}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 'semibold',
          }}>
          No se puede generar una cotización con productos de costo cero. Por
          favor, revisa los costos con compras antes de continuar
        </p>
      </Box>
    </Modal>
  )
}

export default WarningModal
