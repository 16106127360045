import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import Product from '../Product'
import './productList.css'
import MiniLoading from '../MiniLoading'
import styles from './productList.module.css'
import MaterialsCalculator from 'Components/MaterialsCalculator/MaterialCalculator'
import Modal from 'react-bootstrap/Modal'
import { emptyCart } from 'redux/actions/cartActions'
import { useDispatch } from 'react-redux'

function ProductList({
  products,
  setProductCart,
  successAlgoliaHits,
  productsRef,
  productsLoading,
  hasMore,
  handlePage,
  inListScreen,
  currentCategory,
  productSearchText,
  setShowMaterialsCalculatorPDP,
  setProductDetail,
}) {
  const [productsList, setProductsList] = useState([])
  const [showMaterialsCalculatorPLP, setShowMaterialsCalculatorPLP] =
    useState(false)

  useEffect(() => {
    setProductsList(products)
  }, [products])
  const dispatch = useDispatch()
  const showCalculatorAds =
    (currentCategory?.includes('Cielo rasos') && currentCategory?.length > 0) ||
    productSearchText.toLowerCase().includes('cielo raso') ||
    productSearchText.toLowerCase().includes('cielo rasos')

  return (
    <>
      <div className={'products-cotizacion-content'}>
        <div className="product-cards-wrapper">
          {!productsLoading && showCalculatorAds && (
            <div
              className="product-card2 background-image"
              onClick={() => {
                setShowMaterialsCalculatorPLP(true)
                dispatch(emptyCart())
              }}></div>
          )}
          {productsList.length > 0
            ? productsList.map(product => {
                return (
                  <div key={product.ExternalCodes[0]?.ProductId}>
                    <Product
                      product={product}
                      // setProductCart={setProductCart}
                      productsRef={productsRef}
                      inListScreen={inListScreen}
                      setShowMaterialsCalculatorPDP={
                        setShowMaterialsCalculatorPDP
                      }
                      setProductDetail={setProductDetail}
                      isActive={product.IsActive}
                    />
                  </div>
                )
              })
            : !successAlgoliaHits && (
                <div className={styles.noResultsContainer}>
                  <h3>{t('PRODUCTS_SEARCH_T.RESULTS_NOT_FOUND')}</h3>
                </div>
              )}
        </div>
        {productsLoading ? (
          <div className="load-more-button">
            <MiniLoading productsLoading={productsLoading} />
          </div>
        ) : products.length > 0 && hasMore ? (
          <div className="load-more-button">
            <button onClick={() => handlePage()} disabled={productsLoading}>
              Ver más...
            </button>
          </div>
        ) : (
          <div className="load-more-button" />
        )}
      </div>
      <Modal
        backdrop={false}
        fullscreen={true}
        show={showMaterialsCalculatorPLP}>
        <MaterialsCalculator
          showCalculator={showMaterialsCalculatorPLP}
          setShowCalculator={setShowMaterialsCalculatorPLP}
          isPLP
        />
      </Modal>
    </>
  )
}

export default ProductList
